import React, { useEffect, useState, useRef } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../modules';

import {
  // BreadcumbTitle,
  MainContainer,
  MainContentInner,
  MainContentRouter,
  MainContentRouterInner,
} from './styled';
import { setLogoutAsync, getCheckSessionAsync } from '../../modules/auth';
import { setIsGNBFold } from '../../modules/sidebarNav';
import ErrorComponent from './ErrorComponent';
import Navigation from '../../components/Organisms/Navigation';

interface PrivateRouteProps extends RouteComponentProps {
  exact: boolean;
  path: string;
  title: string;
  Component: any;
}

const PrivateRoute = ({ exact, path, title, Component }: PrivateRouteProps) => {
  // redux
  const { subMenuLinkComponent, gnbIsFold } = useSelector(
    (state: RootState) => state.sidebarNav
  );

  const dispatch = useDispatch();

  const onSetLogout = () => {
    dispatch(setLogoutAsync.request());
  };

  const onSetIsGNBFold = (isFold: boolean | null) => {
    dispatch(setIsGNBFold(isFold));
  };

  // const onGetIsAIVORYAvailable = () => {
  //   dispatch(getIsAIVORYAvailableAsync.request());
  // };

  // const onGetPVUsage = () => {
  //   dispatch(getPVUsageAsync.request());
  // };

  const onGetCheckSession = () => {
    dispatch(getCheckSessionAsync.request());
  };

  const [isScroll, setIsScroll] = useState(false);
  const MainContentRef = useRef<HTMLDivElement | null>(null);
  const MainContentInnerEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // onGetIsAIVORYAvailable();
    // onGetPVUsage();
    if (localStorage.getItem('vq_tenant')) {
      setTimeout(() => {
        // onGetCheckSession();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    const { current } = MainContentRef;
    if (current) {
      if (current.offsetHeight < current.scrollHeight) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
  }, [MainContentRef.current]);

  return (
    <MainContainer id="private_router">
      <ErrorComponent />

      <Navigation
        onSetLogout={onSetLogout}
        onGetCheckSession={onGetCheckSession}
      />
      <MainContentRouter $isFold={gnbIsFold}>
        <MainContentRouterInner id="main-content-inner" ref={MainContentRef}>
          <MainContentInner $isScroll={isScroll}>
            <Route exact path={path} render={() => <Component />} />
          </MainContentInner>
        </MainContentRouterInner>
      </MainContentRouter>
      <div id="gnb_submenu_tooltip">{subMenuLinkComponent}</div>
    </MainContainer>
  );
};

export default withRouter(connect(null, null)(PrivateRoute));
