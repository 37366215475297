import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { H7, P3 } from '../../Atoms/Typo';
import { IconInform } from '../../Atoms/Icon';
import InformTooltip from '../InformTooltip';
import ToggleButton from '../../Atoms/ToggleButton';

const Component = styled.div`
  margin-top: 30px;
`;

const Header = styled.div`
  margin: 10px 0 16px;
  ${H7} {
    color: ${(props) => props.theme.colors.black};
    margin-right: 10px;
  }
`;

const HeadWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
`;

const ButtonGroup = styled.div`
  width: 32%;
  margin-bottom: 30px;
`;

const SelectBtn = styled(Button)<{ $activeId?: number; $isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 11px 10px 12px 20px;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  color: ${(props) => props.theme.colors.black};
  background-color: #fff;
  margin-bottom: 4px;
  margin-right: 40px;

  :hover {
    background-color: rgba(0, 141, 237, 0.1);
    border: solid 1px rgba(0, 141, 237, 0.1);
    ${(props) => {
      if (props.$isDisabled) {
        return css`
          background-color: ${props.theme.colors.grey200};
          border: solid 1px ${props.theme.colors.grey300};
        `;
      }
    }}
  }
  ${(props) => {
    if (props.$isDisabled) {
      return css`
        color: ${props.theme.colors.grey600};
        background-color: ${props.theme.colors.grey200};
        cursor: not-allowed;
      `;
    }
    return css`
      :nth-child(${props.$activeId}) {
        background-color: rgba(0, 141, 237, 0.1);
        border: solid 1px #008ded;
      }
    `;
  }}
`;

const Inform = styled.div`
  display: flex;
`;

interface SubAlgorithmProps {
  data: {
    id?: number | undefined;
    idx: number;
    gid: number;
    is_block: boolean;
    name: string;
    text: string;
  }[];
  mainId: number | undefined;
  subAlgorithm: (id: number, name: string) => void;
  onToogleState: (state: boolean) => void;
}

const SubAlgorithm = ({
  data,
  mainId,
  subAlgorithm,
  onToogleState,
}: SubAlgorithmProps) => {
  const [toggleState, setToggleState] = useState(false);
  const [rebuildData, setRebuildData] = useState<SubAlgorithmProps['data']>();
  const [buttonId, setButtonId] = useState<number>();
  const [tooltip, setTooltip] = useState(false);
  const [informID, setInformID] = useState(-1);

  const checkState = (state: boolean) => {
    setToggleState(state);
  };

  const onClickAlgorithm = (id: number, name: string) => {
    setButtonId(id);
    subAlgorithm(id, name);
  };

  const onEnter = (id: number) => {
    setTooltip(true);
    setInformID(id);
  };

  const onLeave = (id: number) => {
    setTooltip(false);
    setInformID(id);
  };

  useEffect(() => {
    setRebuildData(data.filter((name) => name.gid === 1));
  }, [data]);

  useEffect(() => {
    onToogleState(toggleState);
  }, [toggleState, onToogleState]);

  return (
    <Component>
      <Header>
        <HeadWrap>
          <H7>서브 알고리즘 선택</H7>
          <ToggleButton onState={checkState} />
        </HeadWrap>
        <P3>
          매인 알고리즘을 통한 추천이 10개 미만인 경우 서브 알고리즘을 통해 추천
          개수를 채워서 회원에게 전달하는 역할입니다.
          <br />미 선택시 기존에 있는 추천만 제공됩니다.
        </P3>
      </Header>
      {toggleState ? (
        <ButtonGroup>
          {rebuildData &&
            rebuildData.map((element, idx) => {
              return (
                <SelectBtn
                  key={element.name}
                  $activeId={buttonId}
                  $isDisabled={element.idx === mainId}
                  onClick={() => {
                    onClickAlgorithm(element.idx, element.name);
                  }}
                >
                  {element.name}
                  <Inform
                    onMouseEnter={() => {
                      onEnter(idx);
                    }}
                    onMouseLeave={() => {
                      onLeave(idx);
                    }}
                  >
                    <IconInform />
                    {informID === idx && element.idx !== mainId ? (
                      <InformTooltip isShow={tooltip} text={element.text} />
                    ) : null}
                  </Inform>
                </SelectBtn>
              );
            })}
        </ButtonGroup>
      ) : null}
    </Component>
  );
};

export default SubAlgorithm;
