import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../modules';
import {
  SetEditUserInfoParamType,
  SetEditPasswordParamType,
} from '../../../api/account/type';
import {
  setEditUserInfoAsync,
  setEditPasswordAsync,
} from '../../../modules/account';
import { getCheckSessionAsync } from '../../../modules/auth';

import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import EditUserInfoCard from '../../../components/Organisms/Card/EditUserInfoCard';
import DefaultModal from '../../../components/UIComponents/Modal/DefaultModal';

const EditUserInfo = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState(false);

  const { user_name, user_phone, user_email } = useSelector(
    (state: RootState) => state.auth
  );
  const { editUserState, editPasswordState } = useSelector(
    (state: RootState) => state.account
  );
  const dispatch = useDispatch();

  const onSetEditUserInfo = (info: SetEditUserInfoParamType) => {
    dispatch(setEditUserInfoAsync.request(info));
  };
  const onSetEditPasswordInfo = (info: SetEditPasswordParamType) => {
    dispatch(setEditPasswordAsync.request(info));
  };
  const onGetCheckSession = () => {
    dispatch(getCheckSessionAsync.request());
  };

  const onClickUserInfo = (name: string, phoneNum: string, email: string) => {
    onSetEditUserInfo({
      user_name: name,
      phone: phoneNum,
      email: email,
    });
    setState(true);
  };

  const onClickEditPassword = (pw: string, pw_confirm: string) => {
    onSetEditPasswordInfo({
      pw: pw,
      pw_confirm: pw_confirm,
    });
    setState(true);
  };

  // modal
  const ModalClose = () => {
    setModalOpen(false);
    setState(false);
    onGetCheckSession();
  };

  useEffect(() => {
    if (state) {
      setModalOpen(editUserState);
    }
  }, [editUserState, state]);

  useEffect(() => {
    if (state) {
      setModalOpen(editPasswordState);
    }
  }, [editPasswordState, state]);

  return (
    <ContentBodyLayout>
      <DefaultModal
        isPublic={false}
        isOpen={modalOpen}
        message="저장되었습니다."
        handleClose={ModalClose}
      />
      <ContentsItemLayout desktop={12}>
        <EditUserInfoCard
          user={user_name || ''}
          phone={user_phone || ''}
          mail={user_email || ''}
          onClickUserInfo={onClickUserInfo}
          onClickEditPassword={onClickEditPassword}
        />
      </ContentsItemLayout>
    </ContentBodyLayout>
  );
};

export default EditUserInfo;
