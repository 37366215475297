import axios from 'axios';
import config from '../config-api.json';

import {
  GetManageRecommendListParamType,
  GetManageRecommendListResponseType,
  GetManageRecommendDetailParamType,
  GetManageRecommendDetailResponseType,
  GetManageRecommendGraphParamType,
  GetManageRecommendGraphResponseType,
  SetRecommendNameResponseType,
  SetRecommendNameParamType,
  GetAlgorithmCheckParamType,
  GetAlgorithmCheckResponseType,
  DeleteRecommendParamType,
  DeleteRecommendResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 개인화 추천 리스트
export async function apiGetRecommendList(
  info: GetManageRecommendListParamType
) {
  const response = await axios.get<GetManageRecommendListResponseType>(
    `${apiInfo.api_url}/management/recommend_list/`,
    {
      params: {
        dt: info.dt,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 개인화 추천 상세정보
export async function apiGetRecommendDetail(
  info: GetManageRecommendDetailParamType
) {
  const response = await axios.get<GetManageRecommendDetailResponseType>(
    `${apiInfo.api_url}/management/recommend_detail/`,
    {
      params: {
        ak: info.ak,
        dt: info.dt,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 개인화 추천 상세정보 그래프
export async function apiGetRecommendGraph(
  info: GetManageRecommendGraphParamType
) {
  const response = await axios.get<GetManageRecommendGraphResponseType>(
    `${apiInfo.api_url}/management/recommend_detail_graph/`,
    {
      params: {
        ak: info.ak,
        dt: info.dt,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 개인화 추천 이름 변경
export async function apiSetRecommendName(info: SetRecommendNameParamType) {
  const response = await axios.post<SetRecommendNameResponseType>(
    `${apiInfo.api_url}/management/recommend_set_name/`,
    {
      name: info.name,
      ak: info.ak,
      type: info.type,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 알고리즘 동작 확인 API
export async function apiGetAlgorithmCheck(info: GetAlgorithmCheckParamType) {
  const response = await axios.get<GetAlgorithmCheckResponseType>(
    `${apiInfo.api_url}/management/recommend_expose_items/`,
    {
      params: {
        ak: info.ak,
        dt: info.dt,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 대기중인 추천 삭제
export async function apiDeleteRecommend(info: DeleteRecommendParamType) {
  const response = await axios.delete<DeleteRecommendResponseType>(
    `${apiInfo.api_url}/management/recommend_delete/`,
    {
      params: {
        ak: info.ak,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
