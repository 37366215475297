import { takeLatest } from 'redux-saga/effects';
import {
  apiGetItemAnalysis,
  apiGetAnalysisAlgorithmList,
} from '../../api/itemAnalysis/api';
import {
  GET_ITEM_ANALYSIS,
  getItemAnalysisAsync,
  getAnalysisAlgorithmAsync,
  GET_ANALYSIS_ALGORITHM,
} from './actions';
import createRequestSaga from '../createRequestSaga';

const getItemAnalysisSaga = createRequestSaga(
  getItemAnalysisAsync,
  apiGetItemAnalysis
);

const getAnalysisAlgorithmSaga = createRequestSaga(
  getAnalysisAlgorithmAsync,
  apiGetAnalysisAlgorithmList
);

export function* itemAnalysisSaga() {
  yield takeLatest(GET_ITEM_ANALYSIS, getItemAnalysisSaga);
  yield takeLatest(GET_ANALYSIS_ALGORITHM, getAnalysisAlgorithmSaga);
}

export { itemAnalysisSaga as default };
