import React from 'react';
import styled, { css } from 'styled-components';
import { IconStepperSuccess } from '../../Atoms/Icon';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H4, P3 } from '../../Atoms/Typo';

const Layout = styled(CardLayout)`
  padding: 24px 0;
`;

const Header = styled.div`
  padding: 0 30px;
  ${H4} {
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 25px;
`;

const Component = styled.div<{ $activeID: number; $elementId: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props) => {
    if (props.$activeID === props.$elementId) {
      return css`
        background-color: ${(props) => props.theme.colors.blue100};
      `;
    }
    return css``;
  }}
`;

const Num = styled.div<{ $activeID: number; $elementId: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  margin-right: 16px;
  color: ${(props) => props.theme.colors.grey500};
  background-color: ${(props) => props.theme.colors.grey200};

  ${(props) => {
    if (props.$activeID >= props.$elementId) {
      return css`
        color: ${(props) => props.theme.colors.blue400};
        background-color: ${(props) => props.theme.colors.white};
      `;
    }
    return css``;
  }}
`;

const Title = styled.div<{ $activeID: number; $elementId: number }>`
  font-size: 14px;
  color: #9e9e9e;

  ${(props) => {
    if (props.$activeID >= props.$elementId) {
      return css`
        font-weight: 500;
        color: ${(props) => props.theme.colors.black};
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$activeID === props.$elementId) {
      return css`
        color: ${(props) => props.theme.colors.blue400};
      `;
    }
    return css``;
  }}
`;

const Preview = styled.div`
  position: relative;
  margin: -10px 0 10px 73px;
  padding-right: 25px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    font-weight: normal;
  }
  ::before {
    content: '';
    position: absolute;
    left: -33px;
    height: calc(100% + 20px);
    width: 1px;
    border-right: 1px dotted ${(props) => props.theme.colors.blue400};
    order: -1;
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  ${P3} {
    margin-left: 10px;
  }
`;

const Tag = styled.div`
  min-width: 26px;
  color: ${(props) => props.theme.colors.grey600};
  font-weight: 500;
`;

const Split = styled.div<{ $activeID: number; $id: number }>`
  position: absolute;
  z-index: 999;
  bottom: -10px;
  left: 5.9%;
  width: 1px;
  height: 20px;
  border-right: 1px dotted ${(props) => props.theme.colors.grey200};
  margin-left: 18px;
  ${(props) => {
    if (props.$id === 4) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$activeID > props.$id) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;

interface StepperCardProps {
  isActive: number;
  data: {
    id: number;
    title: string;
  }[];
  segmentList: string[];
  pageName: string;
  algorithmName: string;
  subAlgorithmName: string;
  isSubAlgorithm: boolean;
}

const StepperCard = ({
  isActive,
  data,
  segmentList,
  pageName,
  algorithmName,
  subAlgorithmName,
  isSubAlgorithm,
}: StepperCardProps) => {
  const printCard = (pageID: number, elementID: number) => {
    if (pageID > elementID) {
      switch (elementID) {
        case 1:
          return (
            <Preview>
              {segmentList.map((element) => {
                return <P3 key={element}>{element}</P3>;
              })}
            </Preview>
          );
        case 2:
          return (
            <Preview>
              <P3>{pageName}</P3>
            </Preview>
          );
        case 3:
          return (
            <Preview>
              <RowWrap>
                <Tag>메인</Tag>
                <P3>{algorithmName}</P3>
              </RowWrap>
              <RowWrap>
                <Tag>서브</Tag>
                <P3>{isSubAlgorithm ? subAlgorithmName : '-'}</P3>
              </RowWrap>
            </Preview>
          );

        default:
          return null;
      }
    }
  };

  return (
    <Layout>
      <Header>
        <H4>추천 만들기 단계</H4>
      </Header>
      {data.map((element) => {
        return (
          <React.Fragment key={element.id + element.title}>
            <Component $activeID={isActive} $elementId={element.id}>
              <Step>
                {isActive <= element.id ? (
                  <Num $activeID={isActive} $elementId={element.id}>
                    {element.id}
                  </Num>
                ) : (
                  <IconStepperSuccess />
                )}
                <Title $activeID={isActive} $elementId={element.id}>
                  {element.title}
                </Title>
              </Step>
              <Split
                $activeID={isActive}
                $id={element.id}
                key={element.title}
              />
            </Component>
            {printCard(isActive, element.id)}
          </React.Fragment>
        );
      })}
    </Layout>
  );
};

export default StepperCard;
