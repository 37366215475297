import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { H7, P3 } from '../../Atoms/Typo';
import { IconInform } from '../../Atoms/Icon';
import InformTooltip from '../InformTooltip';
import TextTooltip from '../TextTooltip';

const Component = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  margin-bottom: 30px;
`;

const Header = styled.div`
  margin: 10px 0 16px;
  ${H7} {
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 7px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnGroup = styled.div<{ $allGroup?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  ${(props) => {
    if (props.$allGroup) {
      return css`
        width: 40%;
        padding-right: 0px;
      `;
    }
    return css``;
  }}
`;

const Inform = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  display: flex;
  margin-bottom: 7px;
  ${P3} {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
  svg {
    margin-left: 7px;
  }
`;

const SelectBtn = styled(Button)<{
  $activeId?: number;
  $prevNo?: number;
  $test?: number[];
  $isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* height: 43px; */
  padding: 11px 10px 12px 20px;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  color: ${(props) => props.theme.colors.black};
  background-color: #fff;
  margin-bottom: 4px;
  margin-right: 40px;

  :hover {
    background-color: rgba(0, 141, 237, 0.1);
    border: solid 1px rgba(0, 141, 237, 0.1);
    ${(props) => {
      if (props.$isDisabled) {
        return css`
          background-color: ${props.theme.colors.grey200};
          border: solid 1px ${props.theme.colors.grey300};
        `;
      }
    }}
  }
  ${(props) => {
    if (props.$isDisabled) {
      return css`
        color: ${props.theme.colors.grey600};
        background-color: ${props.theme.colors.grey200};
        cursor: not-allowed;
      `;
    }
    if (props.$test && props.$prevNo) {
      return css`
        :nth-child(${props.$test[0] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[1] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[2] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[3] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[4] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
      `;
    }
    if (props.$prevNo && props.$activeId) {
      return css`
        :nth-child(${props.$activeId - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
      `;
    }
    return css`
      :nth-child(${props.$activeId}) {
        background-color: rgba(0, 141, 237, 0.1);
        border: solid 1px #008ded;
      }
    `;
  }}
  :last-child {
    margin-bottom: 20px;
  }
`;

interface SelectAlgorithmProps {
  activeId?: number;
  data: {
    name: string;
    idx: number;
    gid: number;
    is_block: boolean;
    text: string;
    id?: number;
  }[];
  onClick?: (id: number, name: string) => void;
}

const SelectAlgorithm = ({ activeId, data, onClick }: SelectAlgorithmProps) => {
  // state
  const [tooltip, setTooltip] = useState(false);
  const [statisticsId, setStatisticsId] = useState(-1);
  const [rulesId, setRulesId] = useState(-1);
  const [filterId, setFilterId] = useState(-1);
  const [statisticsTooltip, setStatisticsTooltip] = useState(false);
  const [rulesTooltip, setRulesTooltip] = useState(false);
  const [filterTooltip, setFilterTooltip] = useState(false);

  const onEnter = (type: number, id: number) => {
    setTooltip(true);

    switch (type) {
      case 1:
        setStatisticsId(id);
        break;
      case 2:
        setRulesId(id);
        break;
      case 3:
        setFilterId(id);
        break;
      default:
        break;
    }
  };
  const onLeave = (type: number) => {
    setTooltip(false);
    switch (type) {
      case 1:
        setStatisticsId(-1);
        break;
      case 2:
        setRulesId(-1);
        break;
      case 3:
        setFilterId(-1);
        break;
      default:
        break;
    }
  };

  const onEnterTitle = (id: number) => {
    switch (id) {
      case 1:
        setStatisticsTooltip(true);
        break;
      case 2:
        setRulesTooltip(true);
        break;
      case 3:
        setFilterTooltip(true);
        break;
      default:
        break;
    }
  };

  const onLeaveTitle = (id: number) => {
    switch (id) {
      case 1:
        setStatisticsTooltip(false);
        break;
      case 2:
        setRulesTooltip(false);
        break;
      case 3:
        setFilterTooltip(false);
        break;
      default:
        break;
    }
  };

  const onClickBtn = (id: number, isblock: boolean, name: string) => {
    if (!isblock) {
      if (onClick) {
        onClick(id, name);
      }
    }
  };

  const gOneData = data.filter((name) => name.gid === 1);
  const gTwoData = data.filter((name) => name.gid === 2);
  const gThreeData = data.filter((name) => name.gid === 3);

  return (
    <Component>
      <Header>
        <H7>메인 알고리즘 선택</H7>
        <P3>이번 추천에 적용할 알고리즘을 1개 선택해주세요.</P3>
      </Header>
      <Wrap>
        <ColumnGroup>
          <Title>
            <P3>통계기반 알고리즘</P3>
            <Inform
              onMouseEnter={() => {
                onEnterTitle(1);
              }}
              onMouseLeave={() => {
                onLeaveTitle(1);
              }}
            >
              <IconInform />
              <TextTooltip
                isShow={statisticsTooltip}
                algorithm
                text="상품 클릭이나 구매 등 통계에 따른 상품을 추천합니다."
              />
            </Inform>
          </Title>
          <ButtonGroup>
            {gOneData.map((element, idx) => {
              return (
                <SelectBtn
                  $activeId={activeId}
                  $isDisabled={element.is_block}
                  key={element.idx}
                  onClick={() => {
                    onClickBtn(element.idx, element.is_block, element.name);
                  }}
                >
                  {element.name}
                  <Inform
                    onMouseEnter={() => {
                      onEnter(element.gid, idx);
                    }}
                    onMouseLeave={() => {
                      onLeave(element.gid);
                    }}
                  >
                    <IconInform />
                    {statisticsId === idx && !element.is_block ? (
                      <InformTooltip isShow={tooltip} text={element.text} />
                    ) : null}
                  </Inform>
                </SelectBtn>
              );
            })}
          </ButtonGroup>
        </ColumnGroup>

        <ColumnGroup>
          <Title>
            <P3>규칙 기반 알고리즘</P3>
            <Inform
              onMouseEnter={() => {
                onEnterTitle(2);
              }}
              onMouseLeave={() => {
                onLeaveTitle(2);
              }}
            >
              <IconInform />
              <TextTooltip
                isShow={rulesTooltip}
                algorithm
                text="해당 상품 조회 혹은 구매와 관련된 상품을 추천합니다."
              />
            </Inform>
          </Title>
          <ButtonGroup>
            {gTwoData.map((element, idx) => {
              return (
                <SelectBtn
                  $activeId={activeId}
                  $isDisabled={element.is_block}
                  $prevNo={gOneData.length}
                  key={element.idx}
                  onClick={() => {
                    onClickBtn(element.idx, element.is_block, element.name);
                  }}
                >
                  {element.name}
                  <Inform
                    onMouseEnter={() => {
                      onEnter(element.gid, idx);
                    }}
                    onMouseLeave={() => {
                      onLeave(element.gid);
                    }}
                  >
                    <IconInform />
                    {rulesId === idx && !element.is_block ? (
                      <InformTooltip isShow={tooltip} text={element.text} />
                    ) : null}
                  </Inform>
                </SelectBtn>
              );
            })}
          </ButtonGroup>
        </ColumnGroup>

        <ColumnGroup>
          <Title>
            <P3>AI 기반 알고리즘</P3>
            <Inform
              onMouseEnter={() => {
                onEnterTitle(3);
              }}
              onMouseLeave={() => {
                onLeaveTitle(3);
              }}
            >
              <IconInform />
              <TextTooltip
                isShow={filterTooltip}
                algorithm
                text="회원의 구매 패턴과 관련된 상품을 추천합니다."
              />
            </Inform>
          </Title>
          <ButtonGroup>
            {gThreeData.map((element, idx) => {
              return (
                <SelectBtn
                  $activeId={activeId}
                  $isDisabled={element.is_block}
                  $prevNo={gOneData.length + gTwoData.length}
                  key={element.idx}
                  onClick={() => {
                    onClickBtn(element.idx, element.is_block, element.name);
                  }}
                >
                  {element.name}
                  <Inform
                    onMouseEnter={() => {
                      onEnter(element.gid, idx);
                    }}
                    onMouseLeave={() => {
                      onLeave(element.gid);
                    }}
                  >
                    <IconInform />
                    {filterId === idx && !element.is_block ? (
                      <InformTooltip isShow={tooltip} text={element.text} />
                    ) : null}
                  </Inform>
                </SelectBtn>
              );
            })}
          </ButtonGroup>
        </ColumnGroup>
      </Wrap>
    </Component>
  );
};

export default SelectAlgorithm;
