import React from 'react';
import styled, { css } from 'styled-components';
import Select from 'react-select';

const Component = styled(Select)<{ $state?: number; $type?: string }>`
  ${(props) => {
    switch (props.$type) {
      case 'state':
        return css`
          display: flex;
          width: 100%;
        `;
      case 'page':
        return css`
          width: 354px;
          margin-bottom: 30px;
          color: ${(props) => props.theme.colors.black};
        `;
      default:
        return css`
          width: 100%;
          margin-right: 10px;
          color: ${(props) => props.theme.colors.black};
        `;
    }
  }}
  .Select__control {
    height: 40px;
    cursor: pointer;
    border-radius: 5px;

    ${(props) => {
      switch (props.$type) {
        case 'state':
          return css`
            font-size: 12px;
          `;
        case 'page':
          return css`
            font-size: 14px;
          `;
        default:
          return css`
            font-size: 14px;
          `;
      }
    }}

    ${(props) => {
      switch (props.$state) {
        case 0:
          return css`
            background-color: #e0e0e0;
            border: 1px solid #e0e0e0;
          `;
        case 1:
          return css`
            background-color: #deebff;
            border: 1px solid #deebff;
            color: #0049b0;
          `;
        case 2:
          return css`
            background-color: #e3fcef;
            border: 1px solid #e3fcef;
          `;
        default:
          break;
      }
    }}
  }

  .Select__single-value {
    ${(props) => {
      switch (props.$state) {
        case 0:
          return css`
            color: #3d4954;
          `;
        case 1:
          return css`
            color: #0049b0;
          `;
        case 2:
          return css`
            color: #006644;
          `;
        default:
          break;
      }
    }}
  }

  .Select__control:hover {
    border-color: #a1a1a1;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 0px black;
    outline: none;
  }

  .Select__input-container {
    input {
      caret-color: transparent;
    }
  }

  .Select__value-container {
    padding-right: 0px;
  }
  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    ${(props) => {
      switch (props.$type) {
        case 'state':
          return css`
            font-size: 12px;
            color: #3c3d3e;
          `;
        case 'page':
          return css`
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
          `;
        default:
          return css`
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
          `;
      }
    }}
  }

  .Select__dropdown-indicator {
    padding-left: 0px;
    svg {
      ${(props) => {
        switch (props.$state) {
          case 0:
            return css`
              color: #3d4954;
            `;
          case 1:
            return css`
              color: #0049b0;
            `;
          case 2:
            return css`
              display: none;
              color: #006644;
            `;

          default:
            break;
        }
      }}
    }
  }
`;

interface SelectBoxProps {
  state?: number;
  type?: string;
  option: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: {
    value: string | number;
    label: string;
  };
  value?: {
    value: string | number;
    label: string;
  };
  disabled?: boolean;
  placeholder?: string;
  onChange?: (changeValue: any) => void;
}

const SelectBox = ({
  state,
  type,
  option,
  defaultValue,
  value,
  disabled,
  placeholder,
  onChange,
}: SelectBoxProps) => {
  return (
    <Component
      classNamePrefix="Select"
      $state={state}
      $type={type}
      options={option}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default SelectBox;
