import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';

import {
  SetCheckPasswordParamType,
  SetCheckPasswordResponseType,
  SetEditUserInfoParamType,
  SetEditUserInfoResponseType,
  SetEditPasswordParamType,
  SetEditPasswordResponseType,
} from '../../api/account/type';

// 비밀번호 확인
export const SET_CHECK_PASSWORD = 'account/SET_CHECK_PASSWORD' as const;
export const SET_CHECK_PASSWORD_SUCCESS =
  'account/SET_CHECK_PASSWORD_SUCCESS' as const;
export const SET_CHECK_PASSWORD_ERROR =
  'account/SET_CHECK_PASSWORD_ERROR' as const;
// 기본정보 변경
export const SET_EDIT_USER_INFO = 'account/SET_EDIT_USER_INFO' as const;
export const SET_EDIT_USER_INFO_SUCCESS =
  'account/SET_EDIT_USER_INFO_SUCCESS' as const;
export const SET_EDIT_USER_INFO_ERROR =
  'account/SET_EDIT_USER_INFO_ERROR' as const;
// 비밀번호 변경
export const SET_EDIT_PASSWORD = 'account/SET_EDIT_PASSWORD' as const;
export const SET_EDIT_PASSWORD_SUCCESS =
  'account/SET_EDIT_PASSWORD_SUCCESS' as const;
export const SET_EDIT_PASSWORD_ERROR =
  'account/SET_EDIT_PASSWORD_ERROR' as const;

export const setCheckPasswordAsync = createAsyncAction(
  SET_CHECK_PASSWORD,
  SET_CHECK_PASSWORD_SUCCESS,
  SET_CHECK_PASSWORD_ERROR
)<SetCheckPasswordParamType, SetCheckPasswordResponseType, AxiosErrorType>();

export const setEditUserInfoAsync = createAsyncAction(
  SET_EDIT_USER_INFO,
  SET_EDIT_USER_INFO_SUCCESS,
  SET_EDIT_USER_INFO_ERROR
)<SetEditUserInfoParamType, SetEditUserInfoResponseType, AxiosErrorType>();

export const setEditPasswordAsync = createAsyncAction(
  SET_EDIT_PASSWORD,
  SET_EDIT_PASSWORD_SUCCESS,
  SET_EDIT_PASSWORD_ERROR
)<SetEditPasswordParamType, SetEditPasswordResponseType, AxiosErrorType>();

export const SET_ERROR_NULL_ACCOUNT = 'account/SET_ERROR_NULL_ACCOUNT';
export const setErrorNullAccount = createAction(SET_ERROR_NULL_ACCOUNT)();
