import styled, { css } from 'styled-components';

export const TableRow = styled.tr<{ $isHighlight?: boolean }>`
  /* width: 100%; */
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
  ${(props) => {
    if (props.$isHighlight) {
      return css`
        background-color: rgba(0, 141, 237, 0.1);
        ::after {
          content: '';
          position: absolute;
          left: 0px;
          height: 71px;
          width: 3px;
          background-color: ${(props) => props.theme.colors.blue400};
        }
      `;
    }
    return css``;
  }}
`;

export const TableSubRow = styled.tr`
  /* width: 100%; */
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  &:last-child {
    border-bottom: none;
  }
`;

export default TableRow;
