import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { P3, H4 } from '../../Atoms/Typo';
import { Button } from '../../Atoms/Button';
import SelectButton from '../../Molecule/SelectButton';
import SelectPage from '../../Molecule/SelectPage';
import SelectAlgorithm from '../../Molecule/SelectAlgorithm';
import Spinner from '../../Molecule/Spinner';
import Input from '../../Atoms/Input';
import SubAlgorithm from '../../Molecule/SubAlgorithm';

const Component = styled(CardLayout)<{ $display: boolean }>`
  padding: 24px;
  ${(props) => {
    if (props.$display) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;

const Body = styled(CardBodyLayout)`
  margin-top: 8px;
`;

const Title = styled.div`
  ${H4} {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
  ${P3} {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
  margin-top: 8px;
`;

const Script = styled.div`
  font-size: 14px;
  padding-bottom: 40px;
  color: #424242;
`;

const AlgorithmWrap = styled.div`
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  Button {
    :first-child {
      margin-right: 8px;
    }
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 30px;
`;

const SubTitle = styled.div`
  margin-bottom: 10px;
  ${P3} {
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;
interface CreateRecommendCardProps {
  id: number;
  title: string;
  script?: string;
  displayCard: boolean;
  isButtonGroup?: boolean;
  nextText?: string;
  prevText?: string;
  segmentData:
    | {
        name: string;
        cnt: number;
        idx: number;
        id: number;
        gid: number;
        text: string;
      }[]
    | null;
  pageData: {
    option: {
      value: string;
      label: string;
    }[];
    img_url: {
      [key: string]: {
        pc: string;
        mobile: string;
      };
    };
  } | null;
  algorithmData:
    | {
        name: string;
        idx: number;
        gid: number;
        is_block: boolean;
        text: string;
        id?: number;
      }[]
    | null;
  loading: boolean;
  onClickNext: () => void;
  onClickPrev?: () => void;
  onClickCustomerID?: (id: number[]) => void;
  onClickAlgorithmID?: (id: number, name: string) => void;
  onClickSubAlgorithm?: (id: number, name: string) => void;
  isSubAlgorithm?: (state: boolean) => void;
  onClickPageID?: (id: string, name: string) => void;
  onCreateTitle?: (val: string) => void;
}

const CreateRecommendCard = ({
  id,
  title,
  script,
  displayCard,
  isButtonGroup,
  nextText,
  prevText,
  segmentData,
  pageData,
  algorithmData,
  loading,
  onClickNext,
  onClickPrev,
  onClickCustomerID,
  onClickAlgorithmID,
  onClickSubAlgorithm,
  isSubAlgorithm,
  onClickPageID,
  onCreateTitle,
}: CreateRecommendCardProps) => {
  // state
  const [custombtnID, setCustomBtnID] = useState(0);
  const [algorithmbtnID, setAlgorithmBtnID] = useState<number>();
  const [createTitle, setCreateTitle] = useState('');
  // 중복 체크
  // default 5
  const [testID, setTestID] = useState<number[]>([2]);
  const [postId, setPostId] = useState<number[]>([5]);

  const [isOpen, setIsOpen] = useState(false);

  // 중복 체크
  const isDuplicate = (arr: number[], id: number) => {
    // 회원 전체가 있으면 리스트에서 회원전체를 뺌
    if (arr.includes(1)) {
      arr = arr.filter((element) => element !== 1);
    }
    // 중복 체크
    const isDup = arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    let rebuild: number[] = [];
    if (isDup) {
      rebuild = arr.filter(
        (element, idx) => arr.indexOf(element) === idx && element !== id
      );

      return rebuild;
    }
    if (id === 1) {
      arr = [1];
      setCustomBtnID(1);
    }

    return arr;
  };

  const rebuildDup = (arr: any[], id: number, idx: number) => {
    if (arr.includes(null)) {
      arr = arr.filter((element) => element !== null);
    }
    // 중복 체크
    const isDup = arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    let rebuild: number[] = [];
    if (isDup) {
      rebuild = arr.filter(
        (element, idx) => arr.indexOf(element) === idx && element !== id
      );

      return rebuild;
    }
    if (idx === 1) {
      arr = [];
      setCustomBtnID(1);
    }

    return arr;
  };

  const onClickSegmentBtn = (idx: number, id: number) => {
    let temp: number[] = [];
    let postTemp: number[] = [];

    setCustomBtnID(idx); // activeID로 넘어가는 값은 idx로 제어

    testID.push(idx);
    postId.push(id);

    temp = isDuplicate(testID, idx);
    postTemp = rebuildDup(postId, id, idx);

    setIsOpen((prev) => {
      return !prev;
    });

    setTestID(temp);
    setPostId(postTemp);

    if (onClickCustomerID) {
      onClickCustomerID(postTemp);
    }
  };

  const onClickAlgorithmBtn = (id: number, name: string) => {
    setAlgorithmBtnID(id);
    if (onClickAlgorithmID) {
      onClickAlgorithmID(id, name);
    }
  };

  const onSubAlgorithm = (id: number, name: string) => {
    if (onClickSubAlgorithm) {
      onClickSubAlgorithm(id, name);
    }
  };

  const onToggleState = (state: boolean) => {
    if (isSubAlgorithm) {
      isSubAlgorithm(state);
    }
  };

  const onClickPageType = (type: string, name: string) => {
    if (onClickPageID) {
      onClickPageID(type, name);
    }
  };

  const onChangeTitle = (val: string) => {
    setCreateTitle(val);
  };

  const printCard = () => {
    switch (id) {
      case 1:
        return loading ? (
          <Spinner />
        ) : segmentData ? (
          <SelectButton
            data={segmentData}
            activeId={custombtnID}
            onClick={onClickSegmentBtn}
            test={testID}
          />
        ) : null;
      case 2:
        return loading ? (
          <Spinner />
        ) : pageData ? (
          <SelectPage pageData={pageData} onClickPageID={onClickPageType} />
        ) : null;
      case 3:
        return loading ? (
          <Spinner />
        ) : algorithmData ? (
          <AlgorithmWrap>
            <SelectAlgorithm
              data={algorithmData}
              activeId={algorithmbtnID}
              onClick={onClickAlgorithmBtn}
            />
            <SubAlgorithm
              data={algorithmData}
              mainId={algorithmbtnID}
              subAlgorithm={onSubAlgorithm}
              onToogleState={onToggleState}
            />
          </AlgorithmWrap>
        ) : null;
      case 4:
        return (
          <InputWrapper>
            <SubTitle>
              <P3>노출될 추천 타이틀 (쇼핑몰 회원이 보는 타이틀)</P3>
            </SubTitle>
            <Input
              size="curation"
              status="create"
              placeholder="예시) 이 상품과 함께 많이 본 상품이에요!"
              onChange={onChangeTitle}
            />
          </InputWrapper>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (onCreateTitle) {
      onCreateTitle(createTitle);
    }
  }, [createTitle]);

  return (
    <Component $display={displayCard}>
      <CardHeaderLayout>
        <Title>
          <H4>{title}</H4>
        </Title>
      </CardHeaderLayout>
      <Body>
        {script ? <Script>{script}</Script> : null}
        {printCard()}

        {isButtonGroup ? (
          <ButtonGroup>
            <Button size="xsamll" isPrev onClick={onClickPrev}>
              {prevText}
            </Button>

            <Button size="xsamll" onClick={onClickNext}>
              {nextText}
            </Button>
          </ButtonGroup>
        ) : (
          <Button size="xsamll" onClick={onClickNext}>
            {nextText}
          </Button>
        )}
      </Body>
    </Component>
  );
};

export default CreateRecommendCard;
