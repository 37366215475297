import { createReducer } from 'typesafe-actions';
import {
  GET_MANAGE_RECOMMEND_LIST,
  GET_MANAGE_RECOMMEND_LIST_SUCCESS,
  GET_MANAGE_RECOMMEND_LIST_ERROR,
  GET_MANAGE_RECOMMEND_DETAIL,
  GET_MANAGE_RECOMMEND_DETAIL_SUCCESS,
  GET_MANAGE_RECOMMEND_DETAIL_ERROR,
  GET_MANAGE_RECOMMEND_GRAPH,
  GET_MANAGE_RECOMMEND_GRAPH_SUCCESS,
  GET_MANAGE_RECOMMEND_GRAPH_ERROR,
  SET_RECOMMEND_NAME,
  SET_RECOMMEND_NAME_SUCCESS,
  SET_RECOMMEND_NAME_ERROR,
  SET_ERROR_NULL_MANAGEMENT,
  GET_ALGORITHM_CHECK,
  GET_ALGORITHM_CHECK_SUCCESS,
  GET_ALGORITHM_CHECK_ERROR,
  DELETE_RECOMMEND,
  DELETE_RECOMMEND_SUCCESS,
  DELETE_RECOMMEND_ERROR,
} from './actions';
import { managementAction, managementState } from './types';

const initialState: managementState = {
  isListLoading: false,
  isDetailLoading: false,
  isGraphLoading: false,
  isSetNameLoading: false,
  isItemListLoading: false,
  isDeleteLoading: false,

  recomListData: null,
  recomDetailData: null,
  recomGraphData: null,
  setNameData: false,
  recommendItemList: null,
  deleteStatus: false,
  isDeleteSuccess: false,

  managementError: null,
};

const reducer = createReducer<managementState, managementAction>(initialState, {
  // 개인화 추천 리스트
  [GET_MANAGE_RECOMMEND_LIST]: (state) => ({
    ...state,
    isListLoading: true,
    recomListData: null,
    managementError: null,
  }),
  [GET_MANAGE_RECOMMEND_LIST_SUCCESS]: (state, action) => ({
    ...state,
    isListLoading: false,
    recomListData: action.payload.payload,
  }),
  [GET_MANAGE_RECOMMEND_LIST_ERROR]: (state, action) => ({
    ...state,
    isListLoading: false,
    recomListData: null,
    managementError: action.payload,
  }),
  // 개인화 상세정보
  [GET_MANAGE_RECOMMEND_DETAIL]: (state) => ({
    ...state,
    isDetailLoading: true,
    recomDetailData: null,
    managementError: null,
  }),
  [GET_MANAGE_RECOMMEND_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    isDetailLoading: false,
    recomDetailData: action.payload.payload,
  }),
  [GET_MANAGE_RECOMMEND_DETAIL_ERROR]: (state, action) => ({
    ...state,
    isDetailLoading: false,
    recomDetailData: null,
    managementError: action.payload,
  }),
  // 개인화 상세정보 그래프
  [GET_MANAGE_RECOMMEND_GRAPH]: (state) => ({
    ...state,
    isGraphLoading: true,
    recomGraphData: null,
    managementError: null,
  }),
  [GET_MANAGE_RECOMMEND_GRAPH_SUCCESS]: (state, action) => ({
    ...state,
    isGraphLoading: false,
    recomGraphData: action.payload.payload,
  }),
  [GET_MANAGE_RECOMMEND_GRAPH_ERROR]: (state, action) => ({
    ...state,
    isGraphLoading: false,
    recomGraphData: null,
    managementError: action.payload,
  }),

  // 개인화 추천 이름 변경
  [SET_RECOMMEND_NAME]: (state) => ({
    ...state,
    isSetNameLoading: true,
    setNameData: false,
    managementError: null,
  }),
  [SET_RECOMMEND_NAME_SUCCESS]: (state, action) => ({
    ...state,
    setNameData: true,
    isSetNameLoading: false,
  }),
  [SET_RECOMMEND_NAME_ERROR]: (state, action) => ({
    ...state,
    setNameData: false,
    isSetNameLoading: false,
    managementError: action.payload,
  }),

  // 알고리즘 동작 확인 API
  [GET_ALGORITHM_CHECK]: (state) => ({
    ...state,
    isItemListLoading: true,
    recommendItemList: null,
    managementError: null,
  }),
  [GET_ALGORITHM_CHECK_SUCCESS]: (state, action) => ({
    ...state,
    isItemListLoading: false,
    recommendItemList: action.payload.payload,
  }),
  [GET_ALGORITHM_CHECK_ERROR]: (state, action) => ({
    ...state,
    isItemListLoading: false,
    recommendItemList: null,
    managementError: action.payload,
  }),

  // 대기중인 추천 삭제
  [DELETE_RECOMMEND]: (state) => ({
    ...state,
    isDeleteLoading: true,
    deleteStatus: false,
    isDeleteSuccess: false,
    managementError: null,
  }),
  [DELETE_RECOMMEND_SUCCESS]: (state) => ({
    ...state,
    isDeleteLoading: false,
    deleteStatus: true,
    isDeleteSuccess: true,
  }),
  [DELETE_RECOMMEND_ERROR]: (state, action) => ({
    ...state,
    isDeleteLoading: false,
    deleteStatus: true,
    isDeleteSuccess: false,
    managementError: action.payload,
  }),

  //
  [SET_ERROR_NULL_MANAGEMENT]: (state) => ({
    ...state,
    managementError: null,
  }),
});

export default reducer;
