import { takeLatest } from 'redux-saga/effects';
import {
  apiSetRecommendCreate,
  apiSetRecommendState,
  apiGetSegmentList,
  apiGetPageList,
  apiGetAlgorithmList,
} from '../../api/register/api';

import createRequestSaga from '../createRequestSaga';
import {
  SET_MANAGE_RECOMMEND_CREATE,
  SET_MANAGE_RECOMMEND_STATE,
  GET_MANAGE_SEGMENT,
  GET_MANAGE_PAGE,
  GET_MANAGE_ALGORITHM,
  setRecommendCreateAsync,
  setRecommendStateAsync,
  getSegmentListAsync,
  getPageListAsync,
  getAlgorithmListAsync,
} from './actions';

const setRecommendCreateSaga = createRequestSaga(
  setRecommendCreateAsync,
  apiSetRecommendCreate
);
const setRecommendStateSaga = createRequestSaga(
  setRecommendStateAsync,
  apiSetRecommendState
);
const getSegmentListSaga = createRequestSaga(
  getSegmentListAsync,
  apiGetSegmentList
);
const getPageListSaga = createRequestSaga(getPageListAsync, apiGetPageList);
const getAlgorithmListSaga = createRequestSaga(
  getAlgorithmListAsync,
  apiGetAlgorithmList
);

export function* registerSaga() {
  yield takeLatest(SET_MANAGE_RECOMMEND_CREATE, setRecommendCreateSaga);
  yield takeLatest(SET_MANAGE_RECOMMEND_STATE, setRecommendStateSaga);
  yield takeLatest(GET_MANAGE_SEGMENT, getSegmentListSaga);
  yield takeLatest(GET_MANAGE_PAGE, getPageListSaga);
  yield takeLatest(GET_MANAGE_ALGORITHM, getAlgorithmListSaga);
}

export { registerSaga as default };
