import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';

import {
  GetStatisticsParamType,
  GetStatisticsResponseType,
  GetPVMeanStatisticsParamType,
  GetPVMeanStatisticsResponseType,
  GetUVMeanStatisticsParamType,
  GetUVMeanStatisticsResponseType,
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  GetBrowserStaticsResponseType,
  GetDeviceStaticsResponseType,
  GetOSStaticsResponseType,
  GetCurationStatusParamType,
  GetCurationStatusResponseType,
} from '../../api/analysis/type';

export const GET_CURATION_STATUS = 'analysis/GET_CURATION_STATUS' as const;
export const GET_CURATION_STATUS_SUCCESS =
  'analysis/GET_CURATION_STATUS_SUCCESS' as const;
export const GET_CURATION_STATUS_ERROR =
  'analysis/GET_CURATION_STATUS_ERROR' as const;

export const GET_TOTAL_USER_STATISTICS =
  'serviceAnalytics/GET_TOTAL_USER_STATISTICS' as const;
export const GET_TOTAL_USER_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_TOTAL_USER_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_USER_STATISTICS_ERROR =
  'serviceAnalytics/GET_TOTAL_USER_STATISTICS_ERROR' as const;

export const GET_NEW_USER_STATISTICS =
  'serviceAnalytics/GET_NEW_USER_STATISTICS' as const;
export const GET_NEW_USER_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_NEW_USER_STATISTICS_SUCCESS' as const;
export const GET_NEW_USER_STATISTICS_ERROR =
  'serviceAnalytics/GET_NEW_USER_STATISTICS_ERROR' as const;

export const GET_PAGE_VIEW_USER_STATISTICS =
  'serviceAnalytics/GET_PAGE_VIEW_USER_STATISTICS' as const;
export const GET_PAGE_VIEW_USER_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_PAGE_VIEW_USER_STATISTICS_SUCCESS' as const;
export const GET_PAGE_VIEW_USER_STATISTICS_ERROR =
  'serviceAnalytics/GET_PAGE_VIEW_USER_STATISTICS_ERROR' as const;

export const GET_SESSION_USER_STATISTICS =
  'serviceAnalytics/GET_SESSION_USER_STATISTICS' as const;
export const GET_SESSION_USER_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_SESSION_USER_STATISTICS_SUCCESS' as const;
export const GET_SESSION_USER_STATISTICS_ERROR =
  'serviceAnalytics/GET_SESSION_USER_STATISTICS_ERROR' as const;

export const GET_PV_MEAN_STATISTICS =
  'serviceAnalytics/GET_PV_MEAN_STATISTICS' as const;
export const GET_PV_MEAN_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_PV_MEAN_STATISTICS_SUCCESS' as const;
export const GET_PV_MEAN_STATISTICS_ERROR =
  'serviceAnalytics/GET_PV_MEAN_STATISTICS_ERROR' as const;

export const GET_UV_MEAN_STATISTICS =
  'serviceAnalytics/GET_UV_MEAN_STATISTICS' as const;
export const GET_UV_MEAN_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_UV_MEAN_STATISTICS_SUCCESS' as const;
export const GET_UV_MEAN_STATISTICS_ERROR =
  'serviceAnalytics/GET_UV_MEAN_STATISTICS_ERROR' as const;

export const GET_USAGE_MEAN_STATISTICS =
  'serviceAnalytics/GET_USAGE_MEAN_STATISTICS' as const;
export const GET_USAGE_MEAN_STATISTICS_SUCCESS =
  'serviceAnalytics/GET_USAGE_MEAN_STATISTICS_SUCCESS' as const;
export const GET_USAGE_MEAN_STATISTICS_ERROR =
  'serviceAnalytics/GET_USAGE_MEAN_STATISTICS_ERROR' as const;

export const GET_DEVICE_STATICS =
  'serviceAnalytics/GET_DEVICE_STATICS' as const;
export const GET_DEVICE_STATICS_SUCCESS =
  'serviceAnalytics/GET_DEVICE_STATICS_SUCCESS' as const;
export const GET_DEVICE_STATICS_ERROR =
  'serviceAnalytics/GET_DEVICE_STATICS_ERROR' as const;

export const GET_OS_STATICS = 'serviceAnalytics/GET_OS_STATICS' as const;
export const GET_OS_STATICS_SUCCESS =
  'serviceAnalytics/GET_OS_STATICS_SUCCESS' as const;
export const GET_OS_STATICS_ERROR =
  'serviceAnalytics/GET_OS_STATICS_ERROR' as const;

export const GET_BROWSER_STATICS =
  'serviceAnalytics/GET_BROWSER_STATICS' as const;
export const GET_BROWSER_STATICS_SUCCESS =
  'serviceAnalytics/GET_BROWSER_STATICS_SUCCESS' as const;
export const GET_BROWSER_STATICS_ERROR =
  'serviceAnalytics/GET_BROWSER_STATICS_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_ANALYSIS =
  'serviceAnalytics/SET_ERROR_NULL_SERVICE_ANALYSIS';

export const getCurationStatusAsync = createAsyncAction(
  GET_CURATION_STATUS,
  GET_CURATION_STATUS_SUCCESS,
  GET_CURATION_STATUS_ERROR
)<GetCurationStatusParamType, GetCurationStatusResponseType, AxiosErrorType>();

export const getTotalUserStatisticsAsync = createAsyncAction(
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_TOTAL_USER_STATISTICS_ERROR
)<GetStatisticsParamType, GetStatisticsResponseType, AxiosErrorType>();

export const getNewUserStatisticsAsync = createAsyncAction(
  GET_NEW_USER_STATISTICS,
  GET_NEW_USER_STATISTICS_SUCCESS,
  GET_NEW_USER_STATISTICS_ERROR
)<GetStatisticsParamType, GetStatisticsResponseType, AxiosErrorType>();

export const getPageViewUserStatisticsAsync = createAsyncAction(
  GET_PAGE_VIEW_USER_STATISTICS,
  GET_PAGE_VIEW_USER_STATISTICS_SUCCESS,
  GET_PAGE_VIEW_USER_STATISTICS_ERROR
)<GetStatisticsParamType, GetStatisticsResponseType, AxiosErrorType>();

export const getSessionUserStatisticsAsync = createAsyncAction(
  GET_SESSION_USER_STATISTICS,
  GET_SESSION_USER_STATISTICS_SUCCESS,
  GET_SESSION_USER_STATISTICS_ERROR
)<GetStatisticsParamType, GetStatisticsResponseType, AxiosErrorType>();
export const getPVMeanStatisticsAsync = createAsyncAction(
  GET_PV_MEAN_STATISTICS,
  GET_PV_MEAN_STATISTICS_SUCCESS,
  GET_PV_MEAN_STATISTICS_ERROR
)<
  GetPVMeanStatisticsParamType,
  GetPVMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getUVMeanStatisticsAsync = createAsyncAction(
  GET_UV_MEAN_STATISTICS,
  GET_UV_MEAN_STATISTICS_SUCCESS,
  GET_UV_MEAN_STATISTICS_ERROR
)<
  GetUVMeanStatisticsParamType,
  GetUVMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getUsageMeanStatisticsAsync = createAsyncAction(
  GET_USAGE_MEAN_STATISTICS,
  GET_USAGE_MEAN_STATISTICS_SUCCESS,
  GET_USAGE_MEAN_STATISTICS_ERROR
)<
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getDeviceStaticsAsync = createAsyncAction(
  GET_DEVICE_STATICS,
  GET_DEVICE_STATICS_SUCCESS,
  GET_DEVICE_STATICS_ERROR
)<void, GetDeviceStaticsResponseType, AxiosErrorType>();

export const getOSStaticsAsync = createAsyncAction(
  GET_OS_STATICS,
  GET_OS_STATICS_SUCCESS,
  GET_OS_STATICS_ERROR
)<void, GetOSStaticsResponseType, AxiosErrorType>();

export const getBrowserStaticsAsync = createAsyncAction(
  GET_BROWSER_STATICS,
  GET_BROWSER_STATICS_SUCCESS,
  GET_BROWSER_STATICS_ERROR
)<void, GetBrowserStaticsResponseType, AxiosErrorType>();

export const setErrorNullServiceAnaysis = createAction(
  SET_ERROR_NULL_SERVICE_ANALYSIS
)();
