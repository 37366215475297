import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GetRecommendItemList } from '../../../api/management/type';
import { P3, P4 } from '../../Atoms/Typo';
import CardSubHeader from '../Heading/Card/CardSubHeader';

const Component = styled.div`
  width: 100%;
`;

const Script = styled.div`
  color: ${(props) => props.theme.colors.grey800};
  margin-bottom: 10px;
  span {
    font-weight: 500;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 8px;
`;

const Item = styled.div`
  padding: 15px;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  img {
    width: 100%;
    object-fit: fill;
    margin-bottom: 10px;
  }
`;

const ItemName = styled.div`
  height: 40px;
  overflow: hidden;
  margin-bottom: 20px;
  ${P3} {
    color: ${(props) => props.theme.colors.black};
    :hover {
      color: ${(props) => props.theme.colors.blue400};
    }
  }
`;

const ValWrapper = styled.div`
  ${P4} {
    color: ${(props) => props.theme.colors.black};
  }
`;

const ItemValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
  ${P4} {
    color: ${(props) => props.theme.colors.grey600};
  }
  span {
    font-weight: 500;
    color: ${(props) => props.theme.colors.blue400};
  }
`;

const Infrom = styled.div`
  display: flex;
  justify-content: flex-end;
  ${P4} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

interface RecommendItemListProps {
  algorithm: string;
  count: number;
  startDate: string;
  list: GetRecommendItemList[];
}

const RecommendItemList = ({
  algorithm,
  count,
  startDate,
  list,
}: RecommendItemListProps) => {
  // state
  const [date, setDate] = useState('');

  useEffect(() => {
    const today = new Date();
    let year = today.getFullYear();
    let month = ('0' + (1 + today.getMonth())).slice(-2);
    let day = ('0' + today.getDate()).slice(-2);
    setDate(year + '-' + month + '-' + day);
  }, []);

  return date === startDate ? null : (
    <Component>
      <CardSubHeader subTitle="노출될 추천 상품 리스트 Top 10" />
      {count === 0 ? (
        <Script>해당 고객에게 추천되는 상품 데이터를 수집중입니다.</Script>
      ) : count >= 10 ? (
        <Script>
          본 상품 리스트는 <span>"{algorithm}"</span> 알고리즘을 통해 고객들에게
          추천되는 상품입니다. <br />
          해당 고객에게 추천되는 상품 총 10개의 구매 높은 순으로 정렬되어있으며
          추천 진행 중 구매수에 따라 재 정렬될 수 있습니다.
        </Script>
      ) : (
        <Script>
          본 상품 리스트는 <span>"{algorithm}"</span> 알고리즘을 통해 고객들에게
          추천되는 상품입니다.
          <br /> 해당 고객에게 추천되는
          <span> 상품 총 {count.toLocaleString()}개 중 구매 전환률 Top 10</span>
          개 이며 추천이 진행되는 중 전환률에 따라 재정렬될 수 있습니다.
        </Script>
      )}
      <Grid>
        {list.length > 0
          ? list.map((element, idx) => {
              return (
                <Item key={element.id + idx}>
                  <a
                    target="_blank"
                    href={element.location}
                    rel="noopener noreferrer"
                  >
                    <img src={element.img_url} alt="" />
                    <ItemName>
                      <P3>{element.name}</P3>
                    </ItemName>
                  </a>
                  <ValWrapper>
                    <ItemValue>
                      구매수
                      <P4>
                        <span>{element.rcm_purchase.toLocaleString()}</span>/
                        {element.total_purchase.toLocaleString()}
                      </P4>
                    </ItemValue>
                    <ItemValue>
                      클릭수
                      <P4>
                        <span>{element.rcm_click.toLocaleString()}</span>/
                        {element.total_click.toLocaleString()}
                      </P4>
                    </ItemValue>
                    <ItemValue>
                      추천 노출수
                      <P4>
                        <span>{element.rcm_expose.toLocaleString()}</span>
                      </P4>
                    </ItemValue>
                  </ValWrapper>
                </Item>
              );
            })
          : null}
      </Grid>
      {list.length > 0 ? (
        <Infrom>
          <P4>*추천을 통해 집계된 수 / 전체 집계 수</P4>
        </Infrom>
      ) : null}
    </Component>
  );
};

export default RecommendItemList;
