import React from 'react';
import styled from 'styled-components';
import DefaultLink from '../../Atoms/Link';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.grey300};
  a {
    ${P3} {
      color: ${(props) => props.theme.colors.grey800};
    }
  }
`;

const LogoutEle = styled.div`
  display: flex;
  width: fit-content;
  padding-top: 16px;
  cursor: pointer;
`;
interface NavFooterLinkProps {
  onClickLogout: () => void;
}

const NavFooterLink = ({ onClickLogout }: NavFooterLinkProps) => {
  return (
    <Component className="voryq_nav_footer_link">
      <DefaultLink to="/settings">
        <P3>계정 설정</P3>
      </DefaultLink>
      <DefaultLink to="/support">
        <P3>도움말</P3>
      </DefaultLink>
      <LogoutEle onClick={onClickLogout}>
        <P3>로그아웃</P3>
      </LogoutEle>
    </Component>
  );
};

export default NavFooterLink;
