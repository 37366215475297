import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DataError from '../../Atoms/DataError';
import { IconInform } from '../../Atoms/Icon';
import EmptyGraphImage from '../../../assets/images/img-empty-graph.png';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { P1, P2 } from '../../Atoms/Typo';
import ButtonGroup from '../../Molecule/ButtonGroup';
import MultiLineChart from '../../Molecule/Chart/MultiLineChart';
import Spinner from '../../Molecule/Spinner';
import TextTooltip from '../../Molecule/TextTooltip';

const Component = styled(CardLayout)`
  margin-top: -163px;
  /* height: 100%; */
`;

const Header = styled(CardHeaderLayout)`
  align-items: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  padding-bottom: 20px;
  margin-bottom: 50px;
`;

const RateWrapper = styled.div`
  display: flex;
`;

const RateItem = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  ${P1} {
    font-size: 35px;
    color: ${(props) => props.theme.colors.black};
  }
  margin-right: 50px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  ${P2} {
    font-size: 17px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
  margin-bottom: 20px;
`;

const Rate = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 16px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  ${P1} {
    font-size: 35px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
    margin-right: 5px;
  }
`;

const ChartLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const ImgText = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 20%;
  color: ${(props) => props.theme.colors.grey800};
  text-align: center;
  align-items: center;
  justify-content: center;
`;

interface DashboardGraphCardProps {
  isLoading?: boolean;
  data: {
    click_rate: number;
    purchase_rate: number;
    graph_data: {
      [key: string]: {
        expose: number;
        click: number;
        purchase: number;
      };
    };
  } | null;
  activeTabID: number;
  onClickButtonGroup: (id: number) => void;
}

const DashboardGraphCard = ({
  isLoading,
  data,
  activeTabID,
  onClickButtonGroup,
}: DashboardGraphCardProps) => {
  const [clickRate, setClickRate] = useState(0);
  const [purchaseRate, setPurchaseRate] = useState(0);
  const [unit, setUnit] = useState(0);
  const [text, setText] = useState('');
  const [clickTooltip, setClickTooltip] = useState(false);
  const [purchaseTooltip, setPurchaseTooltip] = useState(false);

  const onClickUnit = (id: number) => {
    setUnit(id);
    onClickButtonGroup(id);
  };

  const onEnterClickInfo = () => {
    setClickTooltip(true);
  };

  const onEnterPurchaseInfo = () => {
    setPurchaseTooltip(true);
  };

  const onLeaveClickInfo = () => {
    setClickTooltip(false);
  };

  const onLeavePurchaseInfo = () => {
    setPurchaseTooltip(false);
  };

  const PrintGraphCard = () => {
    if (data) {
      return Object.keys(data.graph_data).length > 0 ? (
        <MultiLineChart
          DashboardData={data.graph_data}
          label={['추천 노출', '상품 클릭', '상품 구매']}
          graphHeight={350}
          unitId={unit}
          isDashboard
        />
      ) : (
        <ImgWrapper>
          <div>
            <Img src={EmptyGraphImage} alt="" />
          </div>
          <ImgText>{text}</ImgText>
        </ImgWrapper>
      );
    }
    return <DataError />;
  };

  useEffect(() => {
    if (data) {
      setClickRate(data.click_rate);
      setPurchaseRate(data.purchase_rate);
    }
  }, [data]);

  useEffect(() => {
    switch (unit) {
      case 0:
        setText(
          '오늘의 데이터를 모으는 중입니다. 내일이 되면 확인할 수 있습니다'
        );
        break;
      case 1:
        setText(
          '이번주의 데이터를 모으는 중입니다. 다음주가 되면 확인할 수 있습니다.'
        );
        break;
      case 2:
        setText(
          '이번달의 데이터를 모으는 중입니다. 다음달이 되면 확인할 수 있습니다.'
        );
        break;

      default:
        break;
    }
  }, [unit]);

  return (
    <Component>
      <CardBodyLayout>
        <Header>
          <RateWrapper>
            <RateItem>
              <Title>
                <P2>추천 클릭률</P2>
                <IconInform
                  onMouseEnter={onEnterClickInfo}
                  onMouseLeave={onLeaveClickInfo}
                />
                <TextTooltip
                  title
                  isShow={clickTooltip}
                  text="개인화 추천을 통해 노출된 상품 중 클릭된 상품 비율입니다."
                />
              </Title>
              <Rate>
                <P1>{clickRate}</P1>%
              </Rate>
            </RateItem>
            <RateItem>
              <Title>
                <P2>추천 상품 구매율</P2>
                <IconInform
                  onMouseEnter={onEnterPurchaseInfo}
                  onMouseLeave={onLeavePurchaseInfo}
                />
                <TextTooltip
                  title
                  isShow={purchaseTooltip}
                  text="개인화 추천을 통해 클릭된 상품 중 구매로 이어진 상품 비율입니다."
                />
              </Title>
              <Rate>
                <P1>{purchaseRate}</P1>%
              </Rate>
            </RateItem>
          </RateWrapper>
          <ButtonGroup
            buttonData={[
              {
                id: 0,
                title: '일별',
              },
              {
                id: 1,
                title: '주별',
              },
              {
                id: 2,
                title: '월별',
              },
            ]}
            activeID={activeTabID}
            onClick={onClickUnit}
          />
        </Header>
        {isLoading ? (
          <Spinner />
        ) : (
          <ChartLayout>{PrintGraphCard()}</ChartLayout>
        )}
      </CardBodyLayout>
    </Component>
  );
};

export default DashboardGraphCard;
