import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import loading from './loading';

import sidebarNav from './sidebarNav';
import datePicker, { datePickerSaga } from './datePicker';
import myPage, { myPageSaga } from './myPage';
import itemAnalysis, { itemAnalysisSaga } from './itemAnalysis';

// voryq
import auth, { authSaga } from './auth';
import dashboard, { dashboardSaga } from './dashboard';
import management, { managementSaga } from './management';
import register, { registerSaga } from './register';
import analysis, { analysisSaga } from './analysis';
import account, { accountSaga } from './account';

const rootReducer = combineReducers({
  loading,
  // authentication,
  sidebarNav,
  datePicker,
  myPage,
  auth,
  dashboard,
  management,
  register,
  analysis,
  itemAnalysis,
  account,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export function* rootSaga() {
  yield all([
    myPageSaga(),
    datePickerSaga(),
    authSaga(),
    dashboardSaga(),
    managementSaga(),
    registerSaga(),
    analysisSaga(),
    itemAnalysisSaga(),
    accountSaga(),
  ]);
}
