import { createReducer } from 'typesafe-actions';
import { authAction, authState } from './types';

import {
  SET_LOGIN,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOGOUT,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR,
  SET_ERROR_NULL_AUTH,
  GET_CHECK_SESSION,
  GET_CHECK_SESSION_SUCCESS,
  GET_CHECK_SESSION_ERROR,
  CHECK_COOKIE,
} from './actions';

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

const deleteCookie = (
  sKey: string,
  sDomain: string | null,
  sPath: string | null
) => {
  if (getCookie(sKey)) {
    document.cookie = `${sKey}=${sPath ? `;path=${sPath}` : ''}${
      sDomain ? `;domain=${sDomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

const initialState: authState = {
  isLogin: false,
  isLogout: false,
  loginLoading: false,
  setLoginError: null,
  setLogoutError: null,
  getCheckSessionError: null,
  setLoginErrorMessage: {
    '4010000':
      'ID 또는 비밀번호를 확인해주세요.\n5회 이상 잘못 입력시 계정이 잠깁니다.',
    '4000101':
      '비밀번호를 5회 이상 잘못 입력하여 계정이 잠겼습니다.\n관리자에게 문의하세요.',
    network: '네트워크 연결을 확인해주세요.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  setLogoutMessage: {
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default: '정상적으로 로그아웃되었습니다.',
  },
  getCheckSessionErrorMessage: {
    '4010000': '로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.',
    '4010001': '다른기기에서 로그인 하였습니다.\n다시 로그인 해주세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  userId: '',
  isVoda: false,
  created_time: '',
  user_name: '',
  user_phone: '',
  user_email: '',
  session_time: 3600,
  isCookie: false,
  isSessionValid: null,
  sessionValidTime: null,
};

const reducer = createReducer<authState, authAction>(initialState, {
  [SET_LOGIN]: (state) => ({
    ...state,
    loginError: null,
    loginLoading: true,
    setLoginError: null,
    isLogout: null,
  }),
  [SET_LOGIN_SUCCESS]: (state, action) => {
    localStorage.setItem('vq_tenant', action.payload.payload.tenant);
    return {
      ...state,
      isLogin: true,
      loginLoading: false,
      setLoginError: null,
      userId: action.payload.payload.user_id,
      created_time: action.payload.payload.create_time,
      user_name: action.payload.payload.user_name,
      user_email: action.payload.payload.email,
      user_phone: action.payload.payload.user_phone,
      isLogout: false,
    };
  },
  [SET_LOGIN_ERROR]: (state, action) => ({
    ...state,
    isLogin: false,
    loginLoading: false,
    setLoginError: action.payload,
  }),

  [SET_LOGOUT]: (state) => ({
    ...state,
    isLogin: false,
    loginLoading: true,
    isLogout: null,
  }),
  [SET_LOGOUT_SUCCESS]: (state, action) => {
    deleteCookie('sessionid', '.nerdfactory.ai', '/');
    deleteCookie('csrftoken', '.nerdfactory.ai', '/');
    localStorage.removeItem('vq_tenant');
    return {
      ...state,
      isLogin: false,
      loginLoading: false,
      setLogoutError: null,
      isLogout: true,
    };
  },
  [SET_LOGOUT_ERROR]: (state, action) => {
    deleteCookie('sessionid', '.nerdfactory.ai', '/');
    deleteCookie('csrftoken', '.nerdfactory.ai', '/');
    localStorage.removeItem('vq_tenant');
    return {
      ...state,
      isLogin: false,
      loginLoading: false,
      setLogoutError: action.payload,
      isLogout: true,
    };
  },

  [GET_CHECK_SESSION]: (state) => ({
    ...state,
    loginLoading: true,
    isLogout: null,
  }),
  [GET_CHECK_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    isLogin: true,
    loginLoading: false,
    getCheckSessionError: null,
    userId: action.payload.payload.user_id,
    created_time: action.payload.payload.created_time,
    user_name: action.payload.payload.user_name,
    user_email: action.payload.payload.email,
    user_phone: action.payload.payload.user_phone,
    isLogout: false,
  }),
  [GET_CHECK_SESSION_ERROR]: (state, action) => {
    localStorage.removeItem('vq_tenant');
    deleteCookie('sessionid', '.nerdfactory.ai', '/');
    deleteCookie('csrftoken', '.nerdfactory.ai', '/');
    return {
      ...state,
      isLogin: false,
      loginLoading: false,
      getCheckSessionError: action.payload,
    };
  },

  [SET_ERROR_NULL_AUTH]: (state) => ({
    ...state,
    setLoginError: null,
    setLogoutError: null,
  }),

  [CHECK_COOKIE]: (state, action) => ({
    ...state,
    isCookie: action.payload,
  }),
});

export default reducer;
