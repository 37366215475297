import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div`
  width: 45px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.grey200};
  padding: 2px;
  position: relative;
  cursor: pointer;
`;

const Circle = styled.div<{ $toggle?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: ${(props) => props.theme.colors.grey800};
  transition: transform 0.3s ease;
  ${(props) => {
    if (props.$toggle) {
      return css`
        background-color: ${(props) => props.theme.colors.blue400};
        transform: translate(20px, 0);
        transition: transform 0.3s ease;
      `;
    }
  }}
`;

interface ToggleButtonProps {
  onState: (state: boolean) => void;
}

const ToggleButton = ({ onState }: ToggleButtonProps) => {
  const [toggle, setToggle] = useState(false);

  const onClickToggle = () => {
    setToggle((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    onState(toggle);
  }, [onState, toggle]);
  return (
    <Component onClick={onClickToggle}>
      <Circle $toggle={toggle} />
    </Component>
  );
};

export default ToggleButton;
