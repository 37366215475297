import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { Button } from '../../Atoms/Button';
import Spinner from '../../Molecule/Spinner';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const SegButton = styled(Button)<{ $activeID: number | null }>`
  width: 180px;
  background-color: white;
  border: solid 1px #e0e0e0;
  color: ${(props) => props.theme.colors.black};
  border-radius: 20px;
  :nth-last-child(n + 2) {
    margin-right: 10px;
  }

  :hover {
    background-color: rgba(0, 141, 237, 0.1);
    border: solid 1px rgba(0, 141, 237, 0.1);
  }

  ${(props) => {
    if (props.$activeID) {
      return css`
        :nth-child(${props.$activeID}) {
          background-color: ${(props) => props.theme.colors.blue400};
          border: none;
          color: white;
        }
      `;
    }
    return css``;
  }}
`;

interface StatusSearchCardProps {
  loading?: boolean;
  segmentList:
    | {
        name: string;
        id: number;
        idx: number;
      }[]
    | null;
  onClick: (id: number | null) => void;
}

const StatusSearchCard = ({
  loading,
  segmentList,
  onClick,
}: StatusSearchCardProps) => {
  // state
  const [buttonID, setButtonID] = useState<number>(1);

  const onClickButton = (id: number | null, idx: number) => {
    setButtonID(idx);
    onClick(id);
  };

  return (
    <CardLayout>
      <DefaultCardHeading title="고객군 선택" />
      {loading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <ButtonGroup>
            {segmentList &&
              segmentList.map((element, idx) => {
                return (
                  <SegButton
                    key={element.id}
                    size="long"
                    $activeID={buttonID}
                    onClick={() => {
                      onClickButton(element.id, element.idx);
                    }}
                  >
                    {element.name}
                  </SegButton>
                );
              })}
          </ButtonGroup>
        </CardBodyLayout>
      )}
    </CardLayout>
  );
};

export default StatusSearchCard;
