import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { RootState } from '../../modules';

import {
  GetManageSegmentListParamType,
  GetManagePageListParamType,
  GetManageAlgorithmListParamType,
  SetManageRecommendCreateParamType,
} from '../../api/register/type';
import {
  getSegmentListAsync,
  getPageListAsync,
  getAlgorithmListAsync,
  setRecommendCreateAsync,
} from '../../modules/register';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import ContentsHeading from '../../components/Molecule/Heading/Contents/ContentsHeading';
import CreateRecommendCard from '../../components/Organisms/Card/CreateRecommnedCard';
import Modal from '../../components/Molecule/Modal';
import StepperCard from '../../components/Organisms/Card/StepperCard';
import { IconBreadCrum } from '../../components/Atoms/Icon';
import DisableInform from '../../components/Molecule/DisableInform';
import Spinner from '../../components/Molecule/Spinner';

const BreadCrumbs = styled.div`
  display: flex;
  color: #424242;
  font-size: 14px;
  padding-top: 40px;
  flex-direction: row;
`;

const Location = styled.div<{ $first?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  font-size: 14px;
  ${(props) => {
    if (props.$first) {
      return css`
        color: ${(props) => props.theme.colors.grey800};
        font-weight: normal;
      `;
    }
    return css``;
  }}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 10px 0px 16px 0px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
`;

const NewRecommend = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isSegmentLoading,
    isPageLoading,
    isAlgorithmLoading,
    isCreateLoading,
    setCreate,

    segData,
    pageData,
    algorithmData,
  } = useSelector((state: RootState) => state.register);

  const onGetSegmentList = (info: GetManageSegmentListParamType) => {
    dispatch(getSegmentListAsync.request(info));
  };
  const onGetPageList = (info: GetManagePageListParamType) => {
    dispatch(getPageListAsync.request(info));
  };
  const onGetAlgorithmList = (info: GetManageAlgorithmListParamType) => {
    dispatch(getAlgorithmListAsync.request(info));
  };
  const onSetRecommendCreate = (info: SetManageRecommendCreateParamType) => {
    dispatch(setRecommendCreateAsync.request(info));
  };

  const [customer, setCustomer] = useState(false);
  const [page, setPage] = useState(true);
  const [algorithm, setAlgorithm] = useState(true);
  const [recommendTitle, setRecommendTitle] = useState(true);
  const [isToggle, setIsToggle] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [isTitleCheck, setTitleCheck] = useState(false);
  const [stepperId, setStepperId] = useState(1);
  const [reload, setReload] = useState(false);

  const [segName, setSegName] = useState<string[]>(['']);
  const [pageName, setPageName] = useState('메인 페이지');
  const [algorithmName, setAlgorithmName] = useState('');
  const [subAlgorithmName, setSubAlgorithmName] = useState('');

  // 전달받은 selectButtonID, API 파라미터로 사용
  const [customerID, setCustomerID] = useState<number[]>([5]);
  const [algorithmID, setAlgorithmID] = useState<number>();
  const [subAlgorithm, setSubAlgorithm] = useState<number | null>();
  const [pageID, setPageID] = useState('a00');
  const [createTitle, setCreateTitle] = useState('');

  const onClickCustomer = () => {
    getSegmentName();
    setCustomer(true);
    setPage(false);
    setStepperId(2);
  };
  const onClickPagePrev = () => {
    setPage(true);
    setCustomer(false);
    setStepperId(1);
  };
  const onClickPageNext = () => {
    setPage(true);
    setAlgorithm(false);
    setStepperId(3);
    onGetAlgorithmList({
      page_type: pageID,
    });
  };
  const onClickAlgorithmPrev = () => {
    setAlgorithm(true);
    setPage(false);
    setStepperId(2);
  };
  const onClickAlgorithmNext = () => {
    if (!algorithmID) {
      setIsCheckOpen(true);
    } else {
      setStepperId(4);
      setAlgorithm(true);
      setRecommendTitle(false);
    }
  };
  const onClickTitlePrev = () => {
    setRecommendTitle(true);
    setAlgorithm(false);
    setStepperId(3);
  };

  // 생성 모달 open
  const onClickCreate = () => {
    if (createTitle) {
      setIsOpen(true);
    } else {
      setTitleCheck(true);
    }
  };

  // 전달 받은 selectbox ID
  const SelectCustomerId = (id: number[]) => {
    setCustomerID(id);
  };

  const SelectAlgorithmId = (id: number, name: string) => {
    setAlgorithmID(id);
    setAlgorithmName(name);
  };

  const SelectSubAlgorithm = (id: number, name: string) => {
    setSubAlgorithm(id);
    setSubAlgorithmName(name);
  };

  const isSubAlgorithm = (state: boolean) => {
    setIsToggle(state);
  };

  const SelectPageId = (id: string, name: string) => {
    setPageID(id);
    setPageName(name);
  };

  const CreateTitle = (val: string) => {
    setCreateTitle(val);
  };

  // modal
  const ModalClose = () => {
    setIsOpen(false);
    setIsCheckOpen(false);
    setTitleCheck(false);
  };
  const ModalConfirm = () => {
    if (algorithmID && subAlgorithm !== undefined) {
      onSetRecommendCreate({
        segment: customerID,
        page_type: pageID,
        algorithm: algorithmID,
        name: createTitle,
        sub_algorithm: subAlgorithm,
      });
      setReload((prev) => {
        return !prev;
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    onGetSegmentList({});
    onGetPageList({});
  }, []);

  useEffect(() => {
    if (setCreate && reload) {
      history.replace('/curation');
    }
  }, [setCreate, reload, history]);

  useEffect(() => {
    if (subAlgorithm === algorithmID || !isToggle) {
      setSubAlgorithm(null);
    }
  }, [subAlgorithm, algorithmID, isToggle]);

  const getSegmentName = () => {
    let rebuildData = [];
    if (segData) {
      for (let n = 0; n < customerID.length; n++) {
        let data = segData.filter((element) => element.id === customerID[n]);
        rebuildData.push(data[0].name);
      }
      if (customerID.length < 1) {
        rebuildData.push('회원 전체');
      }
    }
    setSegName(rebuildData);
  };

  return (
    <ContentsLayout>
      <Modal
        isOpen={isOpen}
        isPublic={true}
        message={
          '설정된 방식으로 추천을 생성할까요?\n추천을 생성하면 수정이 불가능합니다.'
        }
        closeBtnName="취소"
        confirmBtnName="생성"
        handleClose={ModalClose}
        handleConfirm={ModalConfirm}
      />
      <Modal
        isOpen={isCheckOpen}
        isDefault
        isPublic={true}
        message={'알고리즘을 선택해주세요.'}
        handleClose={ModalClose}
        handleConfirm={ModalClose}
      />
      <Modal
        isOpen={isTitleCheck}
        isDefault
        isPublic={true}
        message={'타이틀을 입력해주세요.'}
        handleClose={ModalClose}
        handleConfirm={ModalClose}
      />

      <BreadCrumbs>
        <Location $first>개인화 추천 관리</Location>
        <IconBreadCrum />
        <Location>새로운 추천</Location>
      </BreadCrumbs>
      <Header>
        <ContentsHeading title="새로운 추천" />
      </Header>
      {isCreateLoading ? (
        <Spinner />
      ) : (
        <ContentBodyLayout>
          <ContentsItemLayout desktop={9}>
            <CreateRecommendCard
              id={1}
              loading={isSegmentLoading}
              title="1. 추천 고객군 선택"
              script="추천 받을 고객군을 선택해주세요. (중복 선택 가능)"
              displayCard={customer}
              segmentData={segData}
              pageData={pageData}
              algorithmData={algorithmData}
              onClickNext={onClickCustomer}
              nextText="다음"
              onClickCustomerID={SelectCustomerId}
            />
            <CreateRecommendCard
              id={2}
              loading={isPageLoading}
              title="2. 노출 페이지 선택"
              script="추천 상품이 보여질 페이지를 선택해주세요."
              displayCard={page}
              segmentData={segData}
              pageData={pageData}
              algorithmData={algorithmData}
              isButtonGroup
              onClickNext={onClickPageNext}
              onClickPrev={onClickPagePrev}
              onClickPageID={SelectPageId}
              prevText="이전"
              nextText="다음"
            />
            <CreateRecommendCard
              id={3}
              loading={isAlgorithmLoading}
              title="3. 추천 알고리즘 선택"
              displayCard={algorithm}
              segmentData={segData}
              pageData={pageData}
              algorithmData={algorithmData}
              isButtonGroup
              onClickNext={onClickAlgorithmNext}
              onClickPrev={onClickAlgorithmPrev}
              onClickAlgorithmID={SelectAlgorithmId}
              onClickSubAlgorithm={SelectSubAlgorithm}
              isSubAlgorithm={isSubAlgorithm}
              prevText="이전"
              nextText="다음"
            />
            <CreateRecommendCard
              id={4}
              loading={isAlgorithmLoading}
              title="4. 추천 타이틀 입력"
              script="쇼핑몰에 보여질 추천의 타이틀을 입력해주세요."
              displayCard={recommendTitle}
              segmentData={segData}
              pageData={pageData}
              algorithmData={algorithmData}
              isButtonGroup
              onClickNext={onClickCreate}
              onClickPrev={onClickTitlePrev}
              onCreateTitle={CreateTitle}
              prevText="이전"
              nextText="생성"
            />
          </ContentsItemLayout>
          <ContentsItemLayout desktop={3}>
            <StepperCard
              isActive={stepperId}
              data={[
                {
                  id: 1,
                  title: '추천 고객군 선택',
                },
                {
                  id: 2,
                  title: '노출 페이지 선택',
                },
                {
                  id: 3,
                  title: '추천 알고리즘 선택',
                },
                {
                  id: 4,
                  title: '추천 타이틀 입력',
                },
              ]}
              segmentList={segName}
              pageName={pageName}
              algorithmName={algorithmName}
              subAlgorithmName={subAlgorithmName}
              isSubAlgorithm={isToggle}
            />
            {stepperId === 3 ? <DisableInform /> : null}
          </ContentsItemLayout>
        </ContentBodyLayout>
      )}
    </ContentsLayout>
  );
};

export default NewRecommend;
