import { createReducer } from 'typesafe-actions';
import {
  GET_ITEM_ANALYSIS,
  GET_ITEM_ANALYSIS_SUCCESS,
  GET_ITEM_ANALYSIS_ERROR,
  GET_ANALYSIS_ALGORITHM,
  GET_ANALYSIS_ALGORITHM_SUCCESS,
  GET_ANALYSIS_ALGORITHM_ERROR,
  SET_ERROR_NULL_ITEM_ANALYSIS,
} from './actions';
import { itemAnalysisAction, itemAnalysisState } from './types';

const initialState: itemAnalysisState = {
  isItemListLoading: false,
  isAlgorithmLoading: false,

  itemAnalysisData: null,
  algorithmListData: null,

  itemAnalysisError: null,
};

const reducer = createReducer<itemAnalysisState, itemAnalysisAction>(
  initialState,
  {
    // 데이터 리스트
    [GET_ITEM_ANALYSIS]: (state) => ({
      ...state,
      isItemListLoading: true,
      itemAnalysisData: null,
      itemAnalysisError: null,
    }),
    [GET_ITEM_ANALYSIS_SUCCESS]: (state, action) => ({
      ...state,
      isItemListLoading: false,
      itemAnalysisData: action.payload.payload,
    }),
    [GET_ITEM_ANALYSIS_ERROR]: (state, action) => ({
      ...state,
      isItemListLoading: false,
      itemAnalysisData: null,
      itemAnalysisError: action.payload,
    }),

    // 알고리즘 리스트
    [GET_ANALYSIS_ALGORITHM]: (state) => ({
      ...state,
      isAlgorithmLoading: true,
      algorithmListData: null,
      itemAnalysisError: null,
    }),
    [GET_ANALYSIS_ALGORITHM_SUCCESS]: (state, action) => ({
      ...state,
      isAlgorithmLoading: false,
      algorithmListData: action.payload.payload,
    }),
    [GET_ANALYSIS_ALGORITHM_ERROR]: (state, action) => ({
      ...state,
      isAlgorithmLoading: false,
      algorithmListData: null,
      itemAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_ITEM_ANALYSIS]: (state) => ({
      ...state,
      itemAnalysisError: null,
    }),
  }
);

export default reducer;
