import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../components/Organisms/Header';
import CardLayout from '../../components/Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../components/Atoms/Layout/Card/CardHeaderLayout';
import { H2, H4, P3, P1 } from '../../components/Atoms/Typo';
import CardBodyLayout from '../../components/Atoms/Layout/Card/CardBodyLayout';
import FAQImage from '../../assets/images/img-faq@2x.png';
import CardButtonGroup from '../../components/Molecule/CardButtonGroup';

const Title = styled(CardHeaderLayout)`
  margin-bottom: 20px;
  ${H2} {
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  } ;
`;
const Body = styled(CardBodyLayout)<{ $last?: boolean }>`
  flex-direction: row;
  padding: 35px 80px 63px 80px;
  background-color: ${(props) => props.theme.colors.grey100};
  ${(props) => {
    if (props.$last) {
      return css`
        padding: 30px;
      `;
    }
    return css``;
  }}
`;

const Layout = styled(CardLayout)`
  padding: 0px 24px 40px 24px;
`;

const TextWrapper = styled.div<{ $last?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  ${H4} {
    margin-bottom: 10px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
  ${P3} {
    margin-bottom: 40px;
  }
  ${(props) => {
    if (props.$last) {
      return css`
        width: 100%;
      `;
    }
    return css``;
  }}
`;
const Wrapper = styled.div`
  display: flex;
  width: 50%;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
const Group = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
const Script = styled.div`
  /* display: flex;
  flex-direction: row; */
`;
const TopButton = styled.div<{ $activeId: number }>`
  position: relative;
  display: flex;
  width: fit-content;
  height: 65px;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
  ${P1} {
    font-weight: bold;
    color: ${(props) => props.theme.colors.grey500};
  }
  ${(props) => {
    if (props.$activeId) {
      return css`
        &:nth-child(${props.$activeId}) {
          ::after {
            content: '';
            position: absolute;
            top: 0;
            height: 5px;
            width: 100%;
            border-top: 1px solid ${(props) => props.theme.colors.blue500};
            background-color: ${(props) => props.theme.colors.blue500};
          }
          ${P1} {
            font-weight: bold;
            color: ${(props) => props.theme.colors.black};
          }
        }
      `;
    }
    return css``;
  }}
`;

const Support = () => {
  const [buttonId, setButtonId] = useState(1);
  const titleList = ['자주묻는 질문', '추천 지수 안내', '고객군 분류'];

  const onClick = (id: number) => {
    switch (id) {
      case 1:
        setButtonId(1);
        break;
      case 2:
        setButtonId(2);
        break;
      case 3:
        setButtonId(3);
        break;
      default:
        break;
    }
  };

  const printCard = (id: number) => {
    switch (id) {
      case 1:
        return (
          <React.Fragment>
            <Title>
              <H2>문의하기</H2>
            </Title>
            <Script>
              AIVORY Q를 이용해주셔서 감사합니다. <br />
              사용중에 궁금하신 점이나 어려운 부분이 있다면 언제든 연락주세요.
              <br />
              <br />
              이메일 : contact@nerdfactory.ai
              <br />
              전화번호 : 042.820.9718
            </Script>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Title>
              <H2>개인화 추천 지수는 어떻게 볼까요?</H2>
            </Title>
            <Body>
              <TextWrapper>
                <H4>1. 추천 상품 클릭률</H4>
                <P3>
                  추천으로 노출된 상품대비 클릭된 상품 비율입니다.
                  <br />
                  클릭률이 높을수록 해당 추천의 효율이 높은것을 알 수 있습니다.
                </P3>
                <H4>2. 추천 재 이용률</H4>
                <P3>
                  추천된 상품을 한번이라도 클릭한 사용자가
                  <br />
                  다시 한번 더 추천된 상품을 클릭한 비율입니다.
                </P3>
                <H4>3. 추천 상품 구매 전환률</H4>
                <P3>
                  추천으로 조회된 상품들 중에 구매로 이어진 비율입니다.
                  <br />
                  구매 전환률에는 장바구니에서 구매한것과 상품을 바로 구매한것
                  모두 포함되어 있습니다.
                </P3>
              </TextWrapper>
              <Wrapper>
                <Img src={FAQImage} alt="" />
              </Wrapper>
            </Body>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Title>
              <H2>고객군은 어떻게 나뉠까요?</H2>
            </Title>
            <Body $last>
              <TextWrapper $last>
                <P3>
                  AIVORY Q에서는 보다 효율적인 추천을 위해 고객분들을 세분화하여
                  그에 맞는 추천을 제공하고자 연구합니다.
                  <br />
                  우선 해당 쇼핑몰을 모바일 또는 PC 등 접속 방식에 대해,
                  최근이나 자주 구매하는지 처음 구매하는지 등의 구매방식에 대해,
                  가격정보에 민감여부에 대해 등의 방식으로 분류하고 <br />
                  일주일 간격으로 고객군을 갱신하여 고객분들의 활동변화에
                  대응하며 적합한 상품을 추천할 수 있도록 합니다.
                  <br />
                  그에 따라 아래와 같은 고객군을 제공하고 이는 지속적으로 고도화
                  및 세분화될 수 있습니다.
                  <br />
                </P3>
                <H4>1. 신규 회원</H4>
                <P3>처음 서비스에 방문하여 가입한지 일주일된 회원입니다.</P3>
                <H4>2. 구매 목표가 명확한 회원</H4>
                <P3>
                  구매할 상품을 이미 파악하여 검색 후 바로 구매로 연결되길
                  바라는 회원입니다.
                </P3>
                <H4>3. 쇼핑몰을 둘러보는 회원</H4>
                <P3>
                  당장은 아니지만 앞으로 구매할 상품을 고르기위해 쇼핑몰을
                  방문한 회원입니다.
                </P3>
                <H4>4. 꼼꼼히 구매하는 회원</H4>
                <P3>
                  제품의 상세 내용이나 구매자의 후기를 꼼꼼히 살펴보고
                  의사결정을 하는 회원입니다.
                </P3>
                <H4>5. 세일 타겟 회원</H4>
                <P3>
                  쇼핑몰에서 제공하는 할인 혜택 혹은 기획전을 주로 둘러보며
                  가격이 저렴한 상품을 구매하려는 회원입니다.
                </P3>
                <H4>6. 회원 전체</H4>
                <P3>고객군을 구분짓지 않은 모든 회원을 의미합니다.</P3>
              </TextWrapper>
            </Body>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <ContentsLayout>
      <Header title="도움말" isDatePicker={false}></Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <Layout>
            <CardButtonGroup
              buttonId={buttonId}
              buttonList={titleList}
              onClick={onClick}
            />
            {/* <Group>
              <TopButton $activeId={buttonId} onClick={() => onClick(1)}>
                <P1>자주묻는 질문</P1>
              </TopButton>
              <TopButton $activeId={buttonId} onClick={() => onClick(2)}>
                <P1>추천 지수 안내</P1>
              </TopButton>
              <TopButton $activeId={buttonId} onClick={() => onClick(3)}>
                <P1>고객군 분류</P1>
              </TopButton>
            </Group> */}
            {printCard(buttonId)}
          </Layout>
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Support;
