import React from 'react';
import styled from 'styled-components';
import { Button } from '../../Atoms/Button';
import Input from '../../Atoms/Input';
import { P3, P4 } from '../../Atoms/Typo';
import CardSubHeader from '../Heading/Card/CardSubHeader';

const Component = styled.div`
  margin-bottom: 20px;
  position: relative;
  ${P3} {
    font-weight: bold;
  }
  &:focus-within {
    button {
      display: block;
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${P4} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

// const SubTitle = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-bottom: 10px;
// `;

const Save = styled(Button)`
  display: none;
  position: absolute;
  z-index: 1;
  margin-top: 5px;
  right: 0;
`;

interface InputWrapperProps {
  title: string;
  closeText?: boolean;
  isClose?: boolean;
  inputPlaceholder?: string;
  onChange?: (value: string) => void;
  onClickSave?: () => void;
}

const InputWrapper = ({
  title,
  closeText,
  isClose,
  inputPlaceholder,
  onChange,
  onClickSave,
}: InputWrapperProps) => {
  return (
    <Component>
      <Title>
        <CardSubHeader subTitle={title} />
        {closeText ? <P4>* 종료된 추천입니다.</P4> : null}
      </Title>
      {isClose ? (
        <Input size="curation" isDisabled placeholder={inputPlaceholder} />
      ) : (
        <Input
          size="curation"
          status="curation"
          placeholder={inputPlaceholder}
          onChange={onChange}
        />
      )}
      <Save size="xsamll" onClick={onClickSave}>
        저장
      </Save>
    </Component>
  );
};

export default InputWrapper;
