import { Grid } from '@material-ui/core';
import styled, { css, keyframes } from 'styled-components';

export const foldAnimation = keyframes`
  from {
    width: calc(100% - 240px);
  }
  to {
    width: calc(100% - 96px);
  }
`;

export const unfoldAnimation = keyframes`
  from {
    width: calc(100% - 96px);
  }
  to {
    width: calc(100% - 240px);
  }
`;

export const MainContainer = styled(Grid)`
  height: 100vh;
  z-index: 1;

  /* Desktop */
  @media ${(props) => props.theme.device.$desktop} {
    min-width: 1440px;
  }

  /* BigScreen */
  @media ${(props) => props.theme.device.$bigscreen} {
    width: 100%;
  }
`;

export const MainContentRouter = styled(Grid)<{ $isFold: boolean | null }>`
  /* common */
  z-index: 20;
  float: left;
  position: relative;

  width: calc(100% - 244px);
  min-height: 100vh;
  height: 100%;
  ${(props) => {
    if (props.$isFold) {
      return css`
        animation-name: ${foldAnimation};
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      `;
    }
    if (props.$isFold === false) {
      return css`
        animation-name: ${unfoldAnimation};
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      `;
    }
    return css``;
  }}
`;

export const MainContentRouterInner = styled(Grid)`
  display: flex;
  flex-direction: column;
  background: #f7f8f9;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

export const MainContentInner = styled(Grid)<{ $isScroll: boolean }>`
  flex: 1 0 auto;
  min-height: 100%;
  ${(props) => {
    if (props.$isScroll) {
      return css`
        padding: 0px 40px 160px 40px;
      `;
    }
    return css`
      padding: 0px 40px 40px 40px;
    `;
  }}
`;

export const Spinner = styled(Grid)`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
`;

export const BreadcumbTitle = styled.h2`
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    font-size: 25px;
  }

  @media (max-width: 500px) {
    font-size: 24px;
  }
`;
