import { createReducer } from 'typesafe-actions';
import {
  SET_CHECK_PASSWORD,
  SET_CHECK_PASSWORD_SUCCESS,
  SET_CHECK_PASSWORD_ERROR,
  SET_EDIT_PASSWORD,
  SET_EDIT_PASSWORD_SUCCESS,
  SET_EDIT_PASSWORD_ERROR,
  SET_EDIT_USER_INFO,
  SET_EDIT_USER_INFO_SUCCESS,
  SET_EDIT_USER_INFO_ERROR,
  SET_ERROR_NULL_ACCOUNT,
} from './actions';
import { accountAction, accountState } from './types';

const initialState: accountState = {
  isPasswordCheckLoading: false,
  passwordCheckState: false,
  getCheckPasswordError: null,
  getCheckPasswordErrorMessage: {
    '4010000': '비밀번호를 다시 확인해주세요.',
    '4000100':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },

  isEditUserLoading: false,
  editUserState: false,
  setEditUserError: null,
  setEditUserErrorMessage: {
    '4010000': '이미 사용중인 비밀번호입니다.',
    '4000100':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },

  isEditPasswordLoading: false,
  editPasswordState: false,
  setEditPasswordError: null,
  setEditPasswordMessage: {
    '4010000': '이미 사용중인 비밀번호입니다.',
    '4000100':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },

  accountError: null,
};

const reducer = createReducer<accountState, accountAction>(initialState, {
  [SET_CHECK_PASSWORD]: (state) => ({
    ...state,
    isPasswordCheckLoading: true,
    passwordCheckState: false,
    getCheckPasswordError: null,
    accountError: null,
  }),
  [SET_CHECK_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    isPasswordCheckLoading: false,
    passwordCheckState: action.payload.payload,
  }),
  [SET_CHECK_PASSWORD_ERROR]: (state, action) => ({
    ...state,
    isPasswordCheckLoading: false,
    passwordCheckState: false,
    getCheckPasswordError: action.payload,
    accountError: action.payload,
  }),

  [SET_EDIT_USER_INFO]: (state) => ({
    ...state,
    isEditUserLoading: true,
    editUserState: false,
    setEditUserError: null,
    accountError: null,
  }),
  [SET_EDIT_USER_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isEditUserLoading: false,
    editUserState: action.payload.payload,
  }),
  [SET_EDIT_USER_INFO_ERROR]: (state, action) => ({
    ...state,
    isEditUserLoading: false,
    editUserState: false,
    setEditUserError: action.payload,
    accountError: action.payload,
  }),

  [SET_EDIT_PASSWORD]: (state) => ({
    ...state,
    isEditPasswordLoading: true,
    editPasswordState: false,
    setEditPasswordError: null,
    accountError: null,
  }),
  [SET_EDIT_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    isEditPasswordLoading: false,
    editPasswordState: action.payload.payload,
  }),
  [SET_EDIT_PASSWORD_ERROR]: (state, action) => ({
    ...state,
    isEditPasswordLoading: false,
    editPasswordState: false,
    setEditPasswordError: action.payload,
    accountError: action.payload,
  }),

  [SET_ERROR_NULL_ACCOUNT]: (state) => ({
    ...state,
    accountError: null,
  }),
});

export default reducer;
