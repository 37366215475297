import React, { useState } from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { B1, H7 } from '../../Atoms/Typo';
import { IconInform } from '../../Atoms/Icon';

import Spinner from '../../Molecule/Spinner';
import TextTooltip from '../../Molecule/TextTooltip';

const Component = styled(CardLayout)`
  padding: 24px 15px 24px 20px;
  height: 140px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 24px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  ${H7} {
    white-space: nowrap;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
  svg {
    margin: 0px 0px 0px 5px;
  }
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface DashboardCardProps {
  title: string;
  titleInform: string;
  recommendData: {
    count: number;
    list: {
      name?: string;
      percent?: string | number;
      datetime?: string;
    }[];
  } | null;
  loading: boolean;
}

const DashboardCard = ({
  title,
  titleInform,
  recommendData,
  loading,
}: DashboardCardProps) => {
  const [titleTooltip, setTitleTooltip] = useState(false);

  const onEnterTitle = () => {
    setTitleTooltip(true);
  };
  const onLeaveTitle = () => {
    setTitleTooltip(false);
  };

  return (
    <Component>
      <CardHeaderLayout>
        <Title>
          <H7>{title}</H7>
          <IconInform onMouseEnter={onEnterTitle} onMouseLeave={onLeaveTitle} />
          <TextTooltip isShow={titleTooltip} title text={titleInform} />
        </Title>
      </CardHeaderLayout>
      {loading ? (
        <Spinner />
      ) : recommendData ? (
        <Body>
          <CountWrapper>
            <B1>{recommendData.count}</B1>&nbsp;개
          </CountWrapper>
        </Body>
      ) : null}
    </Component>
  );
};

export default DashboardCard;
