import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';
import {
  SetLoginParamType,
  SetLoginResponseType,
  SetLogoutResponseType,
  GetCheckSessionResponseType,
} from '../../api/auth/type';

// Login
export const SET_LOGIN = 'auth/SET_LOGIN' as const;
export const SET_LOGIN_SUCCESS = 'auth/SET_LOGIN_SUCCESS' as const;
export const SET_LOGIN_ERROR = 'auth/SET_LOGIN_ERROR' as const;

// logout
export const SET_LOGOUT = 'auth/SET_LOGOUT' as const;
export const SET_LOGOUT_SUCCESS = 'auth/SET_LOGOUT_SUCCESS' as const;
export const SET_LOGOUT_ERROR = 'auth/SET_LOGOUT_ERROR' as const;

// session
export const GET_CHECK_SESSION = 'auth/GET_CHECK_SESSION' as const;
export const GET_CHECK_SESSION_SUCCESS =
  'auth/GET_CHECK_SESSION_SUCCESS' as const;
export const GET_CHECK_SESSION_ERROR = 'auth/GET_CHECK_SESSION_ERROR' as const;

export const setLoginAsync = createAsyncAction(
  SET_LOGIN,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR
)<SetLoginParamType, SetLoginResponseType, AxiosErrorType>();

export const setLogoutAsync = createAsyncAction(
  SET_LOGOUT,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR
)<void, SetLogoutResponseType, AxiosErrorType>();

export const getCheckSessionAsync = createAsyncAction(
  GET_CHECK_SESSION,
  GET_CHECK_SESSION_SUCCESS,
  GET_CHECK_SESSION_ERROR
)<void, GetCheckSessionResponseType, AxiosErrorType>();

export const SET_ERROR_NULL_AUTH = 'auth/SET_ERROR_NULL' as const;
export const REFRESH_SESSION_TIME = 'auth/REFRESH_SESSION_TIME' as const;
export const CHECK_COOKIE = 'auth/CHECK_COOKIE' as const;

export const setErrorNullAuth = createAction(SET_ERROR_NULL_AUTH)();
export const refreshSessionTime = createAction(REFRESH_SESSION_TIME)();
export const checkCookie = createAction(CHECK_COOKIE)<boolean>();
