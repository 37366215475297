import React from 'react';
import styled from 'styled-components';
import { H7 } from '../../../Atoms/Typo';

const Component = styled.div``;

const Title = styled.div`
  ${H7} {
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;

interface DefaultCardHeadingProps {
  className?: string;
  title: string;
}

const DefaultCardHeading = ({ title, className }: DefaultCardHeadingProps) => {
  return (
    <Component className={className}>
      <Title>
        <H7>{title}</H7>
      </Title>
    </Component>
  );
};

export default DefaultCardHeading;
