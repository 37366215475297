import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import DefDatePicker from '../../Molecule/DefaultDatePicker';
import SelectBox from '../../Atoms/SelectBox';
import { P3 } from '../../Atoms/Typo';
import { Button } from '../../Atoms/Button';
import Spinner from '../../Molecule/Spinner';
import { GetAnalysisAlgorithmResponseType } from '../../../api/itemAnalysis/type';
// import { IconCalendarPicker } from '../../Atoms/Icon';

const Component = styled(CardLayout)`
  padding-bottom: 20px;
`;

const CardHeader = styled(DefaultCardHeading)`
  margin-bottom: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.colors.grey300};
  margin-bottom: 20px;
`;

const AllWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  ${P3} {
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 5px;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 230px;
  align-items: center;
  margin-right: 10px;
  svg {
    margin-right: 5px;
  }
`;

const SearchButton = styled(Button)`
  border-radius: 5px;
`;

interface StatisticsSearchCardProps {
  isloading?: boolean;
  algorithmOptions: GetAnalysisAlgorithmResponseType['payload'] | null;
  dateRange: (start: string, end: string) => void;
  getAlgorithm: (algorithm: number) => void;
  getSortField: (field: string) => void;
  getSortOrder: (order: string) => void;
  onClick: () => void;
}

const StatisticsSearchCard = ({
  isloading,
  algorithmOptions,
  dateRange,
  getAlgorithm,
  getSortField,
  getSortOrder,
  onClick,
}: StatisticsSearchCardProps) => {
  // state
  const [options, setOptions] = useState<
    GetAnalysisAlgorithmResponseType['payload']
  >([]);

  useEffect(() => {
    if (algorithmOptions) {
      setOptions(algorithmOptions);
    }
  }, [algorithmOptions]);

  // Options
  const SortField = [
    {
      value: 'exposure',
      label: '노출수',
    },
    {
      value: 'click',
      label: '클릭수',
    },
    {
      value: 'purchase',
      label: '구매수',
    },
  ];
  const SortOrder = [
    {
      value: 'desc',
      label: '내림차순',
    },
    {
      value: 'asc',
      label: '오름차순',
    },
  ];

  const algorithmChange = useCallback((inputValue) => {
    getAlgorithm(inputValue.value); // container로 넘김
  }, []);

  const sortChange = useCallback((inputValue) => {
    getSortField(inputValue.value); // container로 넘김
  }, []);
  const orderChange = useCallback((inputValue) => {
    getSortOrder(inputValue.value); // container로 넘김
  }, []);

  // string 으로 넘어온 date 값 Date type 으로 변환
  const settingDate = (date: any) => {
    if (date[1]) {
      dateRange(dateFormat(new Date(date[0])), dateFormat(new Date(date[1]))); // container로 넘김
    }
  };

  const dateFormat = (date: Date) => {
    const year = date.getFullYear();
    const month = ('0' + (1 + date.getMonth())).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
  };

  const onClickButton = () => {
    onClick();
  };

  return (
    <Component>
      <CardHeader title="통계 검색" />
      {isloading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <FlexRow>
            <AllWrap>
              <P3>필터</P3>
              <ItemWrap>
                <DateWrapper>
                  <DefDatePicker settingDate={settingDate} />
                </DateWrapper>
                <SelectBox
                  option={options}
                  placeholder="추천 알고리즘 선택"
                  onChange={algorithmChange}
                />
              </ItemWrap>
            </AllWrap>
            <AllWrap>
              <P3>정렬</P3>
              <ItemWrap>
                <SelectBox
                  option={SortField}
                  defaultValue={SortField[0]}
                  onChange={sortChange}
                />
                <SelectBox
                  option={SortOrder}
                  defaultValue={SortOrder[0]}
                  onChange={orderChange}
                />
              </ItemWrap>
            </AllWrap>
          </FlexRow>
          <SearchButton onClick={onClickButton}>검색</SearchButton>
        </CardBodyLayout>
      )}
    </Component>
  );
};

export default StatisticsSearchCard;
