import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  IconAlgorithm,
  IconBaseInform,
  IconDate,
  IconPageLocation,
  IconShopper,
  IconState,
} from '../../Atoms/Icon';
import { H4, P2, P3 } from '../../Atoms/Typo';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import Spinner from '../../Molecule/Spinner';
import SelectBox from '../../Atoms/SelectBox';

const Container = styled.div`
  position: fixed;
  width: 500px;
`;

const Component = styled(CardLayout)`
  padding: 20px;
`;

const DeleteRecom = styled.div<{ $iswaiting: boolean }>`
  display: none;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.grey800};
  ${(props) => {
    if (props.$iswaiting) {
      return css`
        display: flex;
      `;
    }
    return css``;
  }}
  ${P3} {
    margin-left: 20px;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  ${P3} {
    font-weight: bold;
  }
  svg {
    margin-right: 10px;
  }
`;

const RowInformData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  svg {
    min-width: 40px;
  }
`;
const InformName = styled.div`
  min-width: 90px;
  ${P3} {
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
`;
const InformDataWrap = styled.div`
  margin-left: 30px;
`;
const InformData = styled.div`
  display: flex;
  ${P2} {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
    min-width: 27px;
    margin-right: 5px;
  }
  ${P3} {
    font-weight: normal;
  }
`;

interface BasicInformCardProps {
  isloading?: boolean;
  detailData: {
    segment: string[];
    name: string;
    state: number;
    page_code: string;
    algorithm: string;
    sub_algorithm: string;
    start_datetime: string;
    end_datetime: string;
  } | null;
  changeState: (val: number) => void;
  onClickDel: (val: boolean) => void;
}

const BasicInformCard = ({
  isloading,
  detailData,
  changeState,
  onClickDel,
}: BasicInformCardProps) => {
  // state
  const [informState, setState] = useState<number>(-1);
  const [isWaiting, setIsWaiting] = useState(false);
  const [dateTitle, setDateTitle] = useState('');
  const [date, setDate] = useState<string | null>('');
  const [rebuild, setRebuild] = useState<(string | null)[][]>([]);
  const informTitle = [
    '고객군',
    '추천 위치',
    '추천 알고리즘',
    '시작일',
    '추천 상태',
  ];
  const subtitle = ['메인', '서브'];

  const statusFunc = (value: number) => {
    switch (value) {
      case 0:
        return [
          { value: 0, label: '대기중' },
          { value: 1, label: '진행중' },
        ];
      case 1:
        return [
          { value: 1, label: '진행중' },
          { value: 2, label: '종료' },
        ];

      default:
        return [{ value: 2, label: '종료' }];
    }
  };

  const onChangeState = (inputValue: any) => {
    changeState(inputValue.value); // 컨테이너로 전달
  };

  const onClickDelete = () => {
    onClickDel(true);
  };

  const dateInformRow = () => {
    if (detailData) {
      switch (detailData.state) {
        case 0:
          setDateTitle('시작일');
          setDate('-');
          break;
        case 1:
          setDateTitle('시작일');
          setDate(detailData.start_datetime);
          break;
        case 2:
          setDateTitle('시작일 - 종료일');
          setDate(detailData.start_datetime + ' - ' + detailData.end_datetime);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (detailData) {
      setState(detailData.state);
      setRebuild([
        detailData.segment,
        [detailData.page_code],
        [detailData.algorithm, '-'],
        [detailData.start_datetime, detailData.end_datetime],
        [],
      ]);
      if (detailData.sub_algorithm) {
        setRebuild([
          detailData.segment,
          [detailData.page_code],
          [detailData.algorithm, detailData.sub_algorithm],
          [detailData.start_datetime, detailData.end_datetime],
          [],
        ]);
      }

      if (detailData.state === 0) {
        setIsWaiting(true);
      } else {
        setIsWaiting(false);
      }
    }
    dateInformRow();
  }, [detailData]);

  const ImageData = (id: number) => {
    switch (id) {
      case 0:
        return <IconShopper />;
      case 1:
        return <IconPageLocation />;
      case 2:
        return <IconAlgorithm />;
      case 3:
        return <IconDate />;
      case 4:
        return <IconState />;

      default:
        break;
    }
  };

  return (
    <Container>
      <Component>
        {isloading ? (
          <Spinner />
        ) : (
          <CardBodyLayout>
            <Title>
              <IconBaseInform />
              <H4>기본 정보</H4>
            </Title>

            {rebuild.map((element, idx) => {
              return idx === 3 ? (
                <RowInformData key={informTitle[idx]}>
                  <IconDate />
                  <InformName>
                    <P3>{dateTitle}</P3>
                  </InformName>
                  <InformDataWrap>
                    <InformData>
                      <P3>{date}</P3>
                    </InformData>
                  </InformDataWrap>
                </RowInformData>
              ) : (
                <RowInformData key={informTitle[idx]}>
                  {ImageData(idx)}
                  <InformName>
                    <P3>{informTitle[idx]}</P3>
                  </InformName>
                  <InformDataWrap>
                    {element && element.length > 0 ? (
                      idx === 2 ? (
                        element.map((El, id) => {
                          return (
                            <InformData key={informTitle[id]}>
                              <P2>{subtitle[id]}</P2>
                              <P3>{El}</P3>
                            </InformData>
                          );
                        })
                      ) : (
                        element.map((El, id) => {
                          return (
                            <InformData key={informTitle[id]}>
                              <P3>{El}</P3>
                            </InformData>
                          );
                        })
                      )
                    ) : (
                      <SelectBox
                        option={statusFunc(informState)}
                        value={statusFunc(informState)[0]}
                        onChange={(inputValue) => {
                          onChangeState(inputValue);
                        }}
                        disabled={informState === 2}
                        state={informState}
                        type="state"
                      />
                    )}
                  </InformDataWrap>
                </RowInformData>
              );
            })}
          </CardBodyLayout>
        )}
      </Component>
      <DeleteRecom $iswaiting={isWaiting}>
        대기중인 추천만 삭제가 가능합니다.
        <P3 onClick={onClickDelete}>삭제하기</P3>
      </DeleteRecom>
    </Container>
  );
};

export default BasicInformCard;
