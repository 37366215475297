import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../createRequestSaga';
import {
  apiSetLogin,
  apiSetLogout,
  apiGetCheckSession,
} from '../../api/auth/api';
import {
  SET_LOGIN,
  setLoginAsync,
  SET_LOGOUT,
  setLogoutAsync,
  getCheckSessionAsync,
  GET_CHECK_SESSION,
} from './actions';

const setLoginSaga = createRequestSaga(setLoginAsync, apiSetLogin);

const setLogoutSaga = createRequestSaga(setLogoutAsync, apiSetLogout);

const getCheckSessionSaga = createRequestSaga(
  getCheckSessionAsync,
  apiGetCheckSession
);

export function* authSaga() {
  yield takeLatest(SET_LOGIN, setLoginSaga);
  yield takeLatest(SET_LOGOUT, setLogoutSaga);
  yield takeLatest(GET_CHECK_SESSION, getCheckSessionSaga);
}

export { authSaga as default };
