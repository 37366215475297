import React from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { P3 } from '../../Atoms/Typo';

const Layout = styled(CardLayout)`
  height: 300px;
`;

const Component = styled(CardBodyLayout)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const NoDataCard = () => {
  return (
    <Layout>
      <Component>
        <P3>
          아직 추천이 대기중입니다.
          <br />
          상태를 진행중으로 변경하면 추천 관련 데이터를 받아올 수 있습니다.
        </P3>
      </Component>
    </Layout>
  );
};

export default NoDataCard;
