import React from 'react';
import styled, { css } from 'styled-components';
import { ItemGrid } from './ContentsItemLayout';

const Component = styled.div<{
  $spacing?: 'xxs' | 'xs' | 'xsm' | 'sm' | 'md' | 'xlg' | 'lg' | 'xl';
  $detailPage?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  ${(props) => {
    if (props.$spacing) {
      return css`
        width: calc(100% + ${props.theme.spacing[props.$spacing] * 2}px);
        margin: -${props.theme.spacing[props.$spacing]}px;
        ${ItemGrid} {
          padding: ${props.theme.spacing[props.$spacing]}px;
        }
      `;
    }
    return css`
      padding: 0px;
    `;
  }}
  ${(props) => {
    if (props.$detailPage) {
      return css`
        margin-top: 150px;
      `;
    }
    return css``;
  }}
`;

interface ContentBodyLayoutProps {
  className?: string;
  detailPage?: boolean;
  children?: React.ReactNode;
}

const ContentBodyLayout = ({
  children,
  detailPage,
  className,
}: ContentBodyLayoutProps) => {
  return (
    <Component className={className} $spacing="xsm" $detailPage={detailPage}>
      {children}
    </Component>
  );
};

export default ContentBodyLayout;
