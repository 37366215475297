import React from 'react';
import styled, { css } from 'styled-components';
import { IconBreadCrum } from '../../Atoms/Icon';

import ContentsHeading from '../../Molecule/Heading/Contents/ContentsHeading';

const Component = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 40px 0px 16px 283px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 900;
  background-color: ${(props) => props.theme.colors.grey100};
`;

const BreadCrumbs = styled.div`
  display: flex;
  color: #424242;
  font-size: 14px;
  flex-direction: row;
`;

const Location = styled.div<{ $first?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  font-weight: 500;
  font-size: 14px;
  ${(props) => {
    if (props.$first) {
      return css`
        color: ${(props) => props.theme.colors.grey800};
        font-weight: normal;
      `;
    }
    return css``;
  }}
`;

interface DetailHeaderProps {
  title: string;
}

const DetailHeader = ({ title }: DetailHeaderProps) => {
  return (
    <Component>
      <BreadCrumbs>
        <Location $first>개인화 추천 관리</Location>
        <IconBreadCrum />
        <Location>추천 상세보기</Location>
      </BreadCrumbs>
      <ContentsHeading title={title} />
    </Component>
  );
};

export default DetailHeader;
