import React from 'react';
import styled, { css } from 'styled-components';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div<{
  $isShow: boolean;
  $title?: boolean;
  $algorithm?: boolean;
}>`
  position: absolute;
  z-index: 1;
  display: none;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  background-color: ${(props) => props.theme.colors.blue400};
  border-radius: 5px;
  ${P3} {
    color: ${(props) => props.theme.colors.white};
  }
  ${(props) => {
    if (props.$isShow) {
      if (props.$title) {
        return css`
          display: flex;
          transform: translate(70px, -40px);
        `;
      }
      if (props.$algorithm) {
        return css`
          display: flex;
          transform: translate(30px, -10px);
        `;
      }
      return css`
        display: flex;
        transform: translate(-15px, -70px);
      `;
    }
    return css``;
  }}
`;
interface TextTooltipProps {
  isShow: boolean;
  title?: boolean;
  algorithm?: boolean;
  text?: string;
}
const TextTooltip = ({ text, title, algorithm, isShow }: TextTooltipProps) => {
  return (
    <Component $isShow={isShow} $algorithm={algorithm} $title={title}>
      <P3>{text}</P3>
    </Component>
  );
};

export default TextTooltip;
