import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { P3 } from '../Typo';

const Component = styled(NavLink)<{ $className?: boolean }>`
  position: relative;
  border-radius: 5px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey800};
  }

  &.active {
    ${P3} {
      color: ${(props) => props.theme.colors.blue400};
    }

    background-color: ${(props) => props.theme.colors.blue100};
    ::before {
      content: '';
      position: absolute;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      height: 60%;
      width: 5px;
      background-color: ${(props) => props.theme.colors.blue400};
      left: 0px;
    }
  }
  :hover {
    ${P3} {
      color: ${(props) => props.theme.colors.blue400};
    }
  }
  ${(props) => {
    if (props.$className) {
      return css`
        ${P3} {
          color: ${(props) => props.theme.colors.blue400};
        }

        background-color: ${(props) => props.theme.colors.blue100};
        ::before {
          content: '';
          position: absolute;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          height: 60%;
          width: 5px;
          background-color: ${(props) => props.theme.colors.blue400};
          left: 0px;
        }
      `;
    }
    return css``;
  }}
`;

interface DefaultNavLinkProps {
  to: string;
  title: string;
  exact?: boolean;
  className?: boolean;
  children?: React.ReactNode;
}

const DefaultNavLink = ({
  to,
  title,
  exact,
  className,
  children,
}: DefaultNavLinkProps) => {
  return (
    <Component
      $className={className}
      exact={exact !== undefined ? exact : true}
      to={to}
    >
      {children}
      <P3>{title}</P3>
    </Component>
  );
};

export default DefaultNavLink;
