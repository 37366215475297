import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../modules';

import {
  GetDashboardAccumulateParamType,
  GetDashboardProgressParamType,
  GetDashboardEndedParamType,
  GetSegmentStatusParamType,
  GetUsageSegmentParamType,
  GetRecommendTotalGraphParamType,
} from '../../api/dashboard/type';
import {
  getAccumulateAsync,
  getProgressAsync,
  getEndedAsync,
  getSegmentStatusAsync,
  getUsageSegmentAsync,
  getRecommendTotalGraphAsync,
} from '../../modules/dashboard';

// components
import Header from '../../components/Organisms/Header';
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import DashboardCard from '../../components/Organisms/Card/DashboardCard';
import DashboardGraphCard from '../../components/Organisms/Card/DashboardGraphCard';
import DashboardDonutChart from '../../components/Organisms/Card/DashboardDonutChart';
import DashboardUsageCard from '../../components/Organisms/Card/DashboardUsageCard';

const Dashboard = () => {
  const today = new Date();
  let year = today.getFullYear();
  let month = ('0' + (1 + today.getMonth())).slice(-2);
  let day = ('0' + today.getDate()).slice(-2);
  let todayDate = year + '-' + month + '-' + day;

  const dispatch = useDispatch();
  const {
    isAccumulateLoading,
    isProgressLoading,
    isEndedLoading,
    isSegmentStatusLoading,
    isUsageSegmentLoading,
    isGraphLoading,

    accumData,
    ProgressData,
    endedData,
    segmentStatusData,
    usageSegmentData,
    totalGraphData,
  } = useSelector((state: RootState) => state.dashboard);

  const onGetAccumulateInfo = (info: GetDashboardAccumulateParamType) => {
    dispatch(getAccumulateAsync.request(info));
  };
  const onGetProgressInfo = (info: GetDashboardProgressParamType) => {
    dispatch(getProgressAsync.request(info));
  };
  const onGetEndedInfo = (info: GetDashboardEndedParamType) => {
    dispatch(getEndedAsync.request(info));
  };
  const onGetSegmentStatus = (info: GetSegmentStatusParamType) => {
    dispatch(getSegmentStatusAsync.request(info));
  };
  const onGetUsageSegment = (info: GetUsageSegmentParamType) => {
    dispatch(getUsageSegmentAsync.request(info));
  };
  const onGetRecommendTotalGraph = (info: GetRecommendTotalGraphParamType) => {
    dispatch(getRecommendTotalGraphAsync.request(info));
  };

  // state
  const [clickGraphTab, setClickGraphTab] = useState(0);
  const [clickUsageTab, setClickUsageTab] = useState(0);

  const [activeGraphDate, setActiveGraphDate] = useState('day');
  const [activeUsageDate, setActiveUsageDate] = useState('day');

  const onClickGraphTab = (id: number) => {
    setClickGraphTab(id);
    switch (id) {
      case 0:
        setActiveGraphDate('day');
        break;
      case 1:
        setActiveGraphDate('week');
        break;
      case 2:
        setActiveGraphDate('month');
        break;
      default:
        setActiveGraphDate('day');
        break;
    }
  };

  const onClickUseageTab = (id: number) => {
    setClickUsageTab(id);
    switch (id) {
      case 0:
        setActiveUsageDate('day');
        break;
      case 1:
        setActiveUsageDate('week');
        break;
      case 2:
        setActiveUsageDate('month');
        break;
      default:
        setActiveUsageDate('day');
        break;
    }
  };
  useEffect(() => {
    onGetAccumulateInfo({
      datetime: todayDate,
    });
    onGetProgressInfo({
      datetime: todayDate,
    });
    onGetEndedInfo({
      datetime: todayDate,
    });
    onGetSegmentStatus({
      datetime: todayDate,
    });
  }, [todayDate]);

  useEffect(() => {
    onGetUsageSegment({
      datetime: todayDate,
      unit: activeUsageDate,
    });
  }, [todayDate, activeUsageDate]);

  useEffect(() => {
    onGetRecommendTotalGraph({
      datetime: todayDate,
      unit: activeGraphDate,
    });
  }, [todayDate, activeGraphDate]);

  return (
    <ContentsLayout>
      <Header title="대시보드" isDatePicker={false}></Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={2.3}>
          <DashboardCard
            loading={isAccumulateLoading}
            title="누적 개인화 추천"
            titleInform="지금까지 생성된 개인화 추천 개수입니다."
            recommendData={accumData}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={2.3}>
          <DashboardCard
            loading={isProgressLoading}
            title="진행중인 개인화 추천"
            titleInform="현재 진행중인 개인화 추천 개수입니다."
            recommendData={ProgressData}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={2.3}>
          <DashboardCard
            loading={isEndedLoading}
            title="종료된 개인화 추천"
            titleInform="추천이 종료된 개인화 추천 개수입니다."
            recommendData={endedData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={5}>
          <DashboardDonutChart
            isLoading={isSegmentStatusLoading}
            segStatusData={segmentStatusData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={7}>
          <DashboardGraphCard
            isLoading={isGraphLoading}
            data={totalGraphData}
            activeTabID={clickGraphTab}
            onClickButtonGroup={onClickGraphTab}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={5}>
          <DashboardUsageCard
            isLoading={isUsageSegmentLoading}
            usageData={usageSegmentData}
            activeTabID={clickUsageTab}
            onClickButtonGroup={onClickUseageTab}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Dashboard;
