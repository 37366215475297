import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { IconInform } from '../../Atoms/Icon';
import InformTooltip from '../InformTooltip';

const ColumnGroup = styled.div<{ $allGroup?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  ${(props) => {
    if (props.$allGroup) {
      return css`
        width: 40%;
        padding-right: 0px;
      `;
    }
    return css``;
  }}
`;

const Split = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border-bottom: solid 1px ${(props) => props.theme.colors.grey200};
`;

const Inform = styled.div`
  display: flex;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectBtn = styled(Button)<{
  $activeId: number;
  $prevNo?: number;
  $test?: number[];
  $isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 334px; */
  width: 100%;
  height: 43px;
  padding: 11px 10px 12px 20px;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  color: ${(props) => props.theme.colors.black};
  background-color: #fff;
  margin-bottom: 4px;
  margin-right: 40px;

  :hover {
    background-color: rgba(0, 141, 237, 0.1);
    border: solid 1px rgba(0, 141, 237, 0.1);
    ${(props) => {
      if (props.$isDisabled) {
        return css`
          background-color: ${props.theme.colors.grey300};
          border: solid 1px ${props.theme.colors.grey300};
        `;
      }
    }}
  }
  ${(props) => {
    if (props.$isDisabled) {
      return css`
        background-color: ${props.theme.colors.grey300};
        cursor: not-allowed;
      `;
    }
    if (props.$test && props.$prevNo) {
      return css`
        :nth-child(${props.$test[0] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[1] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[2] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[3] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
        :nth-child(${props.$test[4] - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
      `;
    }
    if (props.$prevNo) {
      return css`
        :nth-child(${props.$activeId - props.$prevNo}) {
          background-color: rgba(0, 141, 237, 0.1);
          border: solid 1px #008ded;
        }
      `;
    }
    return css`
      :nth-child(${props.$activeId}) {
        background-color: rgba(0, 141, 237, 0.1);
        border: solid 1px #008ded;
      }
    `;
  }}
  :last-child {
    margin-bottom: 10px;
  }
`;

const Cnt = styled.div`
  display: flex;
  color: #424242;
  font-size: 14px;
`;

interface SelectButtonProps {
  activeId: number;
  data: {
    name: string;
    cnt: number;
    idx: number;
    id: number;
    gid: number;
    text: string;
  }[];
  onClick?: (idx: number, id: number) => void;
  test?: number[];
}

const SelectButton = ({ activeId, data, onClick, test }: SelectButtonProps) => {
  // state
  const [tooltip, setTooltip] = useState(false);
  const [segmentId, setSegmentId] = useState(-1);
  const [allSegId, setAllSegId] = useState(-1);

  const [firstGroupData, setFirstGroupData] = useState<
    SelectButtonProps['data']
  >([]);
  const [secGroupData, setSecGroupData] = useState<SelectButtonProps['data']>(
    []
  );

  const onEnter = (type: number, id: number) => {
    setTooltip(true);

    switch (type) {
      case 1:
        setAllSegId(id);
        break;
      case 2:
        setSegmentId(id);
        break;
      default:
        break;
    }
  };
  const onLeave = (type: number) => {
    setTooltip(false);
    switch (type) {
      case 1:
        setAllSegId(-1);
        break;
      case 2:
        setSegmentId(-1);
        break;
      default:
        break;
    }
  };

  const onClickBtn = (idx: number, id: number) => {
    if (onClick) {
      onClick(idx, id);
    }
  };

  // refac
  useEffect(() => {
    setFirstGroupData(data.filter((name) => name.gid === 1));
    setSecGroupData(data.filter((name) => name.gid === 2));
  }, [data, test]);

  return (
    <ColumnGroup $allGroup>
      <ButtonGroup>
        {secGroupData.map((element, idx) => {
          return (
            <SelectBtn
              $activeId={activeId}
              $prevNo={firstGroupData.length}
              $test={test}
              key={element.idx}
              onClick={() => {
                onClickBtn(element.idx, element.id);
              }}
            >
              {element.name}
              <Cnt>
                {element.cnt}명
                <Inform
                  onMouseEnter={() => {
                    onEnter(element.gid, idx);
                  }}
                  onMouseLeave={() => {
                    onLeave(element.gid);
                  }}
                >
                  <IconInform />
                  {segmentId === idx ? (
                    <InformTooltip isShow={tooltip} text={element.text} />
                  ) : null}
                </Inform>
              </Cnt>
            </SelectBtn>
          );
        })}
      </ButtonGroup>
      <Split />
      <ButtonGroup>
        {firstGroupData.map((element, idx) => {
          return (
            <SelectBtn
              $activeId={activeId}
              key={element.idx}
              onClick={() => {
                onClickBtn(element.idx, element.id);
              }}
            >
              {element.name}
              <Cnt>
                {element.cnt}명
                <Inform
                  onMouseEnter={() => {
                    onEnter(element.gid, idx);
                  }}
                  onMouseLeave={() => {
                    onLeave(element.gid);
                  }}
                >
                  <IconInform />
                  {allSegId === idx ? (
                    <InformTooltip isShow={tooltip} text={element.text} />
                  ) : null}
                </Inform>
              </Cnt>
            </SelectBtn>
          );
        })}
      </ButtonGroup>
    </ColumnGroup>
  );
};

export default SelectButton;
