import React, { useEffect, useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../modules';
import DefaultModal from '../../components/UIComponents/Modal/DefaultModal';
import { AxiosErrorType } from '../../modules/createRequestSaga';
import { setLogoutAsync } from '../../modules/auth';
import { setErrorNullMypage } from '../../modules/myPage';

const ErrorComponent = () => {
  const ErrorMessage = {
    '4010000': '로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.',
    '4010001': '다른기기에서 로그인 하였습니다.\n다시 로그인 해주세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  };

  const {
    getCheckPasswordError,
    getCheckPasswordErrorMessage,
    setEditUserError,
    setEditUserErrorMessage,
    setEditPasswordError,
    setEditPasswordMessage,
  } = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const onSetLogout = () => {
    dispatch(setLogoutAsync.request());
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isNetworkError, setIsNetworkError] = useState(false);
  // let isNetworkError = false;
  const onClickModalConfirmButton = useCallback(() => {
    setIsModalOpen(false);
    if (isNetworkError) {
      onSetLogout();
    }
  }, [isNetworkError]);

  function isResponse(args: AxiosErrorType): args is AxiosError['response'] {
    return args.data !== undefined;
  }
  function isRequest(args: AxiosErrorType): args is AxiosError['request'] {
    return args.responseXML !== undefined;
  }

  const ErrorCodeSetMessage = (
    error: any,
    errorCodeMessage: {
      [key: string]: string;
    }
  ) => {
    try {
      let message = '';
      if (error) {
        if (isResponse(error) && error) {
          const key = error.data.code as string;
          if (Object.prototype.hasOwnProperty.call(errorCodeMessage, key)) {
            // isNetworkError = false;
            setIsNetworkError(false);
            message = errorCodeMessage[key];
          } else {
            setIsNetworkError(false);
            message = errorCodeMessage.default;
          }
        } else if (isRequest(error)) {
          setIsNetworkError(true);
          message = errorCodeMessage.network;
        } else {
          setIsNetworkError(false);
          message = errorCodeMessage.default;
        }

        setErrorMessage(message);
        setIsModalOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const SetMessage = (error: any) => {
    try {
      let message = '';
      if (error) {
        if (isResponse(error) && error) {
          const key = error.data.code as string;
          if (key === '4010001') {
            setIsNetworkError(true);
            message = ErrorMessage['4010001'];
          } else if (key === '4010000') {
            setIsNetworkError(true);
            message = ErrorMessage['4010000'];
          } else {
            setIsNetworkError(false);
            message = ErrorMessage.default;
          }
        } else if (isRequest(error)) {
          setIsNetworkError(true);
          message = ErrorMessage.network;
        } else {
          setIsNetworkError(false);
          message = ErrorMessage.default;
        }
        setErrorMessage(message);
        setIsModalOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  // account
  useEffect(() => {
    ErrorCodeSetMessage(getCheckPasswordError, getCheckPasswordErrorMessage);
  }, [getCheckPasswordError]);

  useEffect(() => {
    ErrorCodeSetMessage(setEditUserError, setEditUserErrorMessage);
  }, [setEditUserError]);

  useEffect(() => {
    ErrorCodeSetMessage(setEditPasswordError, setEditPasswordMessage);
  }, [setEditPasswordError]);

  useEffect(() => {
    return () => {
      dispatch(setErrorNullMypage());
    };
  }, []);

  return (
    <DefaultModal
      isPublic={false}
      isOpen={isModalOpen}
      message={errorMessage}
      handleClose={onClickModalConfirmButton}
    />
  );
};

export default ErrorComponent;
