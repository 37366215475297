import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';

import {
  SetManageRecommendCreateParamType,
  SetManageRecommendCreateResponseType,
  SetManageRecommendStateParamType,
  SetManageRecommendStateResponseType,
  GetManageSegmentListParamType,
  GetManageSegmentListResponseType,
  GetManagePageListParamType,
  GetManagePageListResponseType,
  GetManageAlgorithmListParamType,
  GetManageAlgorithmListResponseType,
} from '../../api/register/type';

// 개인화 추천 생성
export const SET_MANAGE_RECOMMEND_CREATE =
  'management/SET_MANAGE_RECOMMEND_CREATE' as const;
export const SET_MANAGE_RECOMMEND_CREATE_SUCCESS =
  'management/SET_MANAGE_RECOMMEND_CREATE_SUCCESS' as const;
export const SET_MANAGE_RECOMMEND_CREATE_ERROR =
  'management/SET_MANAGE_RECOMMEND_CREATE_ERROR' as const;

export const setRecommendCreateAsync = createAsyncAction(
  SET_MANAGE_RECOMMEND_CREATE,
  SET_MANAGE_RECOMMEND_CREATE_SUCCESS,
  SET_MANAGE_RECOMMEND_CREATE_ERROR
)<
  SetManageRecommendCreateParamType,
  SetManageRecommendCreateResponseType,
  AxiosErrorType
>();

// 개인화 추천 상태 변경
export const SET_MANAGE_RECOMMEND_STATE =
  'management/SET_MANAGE_RECOMMEND_STATE' as const;
export const SET_MANAGE_RECOMMEND_STATE_SUCCESS =
  'management/SET_MANAGE_RECOMMEND_STATE_SUCCESS' as const;
export const SET_MANAGE_RECOMMEND_STATE_ERROR =
  'management/SET_MANAGE_RECOMMEND_STATE_ERROR' as const;

export const setRecommendStateAsync = createAsyncAction(
  SET_MANAGE_RECOMMEND_STATE,
  SET_MANAGE_RECOMMEND_STATE_SUCCESS,
  SET_MANAGE_RECOMMEND_STATE_ERROR
)<
  SetManageRecommendStateParamType,
  SetManageRecommendStateResponseType,
  AxiosErrorType
>();

// 세그먼트 리스트
export const GET_MANAGE_SEGMENT = 'management/GET_MANAGE_SEGMENT' as const;
export const GET_MANAGE_SEGMENT_SUCCESS =
  'management/GET_MANAGE_SEGMENT_SUCCESS' as const;
export const GET_MANAGE_SEGMENT_ERROR =
  'management/GET_MANAGE_SEGMENT_ERROR' as const;

export const getSegmentListAsync = createAsyncAction(
  GET_MANAGE_SEGMENT,
  GET_MANAGE_SEGMENT_SUCCESS,
  GET_MANAGE_SEGMENT_ERROR
)<
  GetManageSegmentListParamType,
  GetManageSegmentListResponseType,
  AxiosErrorType
>();

// 페이지 리스트
export const GET_MANAGE_PAGE = 'management/GET_MANAGE_PAGE' as const;
export const GET_MANAGE_PAGE_SUCCESS =
  'management/GET_MANAGE_PAGE_SUCCESS' as const;
export const GET_MANAGE_PAGE_ERROR =
  'management/GET_MANAGE_PAGE_ERROR' as const;

export const getPageListAsync = createAsyncAction(
  GET_MANAGE_PAGE,
  GET_MANAGE_PAGE_SUCCESS,
  GET_MANAGE_PAGE_ERROR
)<GetManagePageListParamType, GetManagePageListResponseType, AxiosErrorType>();

// 알고리즘 리스트
export const GET_MANAGE_ALGORITHM = 'management/GET_MANAGE_ALGORITHM' as const;
export const GET_MANAGE_ALGORITHM_SUCCESS =
  'management/GET_MANAGE_ALGORITHM_SUCCESS' as const;
export const GET_MANAGE_ALGORITHM_ERROR =
  'management/GET_MANAGE_ALGORITHM_ERROR' as const;

export const getAlgorithmListAsync = createAsyncAction(
  GET_MANAGE_ALGORITHM,
  GET_MANAGE_ALGORITHM_SUCCESS,
  GET_MANAGE_ALGORITHM_ERROR
)<
  GetManageAlgorithmListParamType,
  GetManageAlgorithmListResponseType,
  AxiosErrorType
>();

export const SET_ERROR_NULL_REGISTER = 'management/SET_ERROR_NULL_REGISTER';
export const setErrorNullRegister = createAction(SET_ERROR_NULL_REGISTER)();
