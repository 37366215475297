import axios from 'axios';
import config from '../config-api.json';
import {
  SetLoginParamType,
  SetLoginResponseType,
  SetLogoutResponseType,
  GetCheckSessionResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
  localStorage.setItem('vq_tenant', 'YsxHWfQ63fF4uXIN');
}

// 로그인 로그아웃 쿠키 관리
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

// login
export async function apiSetLogin(info: SetLoginParamType) {
  const response = await axios.post<SetLoginResponseType>(
    `${apiInfo.api_url}/login/login/`,
    {
      id: info.id,
      pw: info.pw,
    },
    {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// logout
export async function apiSetLogout() {
  const response = await axios.post<SetLogoutResponseType>(
    `${apiInfo.api_url}/login/logout/`,
    {},
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// session
export async function apiGetCheckSession() {
  const response = await axios.get<GetCheckSessionResponseType>(
    `${apiInfo.api_url}/login/check_session/`,
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
