import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Atoms
import {
  IconDashboard,
  IconCuration,
  IconServiceAnalysis,
  IconCurationStatus,
} from '../../Atoms/Icon';
import { LogoVORYQ } from '../../Atoms/Logo';

import DefaultLink from '../../Atoms/Link';

// Molecule
import NavFooterLink from '../../Molecule/NavFooterLink';
import DefaultNavLink from '../../Atoms/NavLink';
import Modal from '../../Molecule/Modal';
import Timer from '../../Molecule/Timer';

const Component = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 100vh;
  z-index: 950;
  border-right: 1px solid ${(props) => props.theme.colors.grey200};
  background-color: ${(props) => props.theme.colors.white};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 109px;
  padding-left: 20px;
  margin-bottom: 40px;
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 4px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  .voda_profile_link {
    margin-top: 8px;
  }
  .voryq_nav_footer_link {
    margin: 20px 0px 10px 0px;
    margin-right: -3px;
  }
`;

const FoldButtonWrapper = styled.div`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.purple4};
`;

const SingleMenuButton = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 28px;
  border-radius: 20px;
  margin-bottom: 10px;

  a {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 30px;
    height: 100%;
    width: 100%;
    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }
  :hover {
    svg {
      g {
        path {
          fill: ${(props) => props.theme.colors.blue1};
          stroke: ${(props) => props.theme.colors.blue1};
        }
        rect {
          stroke: ${(props) => props.theme.colors.blue1};
        }
      }
    }
  }
`;

const VodaWrap = styled.img`
  display: flex;
  width: 180px;
  height: 40px;
  margin: 0 auto;
`;

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

const deleteCookie = (
  sKey: string,
  sDomain: string | null,
  sPath: string | null
) => {
  if (getCookie(sKey)) {
    document.cookie = `${sKey}=${sPath ? `;path=${sPath}` : ''}${
      sDomain ? `;domain=${sDomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

interface NavigationProps extends RouteComponentProps {
  lisenceInfo?: {
    pv?: number;
    day?: number;
  };
  userName?: string;
  onSetLogout: () => void;
  onGetCheckSession: () => void;
}

const Navigation = ({
  location,
  lisenceInfo,
  userName,
  onSetLogout,
  onGetCheckSession,
}: NavigationProps) => {
  let interval: ReturnType<typeof setTimeout> | null = null;
  const sessionInitTime = 3600;
  let sessionSecond = sessionInitTime;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [modalMessage, setModalMessage] =
    useState('정상적으로 로그아웃되었습니다.');
  const [timer, setTimer] = useState('59:59');

  const onClickLogout = () => {
    // onSetLogout();
    setIsLogoutModal(true);
  };

  const TimerInterval = () => {
    const pad = (n: number, width: number) => {
      const numberToStr = `${n}`;
      return numberToStr.length >= width
        ? numberToStr
        : new Array(width - numberToStr.length + 1).join('0') + numberToStr;
    };

    interval = setInterval(() => {
      if (sessionSecond >= 0) {
        const voryq_session_valid_time = sessionStorage.getItem(
          'voryq_session_valid_time'
        );
        if (voryq_session_valid_time && voryq_session_valid_time !== null) {
          const currentTime = new Date();
          const validTime = new Date(voryq_session_valid_time);

          const elapsedMsec = Math.floor(
            (validTime.getTime() - currentTime.getTime()) / 1000
          );

          const min = Math.floor(elapsedMsec / 60);
          const sec = elapsedMsec % 60;

          const minStr = pad(min, 2);
          const secStr = pad(sec, 2);
          setTimer(`${minStr}:${secStr}`);
          sessionSecond = elapsedMsec;

          if (elapsedMsec <= 1) {
            setModalMessage(
              '로그인 유지 시간이 만료되었습니다.\n다시 로그인 해주세요.'
            );

            setIsModalOpen(true);

            if (interval) {
              clearInterval(interval);
            }
            localStorage.removeItem('vq_tenant');
            deleteCookie('sessionid', '.nerdfactory.ai', '/');
            deleteCookie('csrftoken', '.nerdfactory.ai', '/');
            onGetCheckSession();
          }
        }
      }
    }, 1000);
  };

  const ModalClose = () => {
    setIsModalOpen(false);
    setIsLogoutModal(false);
  };
  const ModalLogout = () => {
    setIsModalOpen(false);
    setIsLogoutModal(false);
    onSetLogout();
  };

  useEffect(() => {
    const expire = new Date();
    expire.setMinutes(expire.getMinutes() + 60);
    sessionStorage.setItem('voryq_session_valid_time', expire.toUTCString());
    axios.interceptors.response.use(
      (response) => {
        sessionSecond = sessionInitTime;
        const expireNew = new Date();
        expireNew.setMinutes(expireNew.getMinutes() + 60);
        sessionStorage.setItem(
          'voryq_session_valid_time',
          expireNew.toUTCString()
        );
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    TimerInterval();
    return () => {
      if (interval !== null) {
        clearInterval(interval);
        sessionSecond = sessionInitTime;
      }
      sessionStorage.setItem('voryq_session_valid_time', '');
      setIsModalOpen(false);
    };
  }, []);

  return (
    <Component>
      <Modal
        isPublic={false}
        isOpen={isModalOpen}
        isDefault
        message={modalMessage}
        handleClose={ModalClose}
        handleConfirm={ModalLogout}
      />
      <Modal
        isPublic={false}
        isOpen={isLogoutModal}
        message="로그아웃 하시겠습니까?"
        closeBtnName="취소"
        confirmBtnName="확인"
        handleClose={ModalClose}
        handleConfirm={ModalLogout}
      />
      <LogoWrapper>
        <DefaultLink className="voryq-logo" to="/">
          <LogoVORYQ />
        </DefaultLink>
      </LogoWrapper>
      <MainMenuWrapper>
        <SingleMenuButton>
          <DefaultNavLink key="/" to="/" title="대시보드">
            <IconDashboard
              className={
                location.pathname.split('/')[1] === '' ? 'focus' : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton>

        <SingleMenuButton>
          <DefaultNavLink
            key="/curation"
            to="/curation"
            title="개인화 추천 관리"
            className={location.pathname.split('/')[1] === 'curation'}
          >
            <IconCuration
              className={
                location.pathname.split('/')[1] === 'curation'
                  ? 'focus'
                  : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton>

        <SingleMenuButton>
          <DefaultNavLink
            key="/statistics"
            to="/statistics"
            title="추천 상품 통계"
          >
            <IconServiceAnalysis
              className={
                location.pathname.split('/')[1] === 'statistics'
                  ? 'focus'
                  : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton>

        <SingleMenuButton>
          <DefaultNavLink
            key="/curation_status"
            to="/curation_status"
            title="고객군별 추천 상품"
          >
            <IconCurationStatus
              className={
                location.pathname.split('/')[1] === 'curation_status'
                  ? 'focus'
                  : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton>

        {/* <SingleMenuButton>
          <DefaultNavLink
            key="/serviceanalysis"
            to="/serviceanalysis"
            title="서비스 이용 분석"
          >
            <IconServiceAnalysis
              className={
                location.pathname.split('/')[1] === 'serviceanalysis'
                  ? 'focus'
                  : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton> */}
      </MainMenuWrapper>
      <FooterWrapper>
        {/* <ProfileLink userName={userName} /> */}
        <NavFooterLink onClickLogout={onClickLogout} />
        <Timer time={timer} />
      </FooterWrapper>
      <Split />
      <FoldButtonWrapper>{/* <NavFoldButton /> */}</FoldButtonWrapper>
    </Component>
  );
};

export default withRouter(Navigation);
