import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { P3, P4 } from '../../Atoms/Typo';
import Spinner from '../../Molecule/Spinner';
import { GetItemAnalysisResponseType } from '../../../api/itemAnalysis/type';

const HeadWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${P3} {
    display: flex;
    font-weight: bold;
    height: 60px;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colors.grey300};
    border-right: none;
    padding: 0 10px;
    :nth-child(1) {
      width: 5%;
      border-radius: 5px 0 0 0;
    }
    :nth-child(2) {
      width: 50%;
      justify-content: flex-start;
    }
    :nth-child(3) {
      width: 15%;
    }
    :nth-child(4) {
      width: 15%;
    }
    :nth-child(5) {
      width: 15%;
      border-right: solid 1px ${(props) => props.theme.colors.grey300};
      border-radius: 0 5px 0 0;
    }
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  border-right: none;
  border-top: none;
  padding: 0 10px;
  :nth-child(1) {
    width: 5%;
  }
  :nth-child(2) {
    width: 50%;
    justify-content: flex-start;
  }
  :nth-child(3) {
    width: 15%;
  }
  :nth-child(4) {
    width: 15%;
  }
  :nth-child(5) {
    width: 15%;
    border-right: solid 1px ${(props) => props.theme.colors.grey300};
  }
  :nth-child(n + 3) {
    ${P3} {
      width: 65px;
      text-align: end;
    }
  }
`;

interface StatisticsDataCardProps {
  isloading?: boolean;
  data: GetItemAnalysisResponseType['payload'] | null;
}

const StatisticsDataCard = ({ isloading, data }: StatisticsDataCardProps) => {
  // state
  const [listData, setListData] = useState<
    GetItemAnalysisResponseType['payload']
  >([]);

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);
  return (
    <CardLayout>
      {isloading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <HeadWrap>
            <P3>No.</P3>
            <P3>상품 이름</P3>
            <P3>상품 노출수</P3>
            <P3>상품 클릭수</P3>
            <P3>상품 구매수</P3>
          </HeadWrap>
          {listData.map((element, idx) => {
            return (
              <RowWrap key={element.name + idx}>
                <Cell>{idx + 1}.</Cell>
                <Cell>{element.name}</Cell>
                <Cell>
                  <P3>{element.exposure.toLocaleString()}</P3>
                </Cell>
                <Cell>
                  <P3>{element.click.toLocaleString()}</P3>
                </Cell>
                <Cell>
                  <P3>{element.purchase.toLocaleString()}</P3>
                </Cell>
              </RowWrap>
            );
          })}
        </CardBodyLayout>
      )}
    </CardLayout>
  );
};

export default StatisticsDataCard;
