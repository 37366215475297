import axios from 'axios';
import config from '../config-api.json';

import {
  GetItemAnalysisParamType,
  GetItemAnalysisResponseType,
  GetAnalysisAlgorithmListParamType,
  GetAnalysisAlgorithmResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 상품 통계 분석 리스트
export async function apiGetItemAnalysis(info: GetItemAnalysisParamType) {
  const response = await axios.get<GetItemAnalysisResponseType>(
    `${apiInfo.api_url}/item_analysis/item_analysis/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        algorithm: info.algorithm,
        sort_field: info.sort_field,
        order: info.order,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetAnalysisAlgorithmList(
  info: GetAnalysisAlgorithmListParamType
) {
  const response = await axios.get<GetAnalysisAlgorithmResponseType>(
    `${apiInfo.api_url}/item_analysis/algorithm_list/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
