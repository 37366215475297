import { takeLatest } from 'redux-saga/effects';
import {
  apiSetCheckPasswordInfo,
  apiSetEditUserInfo,
  apiSetEditPasswordInfo,
} from '../../api/account/api';
import {
  SET_CHECK_PASSWORD,
  SET_EDIT_PASSWORD,
  SET_EDIT_USER_INFO,
  setCheckPasswordAsync,
  setEditUserInfoAsync,
  setEditPasswordAsync,
} from './actions';
import createRequestSaga from '../createRequestSaga';

const setCheckPasswordSaga = createRequestSaga(
  setCheckPasswordAsync,
  apiSetCheckPasswordInfo
);
const setEditUserInfoSaga = createRequestSaga(
  setEditUserInfoAsync,
  apiSetEditUserInfo
);
const setEditPasswordSaga = createRequestSaga(
  setEditPasswordAsync,
  apiSetEditPasswordInfo
);

export function* accountSaga() {
  yield takeLatest(SET_CHECK_PASSWORD, setCheckPasswordSaga);
  yield takeLatest(SET_EDIT_USER_INFO, setEditUserInfoSaga);
  yield takeLatest(SET_EDIT_PASSWORD, setEditPasswordSaga);
}

export { accountSaga as default };
