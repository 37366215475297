import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../modules';
import {
  getForecastDateAsync,
  setEndDate,
  setIsDatePickerToggle,
  setStartDate,
} from '../../../modules/datePicker';

import DefaultDatePicker from '../../Molecule/DatePicker';
import ContentsHeading from '../../Molecule/Heading/Contents/ContentsHeading';
import { Button } from '../../Atoms/Button';
import { IconNewPlus } from '../../Atoms/Icon';
import DefaultLink from '../../Atoms/Link';

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 40px 0px 16px 0px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
`;

const RightSide = styled.div`
  display: flex;
`;

const NewButton = styled(Button)`
  display: flex;
  width: 120px;
  height: 36px;
  border-radius: 18px;
  background-color: #008ded;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  padding-left: 10px;
`;

interface HeaderProps {
  title: string;
  isBreadCrumbs?: boolean;
  isDatePicker?: boolean;
  isButton?: boolean;
  btnTitle?: string;
  className?: string;
  onClickLink?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  children?: React.ReactNode;
}

const Header = ({
  title,
  isBreadCrumbs,
  className,
  isDatePicker,
  isButton,
  btnTitle,
  children,
  onClickLink,
}: HeaderProps) => {
  // redux
  const { startDate, endDate, futureDays } = useSelector(
    (state: RootState) => state.datePicker
  );
  const { created_time } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const onSetStartDate = (date: any) => {
    dispatch(setStartDate(date));
  };

  const onSetEndDate = (date: any) => {
    dispatch(setEndDate(date));
  };

  const onSetIsDatePickerToggle = (isToggle: boolean) => {
    dispatch(setIsDatePickerToggle(isToggle));
  };

  // const onGetForecastDate = () => {
  //   dispatch(getForecastDateAsync.request());
  // };

  // useEffect(() => {
  //   onGetForecastDate();
  // }, []);

  return (
    <Component className={className}>
      <ContentsHeading title={title} />

      <RightSide>
        {children}
        {isDatePicker ? (
          <DefaultDatePicker
            startDate={startDate}
            endDate={endDate}
            createdTime={created_time}
            futureDateDays={futureDays}
            setStartDate={onSetStartDate}
            setEndDate={onSetEndDate}
            setIsDatePickerToggle={onSetIsDatePickerToggle}
          />
        ) : null}
        {isButton ? (
          <DefaultLink to="/curation/newRecommend" onClick={onClickLink}>
            <NewButton>
              <IconNewPlus />
              {btnTitle}
            </NewButton>
          </DefaultLink>
        ) : null}
      </RightSide>
    </Component>
  );
};

export default Header;
