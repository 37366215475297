import React from 'react';
import styled from 'styled-components';
import { IconDisableInform } from '../../Atoms/Icon';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey200};
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  ${P3} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    margin-left: 5px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  ${P3} {
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;

const InformBody = styled.div``;

const RowWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0px;
  }
  ${P3} {
    word-break: keep-all;
  }
`;

const ListNumber = styled.div`
  min-width: 24px;
`;

const Example = styled.span`
  color: ${(props) => props.theme.colors.grey800};
`;

const DisableInform = () => {
  return (
    <Component>
      <Title>
        <IconDisableInform />
        <P3>왜 알고리즘이 비활성화 되었나요?</P3>
      </Title>
      <InformBody>
        <RowWrap>
          <ListNumber>
            <P3>1.</P3>
          </ListNumber>
          <P3>
            동일한 페이지와 알고리즘으로 만들어진 추천이 대기중 이거나 진행중인
            경우
          </P3>
        </RowWrap>
        <RowWrap>
          <ListNumber>
            <P3>2.</P3>
          </ListNumber>
          <P3>
            1, 2단계에서 선택한 고객군 또는 노출 페이지에 적합하지 않은
            알고리즘일 경우 <br />
            <Example>
              {'예) 함께 본 상품은 특정 상품의 상세페이지에서 적용 가능합니다.'}
            </Example>
          </P3>
        </RowWrap>
      </InformBody>
    </Component>
  );
};

export default DisableInform;
