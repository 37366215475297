import { createReducer } from 'typesafe-actions';
import {
  GET_DASHBOARD_ACCUMULATE,
  GET_DASHBOARD_ACCUMULATE_ERROR,
  GET_DASHBOARD_ACCUMULATE_SUCCESS,
  GET_DASHBOARD_PROGRESS,
  GET_DASHBOARD_PROGRESS_ERROR,
  GET_DASHBOARD_PROGRESS_SUCCESS,
  GET_DASHBOARD_ENDED,
  GET_DASHBOARD_ENDED_ERROR,
  GET_DASHBOARD_ENDED_SUCCESS,
  GET_SEGMENT_STATUS,
  GET_SEGMENT_STATUS_SUCCESS,
  GET_SEGMENT_STATUS_ERROR,
  GET_USAGE_SEGMENT,
  GET_USAGE_SEGMENT_SUCCESS,
  GET_USAGE_SEGMENT_ERROR,
  GET_RECOMMEND_TOTAL_GRAPH,
  GET_RECOMMEND_TOTAL_GRAPH_SUCCESS,
  GET_RECOMMEND_TOTAL_GRAPH_ERROR,
  SET_ERROR_NULL_DASHBOARD,
} from './actions';
import { dashboardAction, dashboardState } from './types';

const initialState: dashboardState = {
  isAccumulateLoading: false,
  isProgressLoading: false,
  isEndedLoading: false,
  isSegmentStatusLoading: false,
  isUsageSegmentLoading: false,
  isGraphLoading: false,

  accumData: null,
  ProgressData: null,
  endedData: null,
  segmentStatusData: null,
  usageSegmentData: null,
  totalGraphData: null,

  dashboardError: null,
};

const reducer = createReducer<dashboardState, dashboardAction>(initialState, {
  //
  [GET_DASHBOARD_ACCUMULATE]: (state) => ({
    ...state,
    isAccumulateLoading: true,
    accumData: null,
    dashboardError: null,
  }),
  [GET_DASHBOARD_ACCUMULATE_SUCCESS]: (state, action) => ({
    ...state,
    isAccumulateLoading: false,
    accumData: action.payload.payload,
  }),
  [GET_DASHBOARD_ACCUMULATE_ERROR]: (state, action) => ({
    ...state,
    isAccumulateLoading: false,
    accumData: null,
    dashboardError: action.payload,
  }),
  //
  [GET_DASHBOARD_PROGRESS]: (state) => ({
    ...state,
    isProgressLoading: true,
    ProgressData: null,
    dashboardError: null,
  }),
  [GET_DASHBOARD_PROGRESS_SUCCESS]: (state, action) => ({
    ...state,
    isProgressLoading: false,
    ProgressData: action.payload.payload,
  }),
  [GET_DASHBOARD_PROGRESS_ERROR]: (state, action) => ({
    ...state,
    isProgressLoading: false,
    ProgressData: null,
    dashboardError: action.payload,
  }),
  //
  [GET_DASHBOARD_ENDED]: (state) => ({
    ...state,
    isEndedLoading: true,
    endedData: null,
    dashboardError: null,
  }),
  [GET_DASHBOARD_ENDED_SUCCESS]: (state, action) => ({
    ...state,
    isEndedLoading: false,
    endedData: action.payload.payload,
  }),
  [GET_DASHBOARD_ENDED_ERROR]: (state, action) => ({
    ...state,
    isEndedLoading: false,
    endedData: null,
    dashboardError: action.payload,
  }),
  //
  [GET_SEGMENT_STATUS]: (state) => ({
    ...state,
    isSegmentStatusLoading: true,
    segmentStatusData: null,
    dashboardError: null,
  }),
  [GET_SEGMENT_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    isSegmentStatusLoading: false,
    segmentStatusData: action.payload.payload,
  }),
  [GET_SEGMENT_STATUS_ERROR]: (state, action) => ({
    ...state,
    isSegmentStatusLoading: false,
    segmentStatusData: null,
    dashboardError: action.payload,
  }),
  //
  [GET_USAGE_SEGMENT]: (state) => ({
    ...state,
    isUsageSegmentLoading: true,
    usageSegmentData: null,
    dashboardError: null,
  }),
  [GET_USAGE_SEGMENT_SUCCESS]: (state, action) => ({
    ...state,
    isUsageSegmentLoading: false,
    usageSegmentData: action.payload.payload,
  }),
  [GET_USAGE_SEGMENT_ERROR]: (state, action) => ({
    ...state,
    isUsageSegmentLoading: false,
    usageSegmentData: null,
    dashboardError: action.payload,
  }),
  //
  [GET_RECOMMEND_TOTAL_GRAPH]: (state) => ({
    ...state,
    isGraphLoading: true,
    totalGraphData: null,
    dashboardError: null,
  }),
  [GET_RECOMMEND_TOTAL_GRAPH_SUCCESS]: (state, action) => ({
    ...state,
    isGraphLoading: false,
    totalGraphData: action.payload.payload,
  }),
  [GET_RECOMMEND_TOTAL_GRAPH_ERROR]: (state, action) => ({
    ...state,
    isGraphLoading: false,
    totalGraphData: null,
    dashboardError: action.payload,
  }),

  //
  [SET_ERROR_NULL_DASHBOARD]: (state) => ({
    ...state,
    dashboardError: null,
  }),
});

export default reducer;
