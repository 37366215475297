import axios from 'axios';
import config from '../config-api.json';

import {
  SetManageRecommendCreateParamType,
  SetManageRecommendCreateResponseType,
  SetManageRecommendStateParamType,
  SetManageRecommendStateResponseType,
  GetManageSegmentListParamType,
  GetManageSegmentListResponseType,
  GetManagePageListParamType,
  GetManagePageListResponseType,
  GetManageAlgorithmListParamType,
  GetManageAlgorithmListResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 개인화 추천 생성 POST
export async function apiSetRecommendCreate(
  info: SetManageRecommendCreateParamType
) {
  const response = await axios.post<SetManageRecommendCreateResponseType>(
    `${apiInfo.api_url}/management/recommend_create/`,
    {
      segment: info.segment,
      page_type: info.page_type,
      algorithm: info.algorithm,
      name: info.name,
      sub_algorithm: info.sub_algorithm,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 개인화 추천 상태 변경 POST
export async function apiSetRecommendState(
  info: SetManageRecommendStateParamType
) {
  const response = await axios.post<SetManageRecommendStateResponseType>(
    `${apiInfo.api_url}/management/recommend_set_state/`,
    {
      key: info.key,
      state: info.state,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 세그먼트 리스트
export async function apiGetSegmentList(info: GetManageSegmentListParamType) {
  const response = await axios.get<GetManageSegmentListResponseType>(
    `${apiInfo.api_url}/management/segment_list/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 페이지 리스트
export async function apiGetPageList(info: GetManagePageListParamType) {
  const response = await axios.get<GetManagePageListResponseType>(
    `${apiInfo.api_url}/management/page_list/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 알고리즘 리스트
export async function apiGetAlgorithmList(
  info: GetManageAlgorithmListParamType
) {
  const response = await axios.get<GetManageAlgorithmListResponseType>(
    `${apiInfo.api_url}/management/algorithm_list/`,
    {
      params: {
        page_type: info.page_type,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
