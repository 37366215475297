import React from 'react';
import styled, { css } from 'styled-components';

const Layout = styled.div<{ $isShow: boolean }>`
  visibility: hidden;
  ${(props) => {
    if (props.$isShow) {
      return css`
        visibility: visible;
      `;
    }
    return css``;
  }}
`;

const Component = styled.div<{ $isShow: boolean }>`
  display: flex;
  position: absolute;
  visibility: hidden;
  width: 100%;
  max-width: 300px;

  padding: 12px 16px 12px 15px;
  box-sizing: border-box;
  text-align: left;
  background-color: ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blue400};
  border-radius: 5px;

  ${(props) => {
    if (props.$isShow) {
      return css`
        visibility: visible;
        transform: translate(25px, -15px);
      `;
    }
    return css``;
  }}
`;

const Angle = styled.div`
  display: flex;
  position: absolute;
  transform: translate(10px, -5px);
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid ${(props) => props.theme.colors.blue400};
`;
interface InformTooltipProps {
  isShow: boolean;
  text: string;
}
const InformTooltip = ({ text, isShow }: InformTooltipProps) => {
  return (
    <Layout $isShow={isShow}>
      <Angle />
      <Component $isShow={isShow}>{text}</Component>
    </Layout>
  );
};

export default InformTooltip;
