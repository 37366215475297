import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: white;
  padding: 24px 24px 40px 24px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grey200};

  transition: box-shadow 0.3s ease-out;
`;

interface CardLayoutProps {
  card_ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  children?: React.ReactNode;
}

const CardLayout = ({ card_ref, className, children }: CardLayoutProps) => {
  return (
    <Component ref={card_ref} className={className}>
      {children}
    </Component>
  );
};

export default CardLayout;
