import React from 'react';
import styled, { css } from 'styled-components';
import { IconFillArrowDown } from '../Icon';
import { P3 } from '../Typo';

const Th = styled.th`
  ${P3} {
    color: ${(props) => props.theme.colors.grey800};
    font-weight: bold;
  }

  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
  padding: 16px 10px;

  /* &:nth-child(1) {
    width: 50px;
  } */
`;
const Td = styled.td`
  &:nth-child(2) {
    max-width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ThItem = styled.div<{ $isSorting?: boolean; $isAsc?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${(props) => {
    if (props.$isSorting) {
      return css`
        cursor: pointer;
      `;
    }
    return css``;
  }}

  svg {
    width: 10px;
    height: 5px;
    margin-left: 8px;
    ${(props) => {
      if (props.$isAsc) {
        return css`
          transform: rotate(180deg);
        `;
      }
      return css``;
    }}
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 15px;
  white-space: nowrap;
`;

interface TableCellProps {
  isHeading: boolean;
  isAsc?: boolean;
  isSorting?: boolean;
  rowspan?: number;
  colspan?: number;
  className?: string;
  children?: React.ReactNode;
  onClickTh?: () => void;
}

const TableCell = ({
  className,
  isHeading,
  isAsc,
  isSorting,
  rowspan = 1,
  colspan = 1,
  children,
  onClickTh,
}: TableCellProps) => {
  return isHeading ? (
    <Th className={className} rowSpan={rowspan} colSpan={colspan}>
      <ThItem $isSorting={isSorting} $isAsc={isAsc} onClick={onClickTh}>
        {children}
        {isSorting ? <IconFillArrowDown className="disabled" /> : null}
      </ThItem>
      {/* {children} */}
    </Th>
  ) : (
    <Td className={className} rowSpan={rowspan} colSpan={colspan}>
      <Item>{children}</Item>
    </Td>
  );
};

export default TableCell;
