import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../modules';

import { GetManageRecommendListParamType } from '../../api/management/type';
import { SetManageRecommendStateParamType } from '../../api/register/type';
import { getRecommendListAsync } from '../../modules/management';
import { setRecommendStateAsync } from '../../modules/register';

// Components
import Header from '../../components/Organisms/Header';
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import { TableProps } from '../../components/Molecule/Table';
import { P2 } from '../../components/Atoms/Typo';

import CurationboardCard from '../../components/Organisms/Card/CurationboardCard';
import Modal from '../../components/Molecule/Modal';
import Spinner from '../../components/Molecule/Spinner';
import DefaultPagenation from '../../components/Molecule/Pagenation';
import SelectBox from '../../components/Atoms/SelectBox';
import DefaultLink from '../../components/Atoms/Link';

const NameWrapper = styled(DefaultLink)`
  padding-top: 0;
`;

const TableName = styled(P2)`
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 600px;
  min-width: 480px;

  /* @media ${(props) => props.theme.device.$desktop} {
    max-width: 450px;
  } */
`;
const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Curation = () => {
  const today = new Date();
  let year = today.getFullYear();
  let month = ('0' + (1 + today.getMonth())).slice(-2);
  let day = ('0' + today.getDate()).slice(-2);
  let todayDate = year + '-' + month + '-' + day;

  const dispatch = useDispatch();
  const {
    isListLoading,

    recomListData,
  } = useSelector((state: RootState) => state.management);
  const { setState } = useSelector((state: RootState) => state.register);

  const onGetRecommendList = (info: GetManageRecommendListParamType) => {
    dispatch(getRecommendListAsync.request(info));
  };
  const onSetRecommendState = (info: SetManageRecommendStateParamType) => {
    dispatch(setRecommendStateAsync.request(info));
  };

  // state
  const [tableData, setTableData] = useState<TableProps['data']>([]);
  const [stateValue, setStateValue] = useState(0);
  const [selectAk, setSelectAk] = useState('');
  // dropdown
  const [isOpen, setIsOpen] = useState(false);
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const [endModal, setEndModal] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  // set recommend name

  const statusFunc = (value: number) => {
    switch (value) {
      case 0:
        return [
          { value: 0, label: '대기중' },
          { value: 1, label: '진행중' },
        ];
      case 1:
        return [
          { value: 1, label: '진행중' },
          { value: 2, label: '종료' },
        ];

      default:
        return [{ value: 2, label: '종료' }];
    }
  };

  const onChangeState = (inputValue: any, ak: string) => {
    setStateValue(inputValue.value);
    switch (inputValue.value) {
      case 1:
        setSelectAk(ak);
        setModalOpen(true);
        break;
      case 2:
        setSelectAk(ak);
        setEndModal(true);
        break;

      default:
        break;
    }
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setIsOpen(false);
    setPageNumber(page);
  };

  const genTableData = () => {
    const data_temp: TableProps['data'] = [];
    if (recomListData && recomListData.length > 0) {
      for (
        let index = (pageNumber - 1) * 10;
        index <= pageNumber * 10 - 1;
        index += 1
      ) {
        if (index > recomListData.length - 1) {
          break;
        }
        const element = recomListData[index];
        data_temp.push({
          id: `${element.name}`,

          num: <P2>{index + 1}.</P2>,
          name: (
            <NameWrapper
              to={`/curation/detail?ak=${element.ak}&datetime=${todayDate}&title=${element.name}`}
            >
              <TableName>{element.name}</TableName>
            </NameWrapper>
          ),
          click_per_expose: <P2>{element.click_per_expose}%</P2>,
          reuse: <P2>{element.reuse}%</P2>,
          purchase_conversion: <P2>{element.purchase_conversion}%</P2>,
          datetime: <P2>{element.datetime}</P2>,
          state: (
            <SelectBox
              key={element.ak}
              option={statusFunc(element.state)}
              value={statusFunc(element.state)[0]}
              onChange={(inputValue) => {
                onChangeState(inputValue, element.ak);
              }}
              disabled={element.state === 2}
              state={element.state}
              type="state"
            />
          ),
          ak: `${element.ak}`,
          stateNum: element.state,
        });
      }
      setTableData(data_temp);
    } else {
      setTableData(data_temp);
    }
  };

  // State Modal
  const ModalClose = () => {
    setModalOpen(false);
    setEndModal(false);
  };
  const ModalConfirm = (ak: string, state: number) => {
    onSetRecommendState({
      key: ak,
      state: state,
    });
    setModalOpen(false);
    setEndModal(false);
  };

  useEffect(() => {
    onGetRecommendList({
      dt: todayDate,
    });
  }, []);

  useEffect(() => {
    if (setState) {
      onGetRecommendList({
        dt: todayDate,
      });
    }
  }, [setState]);

  // useEffect(() => {
  //   if (recomListData && recomListData.length > 0) {
  //     genTableData();
  //   }
  // }, [recomListData]);

  // pagination
  useEffect(() => {
    genTableData();
  }, [pageNumber]);

  useEffect(() => {
    if (recomListData) {
      setLastPageNumber(Math.ceil(recomListData.length / 10));
      genTableData();
    } else {
      genTableData();
    }
  }, [recomListData]);

  return (
    <ContentsLayout>
      <Modal
        isOpen={modalOpen || endModal}
        isPublic={true}
        message={
          modalOpen
            ? '추천을 진행할까요?\n상태 변경시 데이터를 확인할 수 있습니다.'
            : '추천을 종료할까요?\n상태 변경 즉시 추천이 노출되지 않습니다.'
        }
        closeBtnName="취소"
        confirmBtnName="확인"
        handleClose={ModalClose}
        handleConfirm={() => ModalConfirm(selectAk, stateValue)}
      />
      <Header title="개인화 추천 관리" isButton={true} btnTitle="새로운 추천" />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          {isListLoading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <CurationboardCard data={tableData} isOpen={isOpen} />
              {lastPageNumber ? (
                <PaginationWrapper>
                  <DefaultPagenation
                    count={lastPageNumber}
                    onChange={onChangePage}
                  />
                </PaginationWrapper>
              ) : null}
            </React.Fragment>
          )}
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Curation;
