import React from 'react';
import styled, { css } from 'styled-components';
import {
  IconCheck,
  IconError,
  IconModify,
  IconWarning,
  IconPasswordVisible,
} from '../Icon';

interface InputProps {
  status?:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'curation'
    | 'create'
    | 'password';
  size?: 'large' | 'middle' | 'small' | 'xsmall' | 'custom' | 'curation';
  type?: string;
  value?: string;
  placeholder?: string;
  id?: string;
  isDisabled?: boolean;
  errorMessage?: string;
  className?: string;
  name?: string;
  isBlock?: boolean;
  onChange?: (value: string) => void;
  onClickIcon?: () => void;
  onKeyPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const curation_size_css = css`
  font-size: 14px;
  width: 100%;
  height: 50px;

  input:only-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  input {
    height: 50px;
    padding-left: 16px;
    padding-right: 32px;
  }

  svg {
    width: 24px;
    right: 8px;
  }
`;

const xsmall_size_css = css`
  font-weight: 400;
  font-size: 10px;
  width: 200px;

  input:only-child {
    padding-left: 8px;
    padding-right: 8px;
  }

  input {
    height: 32px;
    padding-left: 8px;
    padding-right: 32px;
  }

  svg {
    width: 16px;
    right: 8px;
  }
`;

const small_size_css = css`
  font-weight: 400;
  font-size: 12px;
  width: 200px;

  input:only-child {
    padding-left: 8px;
    padding-right: 8px;
  }

  input {
    height: 34px;
    padding-left: 8px;
    padding-right: 32px;
  }

  svg {
    width: 16px;
    right: 8px;
  }
`;

const middle_size_css = css`
  font-weight: 400;
  font-size: 14px;
  width: 250px;

  input:only-child {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    height: 36px;
    padding-left: 16px;
    padding-right: 56px;
  }

  svg {
    width: 24px;
    right: 16px;
  }
`;

const large_size_css = css`
  font-weight: normal;
  font-size: 14px;
  width: 350px;

  input:only-child {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    height: 50px;
    padding-left: 16px;
    padding-right: 40px;
  }

  svg {
    width: 24px;
    right: 16px;
  }
`;

const custom_size_css = css`
  font-weight: 400;
  font-size: 16px;
  width: 350px;
  margin-bottom: 30px;

  input:only-child {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    height: 56px;
    padding-left: 16px;
    padding-right: 56px;
  }

  svg {
    width: 24px;
    right: 16px;
  }
`;

const icon_css = css`
  position: absolute;
  margin-right: 15px;
  top: 50%;
  transform: translate(0%, -50%);
  display: none;
`;

const default_css = css`
  input {
    color: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.grey300};
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
  }
`;

const curation_css = css`
  input {
    color: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.grey200};
    border-radius: 5px;

    &::placeholder {
      color: ${(props) => props.theme.colors.black};
      border-color: ${(props) => props.theme.colors.grey400};
    }

    &:hover {
      border: solid 1px ${(props) => props.theme.colors.blue200};
    }

    &:focus {
      outline: 0;
      border: solid 1px ${(props) => props.theme.colors.blue200};
      color: ${(props) => props.theme.colors.black};
    }
  }
  svg {
    ${icon_css};
  }
  &:hover svg {
    display: block;
  }
`;
const create_css = css`
  input {
    color: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.grey200};
    border-radius: 5px;

    &::placeholder {
      color: ${(props) => props.theme.colors.grey600};
      border-color: ${(props) => props.theme.colors.grey400};
    }

    &:hover {
      border: solid 1px ${(props) => props.theme.colors.blue200};
    }

    &:focus {
      outline: 0;
      border: solid 1px ${(props) => props.theme.colors.blue200};
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

const warning_css = css`
  input {
    color: ${(props) => props.theme.colors.grey700} !important;
    border: 2px solid ${(props) => props.theme.colors.warning5} !important;
  }

  svg {
    ${icon_css};
    path {
      &:last-child {
        fill: ${(props) => props.theme.colors.warning5};
      }
    }
  }
`;

const error_css = css`
  input {
    color: ${(props) => props.theme.colors.grey700} !important;
    border: 1px solid ${(props) => props.theme.colors.danger5} !important;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
  }
`;

const success_css = css`
  input {
    color: ${(props) => props.theme.colors.grey700} !important;
    border: 1px solid ${(props) => props.theme.colors.success5} !important;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
  }
`;

const password_css = css`
  input {
    color: ${(props) => props.theme.colors.grey700};
    border: 1px solid ${(props) => props.theme.colors.grey300};
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
  }
`;

const Component = styled.div<{
  $size: 'large' | 'middle' | 'small' | 'xsmall' | 'custom' | 'curation';
  $status:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'curation'
    | 'create'
    | 'password';
  $isBlock?: boolean;
}>`
  .input_wrapper {
    position: relative;
    width: 100%;

    font-family: inherit;
    font-size: inherit;
  }

  ${(props) => {
    switch (props.$size) {
      case 'curation':
        return css`
          ${curation_size_css};
        `;
      case 'xsmall':
        return css`
          ${xsmall_size_css};
        `;
      case 'small':
        return css`
          ${small_size_css};
        `;
      case 'middle':
        return css`
          ${middle_size_css};
        `;
      case 'large':
        return css`
          ${large_size_css};
        `;
      case 'custom':
        return css`
          ${custom_size_css};
        `;
      default:
        return css`
          ${middle_size_css};
        `;
    }
  }};

  ${(props) => {
    if (props.$isBlock) {
      return css`
        width: 100%;
      `;
    }
    return css``;
  }}

  input {
    width: 100%;
    font-family: inherit;
    border-radius: 8px;
    font-size: inherit;

    &:disabled {
      border: solid 1px ${(props) => props.theme.colors.grey200};
      background-color: ${(props) => props.theme.colors.grey200};
      color: ${(props) => props.theme.colors.black};
      &:hover {
        border: solid 1px ${(props) => props.theme.colors.grey200};
        background-color: ${(props) => props.theme.colors.grey200};
      }
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.black};
      border-color: ${(props) => props.theme.colors.grey400};
    }

    &:focus {
      outline: 0;
      border: solid 2px ${(props) => props.theme.colors.grey800};
      color: ${(props) => props.theme.colors.grey800};
    }
  }

  ${(props) => {
    switch (props.$status) {
      case 'default':
        return css`
          ${default_css};
        `;
      case 'curation':
        return css`
          ${curation_css}
        `;
      case 'create':
        return css`
          ${create_css}
        `;
      case 'warning':
        return css`
          ${warning_css};
        `;
      case 'error':
        return css`
          ${error_css};
        `;
      case 'success':
        return css`
          ${success_css};
        `;
      case 'password':
        return css`
          ${password_css};
        `;
      default:
        return css`
          ${default_css};
        `;
    }
  }};
`;

const StatusLabel = styled.div<{
  $size: 'large' | 'middle' | 'small' | 'xsmall' | 'custom' | 'curation';
  $status: 'default' | 'warning' | 'error' | 'success' | 'curation';
}>`
  font-weight: 400;
  margin-top: 8px;

  ${(props) => {
    switch (props.$size) {
      case 'curation':
        return css`
          font-size: 14px;
        `;
      case 'xsmall':
        return css`
          font-size: 8px;
        `;
      case 'small':
        return css`
          font-size: 10px;
        `;
      case 'middle':
        return css`
          font-size: 12px;
        `;
      case 'large':
        return css`
          font-size: 14px;
        `;
      case 'custom':
        return css`
          font-size: 14px;
        `;
      default:
        return css`
          font-size: 12px;
        `;
    }
  }};

  ${(props) => {
    switch (props.$status) {
      case 'default':
        return css``;
      case 'warning':
        return css`
          color: ${props.theme.colors.warning5};
        `;
      case 'error':
        return css`
          color: ${props.theme.colors.danger5};
        `;
      case 'success':
        return css`
          color: ${props.theme.colors.success5};
        `;
      default:
        return css``;
    }
  }};
`;

const Input = ({
  status = 'default',
  size = 'middle',
  type = 'text',
  value,
  placeholder,
  id,
  isDisabled = false,
  errorMessage = '',
  name = '',
  className,
  isBlock,
  onChange,
  onKeyPressEnter,
  onClickIcon,
}: InputProps) => {
  const printStatusLabel = () => {
    switch (status) {
      case 'default':
        return null;
      case 'warning':
        return (
          <StatusLabel $size={size} $status={status}>
            {errorMessage}
          </StatusLabel>
        );
      case 'error':
        return (
          <StatusLabel $size={size} $status={status}>
            {errorMessage}
          </StatusLabel>
        );
      // case 'success':
      //   return (
      //     <StatusLabel $size={size} $status={status}>
      //       {errorMessage}
      //     </StatusLabel>
      //   );
      default:
        return null;
    }
  };

  const printStatusIcon = () => {
    switch (status) {
      case 'default':
        return null;
      case 'curation':
        return <IconModify />;
      // case 'warning':
      //   return <IconWarning />;
      // case 'error':
      //   return <IconError />;
      // case 'success':
      //   return <IconCheck />;
      // case 'password':
      //   return <IconPasswordVisible onClick={onClickIcon} />;
      default:
        return <IconPasswordVisible onClick={onClickIcon} />;
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyPressEnter) {
      onKeyPressEnter(event);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Component $size={size} $status={status} $isBlock={isBlock}>
      <div className="input_wrapper">
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          autoComplete="no"
          onChange={onChangeInput}
          onKeyPress={onKeyPress}
        />
        {printStatusIcon()}
      </div>

      {printStatusLabel()}
    </Component>
  );
};

export default Input;
