import React from 'react';
import styled, { css } from 'styled-components';

const small_size_css = css`
  font-weight: 400;
  font-size: 12px;
  width: 88px;
  height: 32px;
`;

const middle_size_css = css`
  font-weight: 400;
  font-size: 14px;
  width: 96px;
  height: 36px;
`;

const large_size_css = css`
  font-weight: 400;
  font-size: 16px;
  width: 124px;
  height: 56px;
`;

const xsmall_size_css = css`
  border-radius: 5px;
  font-size: 14px;
  width: 74px;
  height: 43px;
`;

const long_size_css = css`
  border-radius: 5px;
  font-size: 14px;
  width: 128px;
  height: 43px;
`;

const Component = styled.button<{
  $isBlock: boolean;
  $isDisabled: boolean;
  $isPrev: boolean;
  $size: 'large' | 'middle' | 'small' | 'xsamll' | 'long';
}>`
  background-color: ${(props) => props.theme.colors.blue200};
  border: none;
  color: #ffffff;
  cursor: pointer;

  ${(props) => {
    if (props.$isDisabled) {
      return css`
        background-color: ${props.theme.colors.grey300};
        cursor: not-allowed;
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$isPrev) {
      return css`
        background-color: #ffffff;
        color: #3d4954;
        border: solid 1px #3d4954;
      `;
    }
    return css``;
  }}

  ${(props) => {
    switch (props.$size) {
      case 'small':
        return css`
          ${small_size_css};
        `;
      case 'middle':
        return css`
          ${middle_size_css};
        `;
      case 'large':
        return css`
          ${large_size_css};
        `;
      case 'xsamll':
        return css`
          ${xsmall_size_css};
        `;
      case 'long':
        return css`
          ${long_size_css};
        `;
      default:
        return css`
          ${middle_size_css};
        `;
    }
  }}

  ${(props) => {
    if (props.$isBlock) {
      return css`
        width: 100%;
        height: 100%;
      `;
    }
    return css``;
  }}
`;

interface ButtonProps {
  isBlock?: boolean;
  isDisabled?: boolean;
  isPrev?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  size?: 'large' | 'middle' | 'small' | 'xsamll' | 'long';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  className?: string;
}

export const Button = ({
  isBlock = false,
  isDisabled = false,
  isPrev = false,
  htmlType = 'button',
  size = 'middle',
  onClick,
  children,
  className,
}: ButtonProps) => {
  return (
    <Component
      className={`voryq_default_button ${className || ''}`}
      type={htmlType}
      onClick={onClick}
      $isBlock={isBlock}
      $isDisabled={isDisabled}
      $isPrev={isPrev}
      $size={size}
    >
      {children}
    </Component>
  );
};
export { Button as defaults };
