import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  margin-bottom: 5px;
  ${P3} {
    font-weight: bold;
  }
`;

interface CardSubHeaderProps {
  subTitle: string;
}

const CardSubHeader = ({ subTitle }: CardSubHeaderProps) => {
  return (
    <Component>
      <P3>{subTitle}</P3>
    </Component>
  );
};

export default CardSubHeader;
