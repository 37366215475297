import React from 'react';
import styled, { css } from 'styled-components';
import { P1 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const ButtonItem = styled.div<{ $activeId: number }>`
  position: relative;
  display: flex;
  width: fit-content;
  padding-top: 25px;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
  ${P1} {
    font-weight: bold;
    color: ${(props) => props.theme.colors.grey500};
  }
  ${(props) => {
    if (props.$activeId) {
      return css`
        &:nth-child(${props.$activeId}) {
          ::after {
            content: '';
            position: absolute;
            top: 0;
            height: 5px;
            width: 100%;
            border-top: 1px solid ${(props) => props.theme.colors.blue500};
            background-color: ${(props) => props.theme.colors.blue500};
          }
          ${P1} {
            font-weight: bold;
            color: ${(props) => props.theme.colors.black};
          }
        }
      `;
    }
    return css``;
  }}
`;

interface CardButtonGroupProps {
  buttonId: number;
  buttonList: string[];
  onClick: (id: number) => void;
}

const CardButtonGroup = ({
  buttonId,
  buttonList,
  onClick,
}: CardButtonGroupProps) => {
  return (
    <Component>
      {buttonList.map((element, idx) => {
        return (
          <ButtonItem
            key={element}
            $activeId={buttonId}
            onClick={() => onClick(idx + 1)}
          >
            <P1>{element}</P1>
          </ButtonItem>
        );
      })}
    </Component>
  );
};

export default CardButtonGroup;
