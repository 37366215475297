import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

// Private Public components
import PublicRoute from '../_PublicRoute';
import LoginPage from '../LoginPage';
import NotfoundPage from '../../components/NotfoundPage';

// Private components
import PrivateRoute from '../_PrivateRoute';

import Dashboard from '../Dashboard';
import Curation from '../Curation';

import Support from '../Support';
import Settings from '../Settings';
import NewRecommend from '../NewRecommend';
// not use
import MyPage from '../MyPage';
import CurationDetail from '../CurationDetail';
import Statistics from '../Statistics';
import CurationStatus from '../CurationStatus';

const env = process.env.NODE_ENV;
let isDevelopment = false;
if (env === 'development') {
  isDevelopment = true;
  // isDevelopment = false;
}
const Routes = () => {
  // redux
  const { isLogin } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {isLogin && localStorage.getItem('vq_tenant') ? (
        <Switch>
          <PrivateRoute exact path="/" title="대시보드" Component={Dashboard} />
          <PrivateRoute
            exact
            path="/curation"
            title="개인화 추천 관리"
            Component={Curation}
          />
          <PrivateRoute
            exact
            path="/curation/detail"
            title="추천 상세보기"
            Component={CurationDetail}
          />
          <PrivateRoute
            exact
            path="/curation/newRecommend"
            title="새로운 추천"
            Component={NewRecommend}
          />
          <PrivateRoute
            exact
            path="/statistics"
            title="추천 상품 통계"
            Component={Statistics}
          />
          <PrivateRoute
            exact
            path="/curation_status"
            title="고객군별 추천 상품"
            Component={CurationStatus}
          />
          {/* <PrivateRoute
          exact
          path="/serviceanalysis"
          title="서비스 이용 분석"
          Component={ServiceAnalysis}
        /> */}
          <PrivateRoute
            exact
            path="/settings"
            title="계정 설정"
            Component={Settings}
          />
          <PrivateRoute
            exact
            path="/support"
            title="도움말"
            Component={Support}
          />

          {/* <PrivateRoute exact path="*" title="대시보드" Component={Dashboard} /> */}

          <Route component={NotfoundPage} />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute exact path="*" component={LoginPage} />
          <Route component={NotfoundPage} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
