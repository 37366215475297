import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../modules';
import { SetCheckPasswordParamType } from '../../../api/account/type';
import { setCheckPasswordAsync } from '../../../modules/account';

import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import ConfirmPasswordCard from '../../../components/Organisms/Card/ConfirmPasswordCard';

interface ConfirmPasswordProps {
  checkPassword: (state: boolean) => void;
}

const ConfirmPassword = ({ checkPassword }: ConfirmPasswordProps) => {
  const { userId } = useSelector((state: RootState) => state.auth);
  const { isPasswordCheckLoading, passwordCheckState } = useSelector(
    (state: RootState) => state.account
  );
  const dispatch = useDispatch();

  const onSetCheckPasswordInfo = (info: SetCheckPasswordParamType) => {
    dispatch(setCheckPasswordAsync.request(info));
  };

  const onClickConfirm = (pw: string) => {
    onSetCheckPasswordInfo({
      pw: pw,
    });
  };

  useEffect(() => {
    checkPassword(passwordCheckState);
  }, [passwordCheckState]);

  return (
    <ContentBodyLayout>
      <ContentsItemLayout desktop={12}>
        <ConfirmPasswordCard
          loading={isPasswordCheckLoading}
          userID={userId}
          confirmPassword={onClickConfirm}
        />
      </ContentsItemLayout>
    </ContentBodyLayout>
  );
};

export default ConfirmPassword;
