import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import NoList from '../../../assets/images/img-nolist.png';

import Table from '../../Molecule/Table';
import { P1 } from '../../Atoms/Typo';

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  ${P1} {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
    margin-top: 13px;
  }
`;

const columns = [
  {
    title: 'No.',
    id: 'num',
    isSorting: false,
  },
  {
    title: '이름',
    id: 'name',
    isSorting: false,
  },
  {
    title: '노출대비 클릭률',
    id: 'click_per_expose',
    isSorting: false,
  },
  {
    title: '재 이용률',
    id: 'reuse',
    isSorting: false,
  },
  {
    title: '구매 전환률',
    id: 'purchase_conversion',
    isSorting: false,
  },
  {
    title: '생성일',
    id: 'datetime',
    isSorting: false,
  },
  {
    title: '상태',
    id: 'state',
    isSorting: false,
  },
];

export interface CurationboardCardProps {
  data: {
    id: string;
    [key: string]: React.ReactNode | { [key: string]: React.ReactNode };
    onClick?: (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
    ) => void;
    isHighlignt?: boolean;
    ak: string;
    stateNum: number;
  }[];
  isOpen: boolean;
}

const CurationboardCard = ({ data, isOpen }: CurationboardCardProps) => {
  // state

  // 모달 밖 영역 클릭시 닫힘, 리서치 더 필요
  // useEffect(() => {
  //   const current = ModalEl.current;
  //   const modalCloseHandler = (e: any) => {
  //     if (current === e.target) {
  //       onClickClose(false, saveCheck);
  //     }
  //   };
  //   if (current && isOpen) {
  //     window.addEventListener('click', modalCloseHandler);
  //   }
  // }, [ModalEl.current, isOpen, saveCheck]);

  return (
    <CardLayout>
      <CardBodyLayout>
        {data.length > 0 ? (
          <Table columns={columns} data={data} isOpen={isOpen} />
        ) : (
          <ImgWrapper>
            <img src={NoList} alt="" />
            <P1>생성된 개인화 추천이 없습니다.</P1>
            개인화 추천을 생성하여 추천 지표를 확인해보세요.
          </ImgWrapper>
        )}
      </CardBodyLayout>
    </CardLayout>
  );
};

export default CurationboardCard;
