import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { H3, P3 } from '../../Atoms/Typo';
import Input from '../../Atoms/Input';
import { Button } from '../../Atoms/Button';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)`
  padding: 20px 30px;
  height: 780px;
`;

const Header = styled.div`
  margin-bottom: 20px;
  ${H3} {
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
`;

const Message = styled.div`
  margin-bottom: 20px;
  ${P3} {
    font-weight: normal;
  }
`;

const InputForm = styled.div`
  margin-bottom: 20px;
  ${P3} {
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const ConfirmButton = styled(Button)`
  margin-top: 10px;
`;

interface ConfirmPasswordCardProps {
  loading: boolean;
  userID: string;
  confirmPassword: (pw: string) => void;
}

const ConfirmPasswordCard = ({
  loading,
  userID,
  confirmPassword,
}: ConfirmPasswordCardProps) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('password');

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onClickIcon = () => {
    setShowPassword((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    setInputType('password');
    if (showPassword) {
      setInputType('text');
    }
  }, [showPassword]);

  return (
    <Component>
      <Header>
        <H3>비밀번호 확인</H3>
      </Header>
      <CardBodyLayout>
        <Message>
          <P3>
            회원님의 소중한 개인정보 보호를 위해 비밀번호를 한번 더 확인하고
            있습니다.
          </P3>
          <P3>
            공공장소에서 PC를 사용중일 경우, 비밀번호가 타인에게 노출되지 않도록
            주의해 주시기 바랍니다.
          </P3>
        </Message>
        <InputForm>
          <P3>아이디</P3>
          <Input size="large" placeholder={userID} isDisabled />
        </InputForm>
        <InputForm>
          <P3>비밀번호</P3>
          <Input
            value={password}
            type={inputType}
            status="password"
            size="large"
            onChange={onChangePassword}
            onClickIcon={onClickIcon}
          />
        </InputForm>
        <ConfirmButton size="xsamll" onClick={() => confirmPassword(password)}>
          {loading ? <Spinner /> : '확인'}
        </ConfirmButton>
      </CardBodyLayout>
    </Component>
  );
};

export default ConfirmPasswordCard;
