import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DataError from '../../Atoms/DataError';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H7, P3 } from '../../Atoms/Typo';
import DounutChart from '../../Molecule/Chart/DonutChart';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)`
  height: 305px;
  padding: 24px 24px 10px 30px;
  padding-bottom: 10px;
`;

const Title = styled.div`
  ${H7} {
    color: ${(props) => props.theme.colors.black};
  }
`;

const Date = styled(P3)`
  color: ${(props) => props.theme.colors.grey600};
  font-weight: normal;
`;

interface DashboardDonutChartProps {
  isLoading?: boolean;
  segStatusData: {
    datetime: string;
    data: {
      name: string;
      count: number;
      rate: number;
    }[];
  } | null;
}

const DashboardDonutChart = ({
  isLoading,
  segStatusData,
}: DashboardDonutChartProps) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    if (segStatusData) {
      setDate(segStatusData.datetime + ' 12:00 기준');
    }
  }, [segStatusData]);

  return (
    <Component>
      {isLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <CardHeaderLayout>
            <Title>
              <H7>고객군 현황</H7>
            </Title>
            <Date>{date}</Date>
          </CardHeaderLayout>
          <CardBodyLayout>
            {segStatusData && segStatusData.data ? (
              <DounutChart data={segStatusData.data} />
            ) : (
              <DataError />
            )}
          </CardBodyLayout>
        </React.Fragment>
      )}
    </Component>
  );
};

export default DashboardDonutChart;
