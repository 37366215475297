import React, { useRef } from 'react';
import styled from 'styled-components';
import { IconCurationLeft, IconCurationRight } from '../../Atoms/Icon';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div`
  position: relative;
`;

const Skin = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 10px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Item = styled.div`
  min-width: 240px;
  height: 300px;
  border: 1px solid ${(props) => props.theme.colors.grey300};
  padding: 10px;
  padding-bottom: 20px;
  overflow-y: hidden;
  :nth-last-child(n + 2) {
    border-right: none;
  }
  img {
    width: 100%;
    height: 220px;
    object-fit: fill;
    margin-bottom: 10px;
  }
  ${P3} {
    overflow: hidden;
    height: 45px;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.colors.blue400};
    }
  }
`;

const PagingButton = styled.div`
  width: 100%;
  position: absolute;
  top: 40%;
  svg {
    opacity: 1;
    cursor: pointer;
    position: absolute;
  }
  .next-button {
    right: 0;
  }
`;

interface CurationItemListProps {
  itemList: {
    name: string;
    id: string;
    ca_id: string;
    img_url: string;
    url: string;
  }[];
}

const CurationItemList = ({ itemList }: CurationItemListProps) => {
  // state
  const listEl = useRef<HTMLDivElement>(null);

  const onClickPrev = () => {
    if (listEl.current) {
      listEl.current.scrollBy({ left: -1000, behavior: 'smooth' });
    }
  };

  const onClickNext = () => {
    if (listEl.current) {
      listEl.current.scrollBy({ left: 1000, behavior: 'smooth' });
    }
  };

  return (
    <Component>
      <Skin ref={listEl}>
        <ItemList className="item-list">
          {itemList.map((element, idx) => {
            return (
              <Item key={element.name + idx}>
                <a href={element.url} target="_blank" rel="noopener noreferrer">
                  <img alt="" src={element.img_url} />
                  <P3>{element.name}</P3>
                </a>
              </Item>
            );
          })}
        </ItemList>
      </Skin>
      {itemList.length > 0 ? (
        <PagingButton>
          <IconCurationLeft className="prev-button" onClick={onClickPrev} />
          <IconCurationRight className="next-button" onClick={onClickNext} />
        </PagingButton>
      ) : null}
    </Component>
  );
};

export default CurationItemList;
