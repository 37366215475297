import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import {
  IconSegId1,
  IconSegId2,
  IconSegId3,
  IconSegId4,
  IconSegId5,
  IconSegIdNull,
} from '../../Atoms/Icon';
import { P1, P3, H4 } from '../../Atoms/Typo';
import EmptyGraphImage from '../../../assets/images/img-empty-graph.png';
import MultiLineChart from '../../Molecule/Chart/MultiLineChart';
import CardSubHeader from '../../Molecule/Heading/Card/CardSubHeader';
import RecommendItemList from '../../Molecule/RecommendItemList';
import Spinner from '../../Molecule/Spinner';

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  ${P3} {
    font-weight: bold;
  }
  svg {
    margin-right: 10px;
  }
`;

const RecommendBox = styled.div`
  border: solid 1px ${(props) => props.theme.colors.grey200};
  /* height: 350px; */
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const RecommendButton = styled.div<{ $activeId?: number }>`
  position: relative;
  height: 87px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  ${P3} {
    font-weight: normal;
    margin-bottom: 0px;
    color: ${(props) => props.theme.colors.grey600};
  }
  ${P1} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey600};
  }
  &:nth-child(n + 2) {
    ::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 60%;
      left: 0;
      right: 0;
      border-right: 1px solid ${(props) => props.theme.colors.grey300};
      background-color: ${(props) => props.theme.colors.grey300};
    }
  }
  ${(props) => {
    if (props.$activeId) {
      return css`
        &:nth-child(${props.$activeId}) {
          ::after {
            content: '';
            position: absolute;
            top: 0;
            height: 5px;
            width: 80%;
            border-top: 1px solid ${(props) => props.theme.colors.blue500};
            background-color: ${(props) => props.theme.colors.blue500};
          }
          ${P3} {
            font-weight: 500;
            margin-bottom: 0px;
            color: ${(props) => props.theme.colors.black};
          }
          ${P1} {
            font-weight: 500;
            color: ${(props) => props.theme.colors.black};
          }
        }
      `;
    }
    return css``;
  }}
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const ImgText = styled.div`
  position: absolute;
  top: 50%;
  left: 40%;
  color: ${(props) => props.theme.colors.grey800};
  text-align: center;
`;

interface GraphWrapperCardProps {
  isLoadingItemList?: boolean;
  graphTitle: string;
  segmentId: number | null;
  algorithmTitle: string;
  state: number;
  startDate: string;
  clickRate: number;
  conversionRate: number;
  reuseRate: number;
  chartData: {
    [key: string]: {
      expose_graph: number;
      click_graph: number;
      purchase_graph: number;
      total_graph: number;
      reuse_graph: number;
    };
  } | null;
  itemList: {
    [key: string]: {
      count: number;
      list: {
        name: string;
        id: string;
        img_url: string;
        location: string;
        rcm_purchase: number;
        total_purchase: number;
        rcm_click: number;
        total_click: number;
        rcm_expose: number;
      }[];
    };
  } | null;
}

const GraphWrapperCard = ({
  isLoadingItemList,
  graphTitle,
  segmentId,
  algorithmTitle,
  state,
  startDate,
  clickRate,
  conversionRate,
  reuseRate,
  chartData,
  itemList,
}: GraphWrapperCardProps) => {
  // state
  const [activeBtnId, setActive] = useState(1);
  const [seriesName, setSeriesName] = useState<string[]>(['노출수', '클릭수']);
  const [count, setCount] = useState(0);
  const [rateList, setRateList] = useState<number[]>([]);

  useEffect(() => {
    setRateList([clickRate, conversionRate, reuseRate]);
  }, [clickRate, conversionRate, reuseRate]);

  const onClickbtn = (id: number) => {
    switch (id) {
      case 1:
        setActive(1);
        setSeriesName(['노출수', '클릭수']);
        break;
      case 2:
        setActive(2);
        setSeriesName(['클릭수', '구매수']);
        break;
      case 3:
        setActive(3);
        setSeriesName(['추천 1회 이상 이용 고객', '추천 2회 이상 이용 고객']);
        break;
      default:
        break;
    }
  };

  const SegmentIcon = () => {
    switch (segmentId) {
      case null:
        return <IconSegIdNull />;
      case 1:
        return <IconSegId1 />;
      case 2:
        return <IconSegId2 />;
      case 3:
        return <IconSegId3 />;
      case 4:
        return <IconSegId4 />;
      case 5:
        return <IconSegId5 />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (itemList && Object.keys(itemList).length > 0) {
      setCount(itemList[graphTitle].count);
    }
  }, [itemList, graphTitle]);

  return (
    <CardLayout>
      <CardBodyLayout>
        <Title>
          {SegmentIcon()}
          <H4>{graphTitle}</H4>
        </Title>
        <CardSubHeader subTitle="추천 효율 지표" />
        <RecommendBox>
          <ButtonGroup>
            <RecommendButton
              $activeId={activeBtnId}
              onClick={() => {
                onClickbtn(1);
              }}
            >
              <P3>노출대비 클릭율(평균)</P3>
              <P1>{clickRate + '%'}</P1>
            </RecommendButton>

            <RecommendButton
              $activeId={activeBtnId}
              onClick={() => {
                onClickbtn(2);
              }}
            >
              <P3>구매 전환율(평균)</P3>
              <P1>{conversionRate + '%'}</P1>
            </RecommendButton>

            <RecommendButton
              $activeId={activeBtnId}
              onClick={() => {
                onClickbtn(3);
              }}
            >
              <P3>추천 재이용률</P3>
              <P1>{reuseRate + '%'}</P1>
            </RecommendButton>
          </ButtonGroup>
          {chartData && Object.keys(chartData).length === 0 && state === 1 ? (
            <ImgWrapper>
              <div>
                <Img src={EmptyGraphImage} alt="" />
              </div>
              <ImgText>
                오늘의 데이터를 모으고 있습니다! <br />
                내일이면 확인 할 수 있어요
              </ImgText>
            </ImgWrapper>
          ) : (
            <MultiLineChart
              Data={chartData}
              buttonId={activeBtnId}
              label={seriesName}
              rate={rateList}
              graphHeight={200}
            />
          )}
        </RecommendBox>

        {isLoadingItemList ? (
          <Spinner />
        ) : itemList ? (
          <RecommendItemList
            algorithm={algorithmTitle}
            count={count}
            startDate={startDate}
            list={itemList[graphTitle].list}
          />
        ) : null}
      </CardBodyLayout>
    </CardLayout>
  );
};

export default GraphWrapperCard;
