import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardButtonGroup from '../../Molecule/CardButtonGroup';
import { P3 } from '../../Atoms/Typo';
import Input from '../../Atoms/Input';
import { Button } from '../../Atoms/Button';

const Componet = styled(CardLayout)`
  display: flex;
  padding: 0 30px 30px;
`;

const InputForm = styled.div`
  margin-bottom: 20px;
  ${P3} {
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const CheckPasswordInput = styled(Input)`
  margin-bottom: 0;
`;

const ConfirmButton = styled(Button)`
  margin-top: 10px;
`;

interface EditUserInfoCardProps {
  user: string;
  phone: string;
  mail: string;
  onClickUserInfo: (id: string, num: string, email: string) => void;
  onClickEditPassword: (pw: string, pw_confirm: string) => void;
}

const EditUserInfoCard = ({
  user,
  phone,
  mail,
  onClickUserInfo,
  onClickEditPassword,
}: EditUserInfoCardProps) => {
  const titleList = ['기본정보 관리', '비밀번호 관리'];
  const [buttonId, setButtonId] = useState(1);

  const [userName, setUserName] = useState('');
  const [userNumber, setUserNumber] = useState('');
  const [userMail, setUserMail] = useState('');
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);

  const [passwordPolicy, setPasswordPolicy] = useState(false);
  const [policyCheckPassword, setPolicyCheckPassword] = useState<
    boolean | null
  >(false);

  const onChangeUserName = (value: string) => {
    setUserName(value);
  };
  const onChangeUserNumber = (value: string) => {
    setUserNumber(value);
  };
  const onChangeUserMail = (value: string) => {
    setUserMail(value);
  };
  const onChangePassword = (value: string) => {
    setPassword(value);
  };
  const onChangeCheckPassword = (value: string) => {
    setCheckPassword(value);
  };

  const onConfirm = () => {
    onClickUserInfo(userName, userNumber, userMail);
  };

  const onConfirmPassword = () => {
    if (passwordPolicy && policyCheckPassword) {
      onClickEditPassword(password, checkPassword);
    }
  };

  const onClick = (id: number) => {
    switch (id) {
      case 1:
        setButtonId(1);
        break;
      case 2:
        setButtonId(2);
        break;
      default:
        break;
    }
  };

  const onClickIcon = () => {
    setShowPassword((prev) => {
      return !prev;
    });
  };

  const onClickIconCheck = () => {
    setShowCheckPassword((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    setUserName(user);
    setUserNumber(phone);
    setUserMail(mail);
  }, [user, phone, mail]);

  const printCard = () => {
    switch (buttonId) {
      case 1:
        return (
          <CardBodyLayout>
            <InputForm>
              <P3>담당자명</P3>
              <Input
                value={userName}
                type="text"
                size="large"
                onChange={onChangeUserName}
              />
            </InputForm>
            <InputForm>
              <P3>전화번호</P3>
              <Input
                value={userNumber}
                type="text"
                size="large"
                onChange={onChangeUserNumber}
              />
            </InputForm>
            <InputForm>
              <P3>이메일</P3>
              <Input
                value={userMail}
                type="text"
                size="large"
                onChange={onChangeUserMail}
              />
            </InputForm>
            <ConfirmButton size="xsamll" onClick={onConfirm}>
              저장
            </ConfirmButton>
          </CardBodyLayout>
        );
      case 2:
        return (
          <CardBodyLayout>
            <InputForm>
              <P3>신규 비밀번호</P3>
              <CheckPasswordInput
                value={password}
                type={showPassword ? 'text' : 'password'}
                status={passwordPolicy ? 'success' : 'error'}
                placeholder="비밀번호를 입력해주세요."
                errorMessage="8~16자 영문 대 소문자, 숫자, 특수문자를 사용해주세요."
                size="large"
                onChange={onChangePassword}
                onClickIcon={onClickIcon}
              />
            </InputForm>
            <InputForm>
              <P3>신규 비밀번호 확인</P3>
              <CheckPasswordInput
                value={checkPassword}
                type={showCheckPassword ? 'text' : 'password'}
                status={policyCheckPassword ? 'success' : 'error'}
                placeholder="비밀번호를 입력해주세요."
                errorMessage={
                  policyCheckPassword
                    ? '비밀번호를 확인해주세요'
                    : '비밀번호를 확인해주세요'
                }
                size="large"
                onChange={onChangeCheckPassword}
                onClickIcon={onClickIconCheck}
              />
            </InputForm>
            <ConfirmButton
              size="xsamll"
              onClick={onConfirmPassword}
              isDisabled={!(passwordPolicy && policyCheckPassword)}
            >
              저장
            </ConfirmButton>
          </CardBodyLayout>
        );

      default:
        break;
    }
  };

  // input password
  useEffect(() => {
    const password_policy1 =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]).{8,16}$/;
    setPasswordPolicy(password_policy1.test(password));
  }, [password]);

  // password check
  useEffect(() => {
    setPolicyCheckPassword(false);
    if (checkPassword.length > 0 && password === checkPassword) {
      setPolicyCheckPassword(true);
    }
  }, [password, checkPassword]);

  return (
    <Componet>
      <CardButtonGroup
        buttonId={buttonId}
        buttonList={titleList}
        onClick={onClick}
      />
      {printCard()}
    </Componet>
  );
};

export default EditUserInfoCard;
