import React, { ReactNodeArray, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import { H2, H4, P2, P3 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

const Component = styled.div<{ $id: number | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;

  .apexcharts-pie-series {
    filter: none;
    ${(props) => {
      if (props.$id === null) {
        return css``;
      }
      if (props.$id === 0) {
        return css`
          &:nth-child(1) {
            path {
              filter: none;
              fill: #b4dd70;
            }
          }
        `;
      }
      if (props.$id === 1) {
        return css`
          &:nth-child(2) {
            path {
              filter: none;
              fill: #a59df2;
            }
          }
        `;
      }
      if (props.$id === 2) {
        return css`
          &:nth-child(3) {
            path {
              filter: none;
              fill: #f98175;
            }
          }
        `;
      }
      if (props.$id === 3) {
        return css`
          &:nth-child(4) {
            path {
              filter: none;
              fill: #fcb469;
            }
          }
        `;
      }
      if (props.$id === 4) {
        return css`
          &:nth-child(5) {
            path {
              filter: none;
              fill: #82b2d2;
            }
          }
        `;
      }
      return css``;
    }}
  }
`;

const ChartDataLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartDataLabelRate = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  ${H4} {
    color: ${(props) => props.theme.colors.grey600};
    font-weight: normal;
  }
  ${H2} {
    font-size: 36px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;

const ChartDataLabelCnt = styled.div`
  ${H4} {
    color: ${(props) => props.theme.colors.grey600};
    font-weight: normal;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Item = styled.div<{ $id: number | null }>`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  :hover {
    ${P2} {
      color: ${(props) => props.theme.colors.blue400};
    }
  }
  ${(props) => {
    if (props.$id) {
      return css`
        :nth-child(${props.$id + 1}) {
          ${P2} {
            color: ${(props) => props.theme.colors.black};
            font-weight: 500;
          }
        }
      `;
    }
    return css`
      :nth-child(1) {
        ${P2} {
          color: ${(props) => props.theme.colors.black};
          font-weight: 500;
        }
      }
    `;
  }}
`;

const ItemDot = styled.div<{ $id: number }>`
  width: 16px;
  height: 16px;

  margin-right: 16px;
  ${(props) => {
    switch (props.$id) {
      case 0:
        return css`
          background-color: #b4dd70;
        `;

      case 1:
        return css`
          background-color: #a59df2;
        `;

      case 2:
        return css`
          background-color: #f98175;
        `;
      case 3:
        return css`
          background-color: #fcb469;
        `;
      case 4:
        return css`
          background-color: #82b2d2;
        `;

      default:
        return css``;
    }
  }}
`;

const ItemTitle = styled.div<{ $id: number | null }>`
  ${P2} {
    color: ${(props) => props.theme.colors.grey700};
  }
`;

// const ItemValue = styled.div`
//   ${H4} {
//     color: ${(props) => props.theme.colors.grey700};
//   }

//   margin-left: auto;
// `;

interface DounutChartProps {
  data: {
    [key: string]: string | number;
    count: number;
    rate: number;
  }[];
}

const DounutChart = ({ data }: DounutChartProps) => {
  const [seriesState, setSeries] = useState<number[]>([]);
  const [mouseOverKey, setMouseOverKey] = useState<number | null>(null);
  const [prevIdx, setPrevIdx] = useState<number | null>(null);

  const DonutChartOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      const index = config.dataPointIndex;
      setMouseOverKey(index);
    } else {
      setMouseOverKey(null);
    }
  };

  const DonutChartOnMouseLeave = (e: any, chartContext: any, config: any) => {
    setMouseOverKey(null);
  };

  const DonutChartSelection = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      setPrevIdx(config.dataPointIndex);
    }
  };

  const ClickSegment = (idx: number | null) => {
    setPrevIdx(idx);
  };

  const options = {
    chart: {
      type: 'donut' as const,
      events: {
        dataPointMouseEnter: DonutChartOnMouseEnter,
        dataPointMouseLeave: DonutChartOnMouseLeave,
        dataPointSelection: DonutChartSelection,
      },
    },
    colors: ['#b4dd70', '#a59df2', '#f98175', '#fcb469', '#82b2d2'],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: '65%',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const printChart = useMemo(() => {
    if (seriesState.length > 0) {
      return (
        <Chart
          type="donut"
          series={seriesState}
          options={options}
          width="100%"
          height="100%"
        />
      );
    }
    return <DataError />;
  }, [seriesState]);

  const printDataLabelMemo = useMemo(() => {
    if (mouseOverKey !== null && data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelRate>
            <H2>{data[mouseOverKey].rate}</H2>%
          </ChartDataLabelRate>
          <ChartDataLabelCnt>
            <H4>{data[mouseOverKey].count.toLocaleString()}명</H4>
          </ChartDataLabelCnt>
        </ChartDataLabelWrapper>
      );
    }
    if (prevIdx !== null && data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelRate>
            <H2>{data[prevIdx].rate}</H2>%
          </ChartDataLabelRate>
          <ChartDataLabelCnt>
            <H4>{data[prevIdx].count.toLocaleString()}명</H4>
          </ChartDataLabelCnt>
        </ChartDataLabelWrapper>
      );
    }
    if (data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelRate>
            <H2>{data[0].rate}</H2>%
          </ChartDataLabelRate>
          <ChartDataLabelCnt>
            <H4>{data[0].count.toLocaleString()}명</H4>
          </ChartDataLabelCnt>
        </ChartDataLabelWrapper>
      );
    }
    return [];
  }, [mouseOverKey, prevIdx, data]);

  const printDataTable = useMemo(() => {
    const result: ReactNodeArray = [];
    if (data.length > 0) {
      data.some((element, index) => {
        if (index > 4) {
          return true;
        }
        const keyArr = Object.keys(element);
        const key = keyArr[0];

        result.push(
          <Item
            key={element[key]}
            $id={prevIdx}
            onClick={() => {
              ClickSegment(index);
            }}
          >
            <ItemDot $id={index} />
            <ItemTitle $id={prevIdx}>
              <P2>{element[key]}</P2>
            </ItemTitle>
            {/* <ItemValue>
              <H4>{element.rate}%</H4>
            </ItemValue> */}
          </Item>
        );
        return false;
      });
    }
    return result;
  }, [data, prevIdx]);

  useEffect(() => {
    if (data.length > 0) {
      const seriesTemp: number[] = [];
      data.some((element, index) => {
        if (index > 4) {
          return true;
        }
        seriesTemp.push(element.rate);
        return false;
      });
      setSeries(seriesTemp);
    }
  }, [data]);

  return (
    <Layout>
      <ItemWrapper>{printDataTable}</ItemWrapper>
      <Component $id={prevIdx}>
        {printChart}
        {printDataLabelMemo}
      </Component>
    </Layout>
  );
};

export default DounutChart;
