import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../components/Organisms/Header';
import StatusSearchCard from '../../components/Organisms/Card/StatusSearchCard';
import StatusDataCard from '../../components/Organisms/Card/StatusDataCard';

import { GetCurationStatusParamType } from '../../api/analysis/type';
import { getCurationStatusAsync } from '../../modules/analysis';
import { GetManageSegmentListParamType } from '../../api/register/type';
import { getSegmentListAsync } from '../../modules/register';

const CurationStatus = () => {
  const dispatch = useDispatch();
  const { isStatusLoading, CurationData } = useSelector(
    (state: RootState) => state.analysis
  );
  const { isSegmentLoading, segData } = useSelector(
    (state: RootState) => state.register
  );

  const onGetCurationStatus = (info: GetCurationStatusParamType) => {
    dispatch(getCurationStatusAsync.request(info));
  };

  const onGetSegmentList = (info: GetManageSegmentListParamType) => {
    dispatch(getSegmentListAsync.request(info));
  };

  // seg Button Click
  const onClickSegButton = (id: number | null) => {
    onGetCurationStatus({
      segment_id: id,
    });
  };

  useEffect(() => {
    onGetSegmentList({});
    onGetCurationStatus({
      segment_id: null,
    });
  }, []);

  return (
    <ContentsLayout>
      <Header title="고객군별 추천 상품" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <StatusSearchCard
            loading={isSegmentLoading}
            segmentList={segData}
            onClick={onClickSegButton}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <StatusDataCard loading={isStatusLoading} data={CurationData} />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default CurationStatus;
