import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CurationItemList from '../../Molecule/CurationItemList';
import { P2 } from '../../Atoms/Typo';
import Spinner from '../../Molecule/Spinner';

const RecommendWrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: 10px;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
  }
`;

interface StatusDataCardProps {
  loading: boolean;
  data: {
    [key: string]: {
      id: string;
      name: string;
      ca_id: string;
      img_url: string;
      url: string;
    }[];
  } | null;
}

const StatusDataCard = ({ data, loading }: StatusDataCardProps) => {
  // state
  const [algorithmName, setAlgorithmName] = useState<string[]>();

  useEffect(() => {
    if (data) {
      setAlgorithmName(Object.keys(data));
    }
  }, [data]);

  return (
    <CardLayout>
      {loading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          {algorithmName?.map((element, idx) => {
            return (
              <RecommendWrapper key={element + idx}>
                <Title>
                  <P2>{element}</P2>
                </Title>
                {data ? <CurationItemList itemList={data[element]} /> : null}
              </RecommendWrapper>
            );
          })}
        </CardBodyLayout>
      )}
    </CardLayout>
  );
};

export default StatusDataCard;
