import { createReducer } from 'typesafe-actions';
import {
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_TOTAL_USER_STATISTICS_ERROR,
  GET_NEW_USER_STATISTICS,
  GET_NEW_USER_STATISTICS_SUCCESS,
  GET_NEW_USER_STATISTICS_ERROR,
  GET_PAGE_VIEW_USER_STATISTICS,
  GET_PAGE_VIEW_USER_STATISTICS_ERROR,
  GET_PAGE_VIEW_USER_STATISTICS_SUCCESS,
  GET_SESSION_USER_STATISTICS,
  GET_SESSION_USER_STATISTICS_ERROR,
  GET_SESSION_USER_STATISTICS_SUCCESS,
  GET_PV_MEAN_STATISTICS,
  GET_PV_MEAN_STATISTICS_ERROR,
  GET_PV_MEAN_STATISTICS_SUCCESS,
  GET_USAGE_MEAN_STATISTICS,
  GET_USAGE_MEAN_STATISTICS_ERROR,
  GET_USAGE_MEAN_STATISTICS_SUCCESS,
  GET_UV_MEAN_STATISTICS,
  GET_UV_MEAN_STATISTICS_ERROR,
  GET_UV_MEAN_STATISTICS_SUCCESS,
  GET_BROWSER_STATICS,
  GET_BROWSER_STATICS_ERROR,
  GET_BROWSER_STATICS_SUCCESS,
  GET_DEVICE_STATICS,
  GET_DEVICE_STATICS_ERROR,
  GET_DEVICE_STATICS_SUCCESS,
  GET_OS_STATICS,
  GET_OS_STATICS_ERROR,
  GET_OS_STATICS_SUCCESS,
  SET_ERROR_NULL_SERVICE_ANALYSIS,
  GET_CURATION_STATUS,
  GET_CURATION_STATUS_ERROR,
  GET_CURATION_STATUS_SUCCESS,
} from './actions';
import { analysisAction, analysisState } from './types';

const initialState: analysisState = {
  isStatusLoading: false,
  isTotalLoading: false,
  isNewLoading: false,
  isPageViewLoading: false,
  isSessionLoading: false,
  isPVLoading: false,
  isUVLoading: false,
  isUsageLoading: false,
  isDeviceLoading: false,
  isOSLoading: false,
  isBrowserLoading: false,

  CurationData: null,
  TotalUserData: null,
  NewUserData: null,
  PageViewData: null,
  SessionData: null,
  PVMeanData: null,
  UVMeanData: null,
  UsageMeanData: null,
  Device: [],
  OS: [],
  Browser: [],

  serviceAnalysisError: null,
};

const reducer = createReducer<analysisState, analysisAction>(initialState, {
  [GET_CURATION_STATUS]: (state) => ({
    ...state,
    isStatusLoading: true,
    CurationData: null,
    serviceAnalysisError: null,
  }),
  [GET_CURATION_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    isStatusLoading: false,
    CurationData: action.payload.payload,
  }),
  [GET_CURATION_STATUS_ERROR]: (state, action) => ({
    ...state,
    isStatusLoading: false,
    CurationData: null,
    serviceAnalysisError: action.payload,
  }),

  [GET_TOTAL_USER_STATISTICS]: (state) => ({
    ...state,
    isTotalLoading: true,
    TotalUserData: null,
    serviceAnalysisError: null,
  }),
  [GET_TOTAL_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isTotalLoading: false,
    TotalUserData: action.payload.payload,
  }),
  [GET_TOTAL_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isTotalLoading: false,
    TotalUserData: null,
    serviceAnalysisError: action.payload,
  }),

  [GET_NEW_USER_STATISTICS]: (state) => ({
    ...state,
    isNewLoading: true,
    NewUserData: null,
    serviceAnalysisError: null,
  }),
  [GET_NEW_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isNewLoading: false,
    NewUserData: action.payload.payload,
  }),
  [GET_NEW_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isNewLoading: false,
    NewUserData: null,
    serviceAnalysisError: action.payload,
  }),

  [GET_PAGE_VIEW_USER_STATISTICS]: (state) => ({
    ...state,
    isPageViewLoading: true,
    PageViewData: null,
    serviceAnalysisError: null,
  }),
  [GET_PAGE_VIEW_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isPageViewLoading: false,
    PageViewData: action.payload.payload,
  }),
  [GET_PAGE_VIEW_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isPageViewLoading: false,
    PageViewData: null,
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_SESSION_USER_STATISTICS]: (state) => ({
    ...state,
    isSessionLoading: true,
    SessionData: null,
    serviceAnalysisError: null,
  }),
  [GET_SESSION_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isSessionLoading: false,
    SessionData: action.payload.payload,
  }),
  [GET_SESSION_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isSessionLoading: false,
    SessionData: null,
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_PV_MEAN_STATISTICS]: (state) => ({
    ...state,
    isPVLoading: true,
    PVMeanData: null,
    serviceAnalysisError: null,
  }),
  [GET_PV_MEAN_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isPVLoading: false,
    PVMeanData: action.payload.payload,
  }),
  [GET_PV_MEAN_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isPVLoading: false,
    PVMeanData: null,
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_UV_MEAN_STATISTICS]: (state) => ({
    ...state,
    isUVLoading: true,
    UVMeanData: null,
    serviceAnalysisError: null,
  }),
  [GET_UV_MEAN_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isUVLoading: false,
    UVMeanData: action.payload.payload,
  }),
  [GET_UV_MEAN_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isUVLoading: false,
    UVMeanData: null,
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_USAGE_MEAN_STATISTICS]: (state) => ({
    ...state,
    isUsageLoading: true,
    UsageMeanData: null,
    serviceAnalysisError: null,
  }),
  [GET_USAGE_MEAN_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isUsageLoading: false,
    UsageMeanData: action.payload.payload,
  }),
  [GET_USAGE_MEAN_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isUsageLoading: false,
    UsageMeanData: null,
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_DEVICE_STATICS]: (state) => ({
    ...state,
    isDeviceLoading: true,
    Device: [],
    serviceAnalysisError: null,
  }),
  [GET_DEVICE_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isDeviceLoading: false,
    Device: action.payload.payload,
  }),
  [GET_DEVICE_STATICS_ERROR]: (state, action) => ({
    ...state,
    isDeviceLoading: false,
    Device: [],
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_OS_STATICS]: (state) => ({
    ...state,
    isOSLoading: true,
    OS: [],
    serviceAnalysisError: null,
  }),
  [GET_OS_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isOSLoading: false,
    OS: action.payload.payload,
  }),
  [GET_OS_STATICS_ERROR]: (state, action) => ({
    ...state,
    isOSLoading: false,
    OS: [],
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [GET_BROWSER_STATICS]: (state) => ({
    ...state,
    isBrowserLoading: true,
    Browser: [],
    serviceAnalysisError: null,
  }),
  [GET_BROWSER_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isBrowserLoading: false,
    Browser: action.payload.payload,
  }),
  [GET_BROWSER_STATICS_ERROR]: (state, action) => ({
    ...state,
    isBrowserLoading: false,
    Browser: [],
    serviceAnalysisError: action.payload,
  }),
  // ------------------------------------------------------
  [SET_ERROR_NULL_SERVICE_ANALYSIS]: (state) => ({
    ...state,
    serviceAnalysisError: null,
  }),
});

export default reducer;
