import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  IconDashboardSegId1,
  IconDashboardSegId2,
  IconDashboardSegId3,
  IconDashboardSegId4,
  IconDashboardSegId5,
} from '../../Atoms/Icon';
import { P2 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  svg {
    width: 44px;
    height: 44px;
    margin-right: 8px;
  }
`;

const BarWrap = styled.div`
  width: 100%;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
  }
`;

const RateWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    width: 43px;
    margin-left: 8px;
    text-align: end;
  }
`;

const ProgressBar = styled.div`
  height: 12px;
  width: 100%;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey300};
`;

const Value = styled.div<{ $id: number; $val: number }>`
  height: 12px;
  width: ${(props) => props.$val + '%'};
  border-radius: 6px;
  ${(props) => {
    if (props.$id === 1) {
      return css`
        background-color: #fcb469;
      `;
    }
    if (props.$id === 2) {
      return css`
        background-color: #a59df2;
      `;
    }
    if (props.$id === 3) {
      return css`
        background-color: #f98175;
      `;
    }
    if (props.$id === 4) {
      return css`
        background-color: #82b2d2;
      `;
    }
    if (props.$id === 5) {
      return css`
        background-color: #b4dd70;
      `;
    }

    return css``;
  }}
  transition: 1s ease;
  transition-delay: 0.5s;
`;

interface SegmentProgressProps {
  segid: number;
  rate: number;
  name: string;
}
const SegmentProgress = ({ segid, rate, name }: SegmentProgressProps) => {
  const [value, setValue] = useState(0);

  const SegmentImg = (id: number) => {
    switch (id) {
      case 1:
        return <IconDashboardSegId1 />;
      case 2:
        return <IconDashboardSegId2 />;
      case 3:
        return <IconDashboardSegId3 />;
      case 4:
        return <IconDashboardSegId4 />;
      case 5:
        return <IconDashboardSegId5 />;
      default:
        break;
    }
  };

  useEffect(() => {
    setValue(rate);
  }, [rate]);

  return (
    <Component>
      {SegmentImg(segid)}
      <BarWrap>
        <P2>{name}</P2>
        <RateWrap>
          <ProgressBar>
            <Value $id={segid} $val={value} />
          </ProgressBar>
          <P2>{rate}%</P2>
        </RateWrap>
      </BarWrap>
    </Component>
  );
};

export default SegmentProgress;
