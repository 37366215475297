import { createReducer } from 'typesafe-actions';
import {
  SET_MANAGE_RECOMMEND_CREATE,
  SET_MANAGE_RECOMMEND_CREATE_SUCCESS,
  SET_MANAGE_RECOMMEND_CREATE_ERROR,
  SET_MANAGE_RECOMMEND_STATE,
  SET_MANAGE_RECOMMEND_STATE_SUCCESS,
  SET_MANAGE_RECOMMEND_STATE_ERROR,
  GET_MANAGE_SEGMENT,
  GET_MANAGE_SEGMENT_SUCCESS,
  GET_MANAGE_SEGMENT_ERROR,
  GET_MANAGE_PAGE,
  GET_MANAGE_PAGE_SUCCESS,
  GET_MANAGE_PAGE_ERROR,
  GET_MANAGE_ALGORITHM,
  GET_MANAGE_ALGORITHM_SUCCESS,
  GET_MANAGE_ALGORITHM_ERROR,
  SET_ERROR_NULL_REGISTER,
} from './actions';
import { registerAction, registerState } from './types';

const initialState: registerState = {
  isCreateLoading: false,
  isStateLoading: false,
  isSegmentLoading: false,
  isPageLoading: false,
  isAlgorithmLoading: false,
  setState: false,
  setCreate: false,

  segData: null,
  pageData: null,
  algorithmData: null,

  registerError: null,
};

const reducer = createReducer<registerState, registerAction>(initialState, {
  // 개인화 추천 생성
  [SET_MANAGE_RECOMMEND_CREATE]: (state) => ({
    ...state,
    setCreate: false,
    isCreateLoading: true,
    registerError: null,
  }),
  [SET_MANAGE_RECOMMEND_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    setCreate: true,
    isCreateLoading: false,
  }),
  [SET_MANAGE_RECOMMEND_CREATE_ERROR]: (state, action) => ({
    ...state,
    setCreate: false,
    isCreateLoading: false,
    registerError: action.payload,
  }),
  // 개인화 추천 상태 변경
  [SET_MANAGE_RECOMMEND_STATE]: (state) => ({
    ...state,
    isStateLoading: true,
    setState: false,
    registerError: null,
  }),
  [SET_MANAGE_RECOMMEND_STATE_SUCCESS]: (state, action) => ({
    ...state,
    isStateLoading: false,
    setState: true,
  }),
  [SET_MANAGE_RECOMMEND_STATE_ERROR]: (state, action) => ({
    ...state,
    isStateLoading: false,
    setState: false,
    registerError: action.payload,
  }),
  // 세그먼트 리스트
  [GET_MANAGE_SEGMENT]: (state) => ({
    ...state,
    isSegmentLoading: true,
    segData: null,
    registerError: null,
  }),
  [GET_MANAGE_SEGMENT_SUCCESS]: (state, action) => ({
    ...state,
    isSegmentLoading: false,
    segData: action.payload.payload,
  }),
  [GET_MANAGE_SEGMENT_ERROR]: (state, action) => ({
    ...state,
    isSegmentLoading: false,
    segData: null,
    registerError: action.payload,
  }),
  // 페이지 리스트
  [GET_MANAGE_PAGE]: (state) => ({
    ...state,
    isPageLoading: true,
    pageData: null,
    registerError: null,
  }),
  [GET_MANAGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    isPageLoading: false,
    pageData: action.payload.payload,
  }),
  [GET_MANAGE_PAGE_ERROR]: (state, action) => ({
    ...state,
    isPageLoading: false,
    pageData: null,
    registerError: action.payload,
  }),
  // 알고리즘 리스트
  [GET_MANAGE_ALGORITHM]: (state) => ({
    ...state,
    isAlgorithmLoading: true,
    algorithmData: null,
    registerError: null,
  }),
  [GET_MANAGE_ALGORITHM_SUCCESS]: (state, action) => ({
    ...state,
    isAlgorithmLoading: false,
    algorithmData: action.payload.payload,
  }),
  [GET_MANAGE_ALGORITHM_ERROR]: (state, action) => ({
    ...state,
    isAlgorithmLoading: false,
    algorithmData: null,
    registerError: action.payload,
  }),
  //
  [SET_ERROR_NULL_REGISTER]: (state) => ({
    ...state,
    registerError: null,
  }),
});

export default reducer;
