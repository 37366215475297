import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';

import {
  GetManageRecommendListParamType,
  GetManageRecommendListResponseType,
  GetManageRecommendDetailParamType,
  GetManageRecommendDetailResponseType,
  GetManageRecommendGraphParamType,
  GetManageRecommendGraphResponseType,
  SetRecommendNameParamType,
  SetRecommendNameResponseType,
  GetAlgorithmCheckParamType,
  GetAlgorithmCheckResponseType,
  DeleteRecommendParamType,
  DeleteRecommendResponseType,
} from '../../api/management/type';

// 개인화 추천 리스트
export const GET_MANAGE_RECOMMEND_LIST =
  'management/GET_MANAGE_RECOMMEND_LIST' as const;
export const GET_MANAGE_RECOMMEND_LIST_SUCCESS =
  'management/GET_MANAGE_RECOMMEND_LIST_SUCCESS' as const;
export const GET_MANAGE_RECOMMEND_LIST_ERROR =
  'management/GET_MANAGE_RECOMMEND_LIST_ERROR' as const;

export const getRecommendListAsync = createAsyncAction(
  GET_MANAGE_RECOMMEND_LIST,
  GET_MANAGE_RECOMMEND_LIST_SUCCESS,
  GET_MANAGE_RECOMMEND_LIST_ERROR
)<
  GetManageRecommendListParamType,
  GetManageRecommendListResponseType,
  AxiosErrorType
>();

// 개인화 추천 상세정보
export const GET_MANAGE_RECOMMEND_DETAIL =
  'management/GET_MANAGE_RECOMMEND_DETAIL' as const;
export const GET_MANAGE_RECOMMEND_DETAIL_SUCCESS =
  'management/GET_MANAGE_RECOMMEND_DETAIL_SUCCESS' as const;
export const GET_MANAGE_RECOMMEND_DETAIL_ERROR =
  'management/GET_MANAGE_RECOMMEND_DETAIL_ERROR' as const;

export const getRecommendDetailAsync = createAsyncAction(
  GET_MANAGE_RECOMMEND_DETAIL,
  GET_MANAGE_RECOMMEND_DETAIL_SUCCESS,
  GET_MANAGE_RECOMMEND_DETAIL_ERROR
)<
  GetManageRecommendDetailParamType,
  GetManageRecommendDetailResponseType,
  AxiosErrorType
>();

// 개인화 추천 상세정보 그래프
export const GET_MANAGE_RECOMMEND_GRAPH =
  'management/GET_MANAGE_RECOMMEND_GRAPH' as const;
export const GET_MANAGE_RECOMMEND_GRAPH_SUCCESS =
  'management/GET_MANAGE_RECOMMEND_GRAPH_SUCCESS' as const;
export const GET_MANAGE_RECOMMEND_GRAPH_ERROR =
  'management/GET_MANAGE_RECOMMEND_GRAPH_ERROR' as const;

export const getRecommendGraphAsync = createAsyncAction(
  GET_MANAGE_RECOMMEND_GRAPH,
  GET_MANAGE_RECOMMEND_GRAPH_SUCCESS,
  GET_MANAGE_RECOMMEND_GRAPH_ERROR
)<
  GetManageRecommendGraphParamType,
  GetManageRecommendGraphResponseType,
  AxiosErrorType
>();

// 개인화 추천 이름 변경
export const SET_RECOMMEND_NAME = 'management/SET_RECOMMEND_NAME' as const;
export const SET_RECOMMEND_NAME_SUCCESS =
  'management/SET_RECOMMEND_NAME_SUCCESS' as const;
export const SET_RECOMMEND_NAME_ERROR =
  'management/SET_RECOMMEND_NAME_ERROR' as const;

export const setRecommendNameAsync = createAsyncAction(
  SET_RECOMMEND_NAME,
  SET_RECOMMEND_NAME_SUCCESS,
  SET_RECOMMEND_NAME_ERROR
)<SetRecommendNameParamType, SetRecommendNameResponseType, AxiosErrorType>();

// 알고리즘 동작 확인 API
export const GET_ALGORITHM_CHECK = 'management/GET_ALGORITHM_CHECK' as const;
export const GET_ALGORITHM_CHECK_SUCCESS =
  'management/GET_ALGORITHM_CHECK_SUCCESS' as const;
export const GET_ALGORITHM_CHECK_ERROR =
  'management/GET_ALGORITHM_CHECK_ERROR' as const;

export const getAlgorithmCheckAsync = createAsyncAction(
  GET_ALGORITHM_CHECK,
  GET_ALGORITHM_CHECK_SUCCESS,
  GET_ALGORITHM_CHECK_ERROR
)<GetAlgorithmCheckParamType, GetAlgorithmCheckResponseType, AxiosErrorType>();

// 대기중인 추천 삭제
export const DELETE_RECOMMEND = `management/DELETE_RECOMMEND` as const;
export const DELETE_RECOMMEND_SUCCESS =
  `management/DELETE_RECOMMEND_SUCCESS` as const;
export const DELETE_RECOMMEND_ERROR =
  `management/DELETE_RECOMMEND_ERROR` as const;

export const deleteRecommendAsync = createAsyncAction(
  DELETE_RECOMMEND,
  DELETE_RECOMMEND_SUCCESS,
  DELETE_RECOMMEND_ERROR
)<DeleteRecommendParamType, DeleteRecommendResponseType, AxiosErrorType>();

// 기본
export const SET_ERROR_NULL_MANAGEMENT = 'management/SET_ERROR_NULL_MANAGEMENT';
export const setErrorNullManagement = createAction(SET_ERROR_NULL_MANAGEMENT)();
