import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { H4 } from '../../Atoms/Typo';
import InputWrapper from '../../Molecule/InputWrapper';
import { IconRecommendTitle } from '../../Atoms/Icon';

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  svg {
    margin-right: 10px;
  }
`;

interface SettingNameCardProps {
  title: string;
  state: number;
  exposeName?: string;
  onSave: (name: string, type: number) => void;
}

const SettingNameCard = ({
  title,
  state,
  exposeName,
  onSave,
}: SettingNameCardProps) => {
  // state
  const [recommendName, setRecommendName] = useState('');
  const [exposureName, setExposureName] = useState('');
  const [exposeRecomTitle, setRecomTitle] = useState<string>();

  const changeRecommendName = (value: string) => {
    setRecommendName(value);
  };

  const changeExposureName = (value: string) => {
    setExposureName(value);
  };

  const onClickSave = () => {
    onSave(recommendName, 1);
  };

  const saveExposure = () => {
    onSave(exposureName, 2);
  };

  const titleWrapperFn = () => {
    switch (state) {
      case 2:
        return (
          <React.Fragment>
            <InputWrapper
              isClose
              closeText
              title="추천 이름"
              inputPlaceholder={recommendName}
            />
            <InputWrapper
              isClose
              title="노출될 추천 이름 (쇼핑몰 회원이 보는 이름)"
              inputPlaceholder={exposeRecomTitle}
            />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <InputWrapper
              title="추천 이름"
              inputPlaceholder={recommendName}
              onChange={changeRecommendName}
              onClickSave={onClickSave}
            />
            <InputWrapper
              title="노출될 추천 이름 (쇼핑몰 회원이 보는 이름)"
              inputPlaceholder={exposeRecomTitle}
              onChange={changeExposureName}
              onClickSave={saveExposure}
            />
          </React.Fragment>
        );
    }
  };

  useEffect(() => {
    setRecommendName(title);
  }, [title]);

  useEffect(() => {
    if (exposeName) {
      setRecomTitle(exposeName);
    }
  }, [exposeName]);

  return (
    <CardLayout>
      <CardHeader>
        <IconRecommendTitle />
        <H4>추천 이름 설정</H4>
      </CardHeader>
      <CardBodyLayout>{titleWrapperFn()}</CardBodyLayout>
    </CardLayout>
  );
};

export default SettingNameCard;
