import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';
import {
  GetItemAnalysisParamType,
  GetItemAnalysisResponseType,
  GetAnalysisAlgorithmListParamType,
  GetAnalysisAlgorithmResponseType,
} from '../../api/itemAnalysis/type';

export const GET_ITEM_ANALYSIS = 'itemAnalysis/GET_ITEM_ANALYSIS' as const;
export const GET_ITEM_ANALYSIS_SUCCESS =
  'itemAnalysis/GET_ITEM_ANALYSIS_SUCCESS' as const;
export const GET_ITEM_ANALYSIS_ERROR =
  'itemAnalysis/GET_ITEM_ANALYSIS_ERROR' as const;

export const GET_ANALYSIS_ALGORITHM =
  'itemAnalysis/GET_ANALYSIS_ALGORITHM' as const;
export const GET_ANALYSIS_ALGORITHM_SUCCESS =
  'itemAnalysis/GET_ANALYSIS_ALGORITHM_SUCCESS' as const;
export const GET_ANALYSIS_ALGORITHM_ERROR =
  'itemAnalysis/GET_ANALYSIS_ALGORITHM_ERROR' as const;

export const SET_ERROR_NULL_ITEM_ANALYSIS =
  'itemAnalysis/SET_ERROR_NULL_ITEM_ANALYSIS';

export const getItemAnalysisAsync = createAsyncAction(
  GET_ITEM_ANALYSIS,
  GET_ITEM_ANALYSIS_SUCCESS,
  GET_ITEM_ANALYSIS_ERROR
)<GetItemAnalysisParamType, GetItemAnalysisResponseType, AxiosErrorType>();

export const getAnalysisAlgorithmAsync = createAsyncAction(
  GET_ANALYSIS_ALGORITHM,
  GET_ANALYSIS_ALGORITHM_SUCCESS,
  GET_ANALYSIS_ALGORITHM_ERROR
)<
  GetAnalysisAlgorithmListParamType,
  GetAnalysisAlgorithmResponseType,
  AxiosErrorType
>();

export const setErrorNullItemAnalysis = createAction(
  SET_ERROR_NULL_ITEM_ANALYSIS
)();
