import { takeLatest } from 'redux-saga/effects';
import {
  apiGetRecommendList,
  apiGetRecommendDetail,
  apiGetRecommendGraph,
  apiSetRecommendName,
  apiGetAlgorithmCheck,
  apiDeleteRecommend,
} from '../../api/management/api';

import createRequestSaga from '../createRequestSaga';
import {
  GET_MANAGE_RECOMMEND_LIST,
  GET_MANAGE_RECOMMEND_DETAIL,
  GET_MANAGE_RECOMMEND_GRAPH,
  SET_RECOMMEND_NAME,
  getRecommendListAsync,
  getRecommendDetailAsync,
  getRecommendGraphAsync,
  setRecommendNameAsync,
  getAlgorithmCheckAsync,
  GET_ALGORITHM_CHECK,
  DELETE_RECOMMEND,
  deleteRecommendAsync,
} from './actions';

const getRecommendListSaga = createRequestSaga(
  getRecommendListAsync,
  apiGetRecommendList
);
const getRecommendDetailSaga = createRequestSaga(
  getRecommendDetailAsync,
  apiGetRecommendDetail
);
const getRecommendGraphSaga = createRequestSaga(
  getRecommendGraphAsync,
  apiGetRecommendGraph
);
const setRecommendNameSaga = createRequestSaga(
  setRecommendNameAsync,
  apiSetRecommendName
);
const getAlgorithmCheckSaga = createRequestSaga(
  getAlgorithmCheckAsync,
  apiGetAlgorithmCheck
);
const deleteRecommendSaga = createRequestSaga(
  deleteRecommendAsync,
  apiDeleteRecommend
);

export function* managementSaga() {
  yield takeLatest(GET_MANAGE_RECOMMEND_LIST, getRecommendListSaga);
  yield takeLatest(GET_MANAGE_RECOMMEND_DETAIL, getRecommendDetailSaga);
  yield takeLatest(GET_MANAGE_RECOMMEND_GRAPH, getRecommendGraphSaga);
  yield takeLatest(SET_RECOMMEND_NAME, setRecommendNameSaga);
  yield takeLatest(GET_ALGORITHM_CHECK, getAlgorithmCheckSaga);
  yield takeLatest(DELETE_RECOMMEND, deleteRecommendSaga);
}

export { managementSaga as default };
