import { takeLatest } from 'redux-saga/effects';
import {
  apiGetAccumulateInfo,
  apiGetProgressInfo,
  apiGetEndedInfo,
  apiGetSegmentStatus,
  apiGetUsageSegment,
  apiGetRecommendTotalGraph,
} from '../../api/dashboard/api';

import createRequestSaga from '../createRequestSaga';
import {
  GET_DASHBOARD_ACCUMULATE,
  GET_DASHBOARD_PROGRESS,
  GET_DASHBOARD_ENDED,
  GET_SEGMENT_STATUS,
  GET_USAGE_SEGMENT,
  GET_RECOMMEND_TOTAL_GRAPH,
  getAccumulateAsync,
  getProgressAsync,
  getEndedAsync,
  getSegmentStatusAsync,
  getUsageSegmentAsync,
  getRecommendTotalGraphAsync,
} from './actions';

const getDashboardAccumulateSaga = createRequestSaga(
  getAccumulateAsync,
  apiGetAccumulateInfo
);
const getDashboardProgressSaga = createRequestSaga(
  getProgressAsync,
  apiGetProgressInfo
);
const getDashboardEndedSaga = createRequestSaga(getEndedAsync, apiGetEndedInfo);
const getSegmentStatusSaga = createRequestSaga(
  getSegmentStatusAsync,
  apiGetSegmentStatus
);
const getUsageSegmentSaga = createRequestSaga(
  getUsageSegmentAsync,
  apiGetUsageSegment
);
const getRecommendTotalGraphSaga = createRequestSaga(
  getRecommendTotalGraphAsync,
  apiGetRecommendTotalGraph
);

export function* dashboardSaga() {
  yield takeLatest(GET_DASHBOARD_ACCUMULATE, getDashboardAccumulateSaga);
  yield takeLatest(GET_DASHBOARD_PROGRESS, getDashboardProgressSaga);
  yield takeLatest(GET_DASHBOARD_ENDED, getDashboardEndedSaga);
  yield takeLatest(GET_SEGMENT_STATUS, getSegmentStatusSaga);
  yield takeLatest(GET_USAGE_SEGMENT, getUsageSegmentSaga);
  yield takeLatest(GET_RECOMMEND_TOTAL_GRAPH, getRecommendTotalGraphSaga);
}

export { dashboardSaga as default };
