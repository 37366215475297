import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../modules';
import DefaultModal from '../../components/UIComponents/Modal/DefaultModal';
import { AxiosErrorType } from '../../modules/createRequestSaga';
import { setErrorNullAuth } from '../../modules/auth';

const ErrorComponent = () => {
  const {
    setLoginError,
    setLoginErrorMessage,
    // getCheckSessionError,
    // getCheckSessionErrorMessage,
  } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const onClickModalConfirmButton = () => {
    setIsModalOpen(false);
  };

  function isResponse(args: AxiosErrorType): args is AxiosError['response'] {
    return args.data !== undefined;
  }
  function isRequest(args: AxiosErrorType): args is AxiosError['request'] {
    return args.responseXML !== undefined;
  }

  // useEffect(() => {
  //   let message = '';
  //   if (getCheckSessionError) {
  //     if (isResponse(getCheckSessionError) && getCheckSessionError) {
  //       const key = getCheckSessionError.data.code as string;
  //       if (
  //         Object.prototype.hasOwnProperty.call(getCheckSessionErrorMessage, key)
  //       ) {
  //         message = getCheckSessionErrorMessage[key];
  //       } else {
  //         message = getCheckSessionErrorMessage.default;
  //       }
  //     } else if (isRequest(getCheckSessionError)) {
  //       message = getCheckSessionErrorMessage.network;
  //     } else {
  //       message = getCheckSessionErrorMessage.default;
  //     }

  //     setErrorMessage(message);
  //     setIsModalOpen(true);
  //   }
  // }, [getCheckSessionError]);

  useEffect(() => {
    let message = '';
    if (setLoginError) {
      if (isResponse(setLoginError) && setLoginError) {
        const key = setLoginError.data.code as string;
        if (Object.prototype.hasOwnProperty.call(setLoginErrorMessage, key)) {
          message = setLoginErrorMessage[key];
        } else {
          message = setLoginErrorMessage.default;
        }
      } else if (isRequest(setLoginError)) {
        message = setLoginErrorMessage.network;
      } else {
        message = setLoginErrorMessage.default;
      }
      setErrorMessage(message);
      setIsModalOpen(true);
    }
  }, [setLoginError]);

  useEffect(() => {
    return () => {
      dispatch(setErrorNullAuth());
    };
  }, []);
  return (
    <DefaultModal
      isPublic
      isOpen={isModalOpen}
      message={errorMessage}
      handleClose={onClickModalConfirmButton}
    />
  );
};

export default ErrorComponent;
