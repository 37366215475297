import React, { useEffect, useState } from 'react';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import Header from '../../components/Organisms/Header';
import ConfirmPassword from './ConfirmPassword';
import EditUserInfo from './EditUserInfo';

const Settings = () => {
  const [confirm, setConfirm] = useState(false);

  const checkPassword = (state: boolean) => {
    setConfirm(state);
  };

  useEffect(() => {
    setConfirm(false);
  }, []);

  return (
    <ContentsLayout>
      <Header title="계정 설정" isDatePicker={false}></Header>
      {confirm ? (
        <EditUserInfo />
      ) : (
        <ConfirmPassword checkPassword={checkPassword} />
      )}
    </ContentsLayout>
  );
};

export default Settings;
