import { takeLatest } from 'redux-saga/effects';
import {
  apiGetTotalUserStatistics,
  apiGetNewUserStatistics,
  apiGetPVMeanStatistics,
  apiGetPageViewUserStatistics,
  apiGetSessionUserStatistics,
  apiGetUVMeanStatistics,
  apiGetUsageMeanStatistics,
  apiGetBrowserStatistics,
  apiGetDeviceStatistics,
  apiGetOSStatistics,
  apiGetCurationStatus,
} from '../../api/analysis/api';
import {
  GET_TOTAL_USER_STATISTICS,
  GET_NEW_USER_STATISTICS,
  GET_PAGE_VIEW_USER_STATISTICS,
  GET_SESSION_USER_STATISTICS,
  GET_PV_MEAN_STATISTICS,
  GET_UV_MEAN_STATISTICS,
  GET_USAGE_MEAN_STATISTICS,
  GET_BROWSER_STATICS,
  GET_DEVICE_STATICS,
  GET_OS_STATICS,
  GET_CURATION_STATUS,
  getTotalUserStatisticsAsync,
  getNewUserStatisticsAsync,
  getPageViewUserStatisticsAsync,
  getSessionUserStatisticsAsync,
  getPVMeanStatisticsAsync,
  getUVMeanStatisticsAsync,
  getUsageMeanStatisticsAsync,
  getBrowserStaticsAsync,
  getDeviceStaticsAsync,
  getOSStaticsAsync,
  getCurationStatusAsync,
} from './actions';
import createRequestSaga from '../createRequestSaga';

const getCurationStatusSaga = createRequestSaga(
  getCurationStatusAsync,
  apiGetCurationStatus
);
const getTotalUserStatisticsSaga = createRequestSaga(
  getTotalUserStatisticsAsync,
  apiGetTotalUserStatistics
);
const getNewUserStatisticsSaga = createRequestSaga(
  getNewUserStatisticsAsync,
  apiGetNewUserStatistics
);
const getPageViewUserStatisticsSaga = createRequestSaga(
  getPageViewUserStatisticsAsync,
  apiGetPageViewUserStatistics
);
const getSessionUserStatisticsSaga = createRequestSaga(
  getSessionUserStatisticsAsync,
  apiGetSessionUserStatistics
);
const getPVMeanStatisticsSaga = createRequestSaga(
  getPVMeanStatisticsAsync,
  apiGetPVMeanStatistics
);
const getUVMeanStatisticsSaga = createRequestSaga(
  getUVMeanStatisticsAsync,
  apiGetUVMeanStatistics
);
const getUsageMeanStatisticsSaga = createRequestSaga(
  getUsageMeanStatisticsAsync,
  apiGetUsageMeanStatistics
);
const getDeviceStaticsSaga = createRequestSaga(
  getDeviceStaticsAsync,
  apiGetDeviceStatistics
);
const getOSStaticsSaga = createRequestSaga(
  getOSStaticsAsync,
  apiGetOSStatistics
);
const getBrowserStaticsSaga = createRequestSaga(
  getBrowserStaticsAsync,
  apiGetBrowserStatistics
);

export function* analysisSaga() {
  yield takeLatest(GET_TOTAL_USER_STATISTICS, getTotalUserStatisticsSaga);
  yield takeLatest(GET_NEW_USER_STATISTICS, getNewUserStatisticsSaga);
  yield takeLatest(
    GET_PAGE_VIEW_USER_STATISTICS,
    getPageViewUserStatisticsSaga
  );
  yield takeLatest(GET_SESSION_USER_STATISTICS, getSessionUserStatisticsSaga);
  yield takeLatest(GET_PV_MEAN_STATISTICS, getPVMeanStatisticsSaga);
  yield takeLatest(GET_UV_MEAN_STATISTICS, getUVMeanStatisticsSaga);
  yield takeLatest(GET_USAGE_MEAN_STATISTICS, getUsageMeanStatisticsSaga);
  yield takeLatest(GET_DEVICE_STATICS, getDeviceStaticsSaga);
  yield takeLatest(GET_OS_STATICS, getOSStaticsSaga);
  yield takeLatest(GET_BROWSER_STATICS, getBrowserStaticsSaga);
  yield takeLatest(GET_CURATION_STATUS, getCurationStatusSaga);
}

export { analysisSaga as default };
