import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { tooltip } from '../../../Atoms/ChartTooltip/DetailChartTooltip';

const Component = styled.div`
  width: 100%;

  .apexcharts-svg {
    overflow: initial;
  }
  ${tooltip};

  .apexcharts-bar-series {
    .apexcharts-series {
      transform: translate(-4px, 0%);
      &:last-child {
        transform: translate(4px, 0%);
      }
    }
  }
  .tooltip {
    padding-right: 20px;
  }
  .tooltip-title {
    font-size: 12px;
  }

  .tooltip-rate {
    font-size: 16px;
  }

  .val {
    display: flex;
  }

  .valWrap {
    font-size: 12px;
  }
  .custom-value {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
  }
`;

interface MultiLineChartProps {
  Data?: {
    [key: string]: {
      expose_graph: number;
      click_graph: number;
      purchase_graph: number;
      total_graph: number;
      reuse_graph: number;
    };
  } | null;
  DashboardData?: {
    [key: string]: {
      expose: number;
      click: number;
      purchase: number;
    };
  } | null;
  buttonId?: number;
  label?: string[];
  rate?: number[];
  isDashboard?: boolean;
  graphHeight: number;
  unitId?: number;
}
interface MultiLineChartState {
  seriesType:
    | {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
        }[];
      }[]
    | null;
}

const MultiLineChart = ({
  Data,
  DashboardData,
  buttonId,
  label,
  rate,
  isDashboard,
  graphHeight,
  unitId,
}: MultiLineChartProps) => {
  const ChartRef = useRef<any>(null);
  const [arr, setArr] = useState<string[]>(['']);

  const options = {
    dataLabels: {
      enabled: false,
    },
    chart: {
      animations: {
        enabled: true,
        // easing: 'easeinout',
        speed: 1,
        animateGradually: {
          enabled: true,
          delay: 50,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      height: graphHeight,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 2,
    },
    markers: {
      size: 4,
      hover: {
        size: 6,
      },
    },
    colors: ['#008ded', '#17c3b2', '#ffd34b'],
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#e0e0e0',
        width: '100%',
      },
    },
    tooltip: {},
  };

  const [optionsState, setOptions] = useState(options);

  const tempSeries = [{ name: '', type: 'line', data: [] }];

  // 리덕스 데이터 변경 시 호출 되는 function
  const updateOption = (
    series: MultiLineChartState['seriesType'],
    startIter: number,
    endIter: number
  ) => {
    const { current } = ChartRef;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        seriesData1.push(series[1].data[index]);
      }

      let updateTempSeries: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];

      updateTempSeries = [
        {
          name: series[0].name,
          type: 'line',
          data: seriesData0,
        },

        {
          name: series[1].name,
          type: 'line',
          data: seriesData1,
        },
      ];

      const tempOptions = {
        ...optionsState,
        series: updateTempSeries,

        xaxis: {
          ...optionsState.xaxis,
        },
        tooltip: {
          followCursor: true,
          custom({
            series,
            seriesIndex,
            dataPointIndex,
            w,
          }: {
            series: any;
            seriesIndex: any;
            dataPointIndex: any;
            w: any;
          }) {
            if (isDashboard) {
              const val1: number = series[0][dataPointIndex];
              const val2: number = series[1][dataPointIndex];
              const val3: number = series[2][dataPointIndex];
              const tooltipTitle = ['테스트'];

              let result = `<div class="tooltip">
              <div class="rateWrap">
                <div class="tooltip-title">${tooltipTitle[0]}</div>
              </div>
              <div class="valWrap">
                <div class="value">
                  <div class="dot-1"></div>
                  <div class="val">${
                    w.globals.seriesNames[0]
                  } : <p class="custom-value">&nbsp${val1.toLocaleString()}</p>
                  </div>
                </div>
                <div class="value">
                  <div class="dot-2"></div>
                  <div class="val">${
                    w.globals.seriesNames[1]
                  } : <p class="custom-value">&nbsp${val2.toLocaleString()}</p>
                  </div>
                </div>
                <div class="value">
                  <div class="dot-3"></div>
                  <div class="val">${
                    w.globals.seriesNames[2]
                  } : <p class="custom-value">&nbsp${val3.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
            `;

              return result;
            }
            if (buttonId && rate) {
              const val1: number = series[0][dataPointIndex];
              const val2: number = series[1][dataPointIndex];
              const tooltipTitle = [
                '노출대비 클릭율',
                '구매 전환율',
                '추천 재이용률',
              ];

              let result = `<div class="tooltip">
              <div class="rateWrap">
                <div class="tooltip-title">${tooltipTitle[buttonId - 1]}</div>
                <div class="tooltip-rate">${rate[buttonId - 1]}%</div>
              </div>
              <div class="valWrap">
                <div class="value">
                  <div class="dot-1"></div>
                  <div class="val">${
                    w.globals.seriesNames[0]
                  } : <p class="custom-value">&nbsp${val1.toLocaleString()}</p>
                  </div>
                </div>
                <div class="value">
                  <div class="dot-2"></div>
                  <div class="val">${
                    w.globals.seriesNames[1]
                  } : <p class="custom-value">&nbsp${val2.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
            `;

              return result;
            }
          },
        },
      };

      current.chart.updateOptions(tempOptions);
    }

    if (isDashboard && series && current) {
      const seriesData0: {
        x: string;
        y: number;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
      }[] = [];
      // const seriesData2: {
      //   x: string;
      //   y: number;
      // }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        seriesData1.push(series[1].data[index]);
        // seriesData2.push(series[2].data[index]);
      }

      let updateTempSeries: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];

      updateTempSeries = [
        {
          name: series[0].name,
          type: 'line',
          data: seriesData0,
        },

        {
          name: series[1].name,
          type: 'line',
          data: seriesData1,
        },

        // {
        //   name: series[2].name,
        //   type: 'line',
        //   data: seriesData2,
        // },
      ];

      const tempOptions = {
        ...optionsState,
        series: updateTempSeries,

        xaxis: {
          ...optionsState.xaxis,
        },
        tooltip: {
          custom({
            series,
            seriesIndex,
            dataPointIndex,
            w,
          }: {
            series: any;
            seriesIndex: any;
            dataPointIndex: any;
            w: any;
          }) {
            if (isDashboard) {
              const val1: number = series[0][dataPointIndex];
              const val2: number = series[1][dataPointIndex];
              // const val3: number = series[2][dataPointIndex];

              let titleArrtest: string[] = [];
              arr.forEach((element) => {
                const each = element.split('-');
                switch (unitId) {
                  case 0:
                    titleArrtest.push(
                      each[0] + '년 ' + each[1] + '월 ' + each[2] + '일'
                    );
                    break;
                  case 1:
                    titleArrtest.push(
                      each[0] + '년 ' + each[1] + '월 ' + each[2] + '주차'
                    );
                    break;
                  case 2:
                    titleArrtest.push(each[0] + '년 ' + each[1] + '월');
                    break;

                  default:
                    break;
                }
              });

              let result = `<div class="tooltip">
              <div class="rateWrap">
                <div class="tooltip-title">${titleArrtest[dataPointIndex]}</div>
              </div>
              <div class="valWrap">
                <div class="value">
                  <div class="dot-1"></div>
                  <div class="val">${
                    w.globals.seriesNames[0]
                  } : <p class="custom-value">&nbsp${val1.toLocaleString()}</p>
                  </div>
                </div>
                <div class="value">
                  <div class="dot-2"></div>
                  <div class="val">${
                    w.globals.seriesNames[1]
                  } : <p class="custom-value">&nbsp${val2.toLocaleString()}</p>
                  </div>
                </div>
                
              </div>
            </div>
            `;

              return result;
            }
            if (buttonId && rate) {
              const val1: number = series[0][dataPointIndex];
              const val2: number = series[1][dataPointIndex];
              const tooltipTitle = [
                '노출대비 클릭율',
                '구매 전환율',
                '추천 재이용률',
              ];

              let result = `<div class="tooltip">
              <div class="rateWrap">
                <div class="tooltip-title">${tooltipTitle[buttonId - 1]}</div>
                <div class="tooltip-rate">${rate[buttonId - 1]}%</div>
              </div>
              <div class="valWrap">
                <div class="value">
                  <div class="dot-1"></div>
                  <div class="val">${
                    w.globals.seriesNames[0]
                  } : <p class="custom-value">&nbsp${val1.toLocaleString()}</p>
                  </div>
                </div>
                <div class="value">
                  <div class="dot-2"></div>
                  <div class="val">${
                    w.globals.seriesNames[1]
                  } : <p class="custom-value">&nbsp${val2.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
            `;

              return result;
            }
          },
        },
      };

      current.chart.updateOptions(tempOptions);
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const rebuildData = () => {
    const { current } = ChartRef;
    if (Data && current) {
      const categoryArr = Object.keys(Data);
      const lineData: {
        x: string;
        y: number;
      }[] = [];
      const barData1: {
        x: string;
        y: number;
      }[] = [];

      let series: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];

      switch (buttonId) {
        // Click Rate
        case 1:
          categoryArr.forEach((element) => {
            lineData.push({
              x: element,
              y: Data[element].expose_graph,
            });
            barData1.push({
              x: element,
              y: Data[element].click_graph,
            });
          });
          if (label) {
            series = [
              { name: label[0], type: 'line', data: lineData },
              { name: label[1], type: 'line', data: barData1 },
            ];
          }
          break;
        // Purchase Rate
        case 2:
          categoryArr.forEach((element) => {
            lineData.push({
              x: element,
              y: Data[element].click_graph,
            });
            barData1.push({
              x: element,
              y: Data[element].purchase_graph,
            });
          });
          if (label) {
            series = [
              { name: label[0], type: 'line', data: lineData },
              { name: label[1], type: 'line', data: barData1 },
            ];
          }
          break;
        // Reuse Rate
        case 3:
          categoryArr.forEach((element) => {
            lineData.push({
              x: element,
              y: Data[element].total_graph,
            });
            barData1.push({
              x: element,
              y: Data[element].reuse_graph,
            });
          });
          if (label) {
            series = [
              { name: label[0], type: 'line', data: lineData },
              { name: label[1], type: 'line', data: barData1 },
            ];
          }
          break;

        default:
          break;
      }

      let startIterTemp = 0;
      let endIterTemp = series[0].data.length - 1;

      updateOption(series, startIterTemp, endIterTemp);
    }
    if (DashboardData && current) {
      const categoryArr = Object.keys(DashboardData);
      // const lineData: {
      //   x: string;
      //   y: number;
      // }[] = [];
      const barData1: {
        x: string;
        y: number;
      }[] = [];
      const barData2: {
        x: string;
        y: number;
      }[] = [];

      let series: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];

      const rebCaArr = categoryArr.map((element) => {
        let sliceArr = element.substring(5).split('-');
        let rebuild = sliceArr.map((el) => {
          if (el[0] === '0') {
            return el[1];
          }
          return el;
        });

        return rebuild.join('/');
      });

      if (unitId) {
        switch (unitId) {
          case 1:
            categoryArr.forEach((element, idx) => {
              let week = rebCaArr[idx].split('/');
              // lineData.push({
              //   x: week[0] + '월 ' + week[1] + '주차',
              //   y: DashboardData[element].expose,
              // });
              barData1.push({
                x: week[0] + '월 ' + week[1] + '주차',
                y: DashboardData[element].click,
              });
              barData2.push({
                x: week[0] + '월 ' + week[1] + '주차',
                y: DashboardData[element].purchase,
              });
            });
            break;
          case 2:
            categoryArr.forEach((element, idx) => {
              let week = rebCaArr[idx].split('/');
              // lineData.push({
              //   x: week[0] + '월 ',
              //   y: DashboardData[element].expose,
              // });
              barData1.push({
                x: week[0] + '월 ',
                y: DashboardData[element].click,
              });
              barData2.push({
                x: week[0] + '월 ',
                y: DashboardData[element].purchase,
              });
            });
            break;

          default:
            categoryArr.forEach((element, idx) => {
              // lineData.push({
              //   x: rebCaArr[idx],
              //   y: DashboardData[element].expose,
              // });
              barData1.push({
                x: rebCaArr[idx],
                y: DashboardData[element].click,
              });
              barData2.push({
                x: rebCaArr[idx],
                y: DashboardData[element].purchase,
              });
            });
            break;
        }
      } else {
        categoryArr.forEach((element, idx) => {
          // lineData.push({
          //   x: rebCaArr[idx],
          //   y: DashboardData[element].expose,
          // });
          barData1.push({
            x: rebCaArr[idx],
            y: DashboardData[element].click,
          });
          barData2.push({
            x: rebCaArr[idx],
            y: DashboardData[element].purchase,
          });
        });
      }
      if (label) {
        series = [
          // { name: label[0], type: 'line', data: lineData },
          { name: label[1], type: 'line', data: barData1 },
          { name: label[2], type: 'line', data: barData2 },
        ];
      }

      let startIterTemp = 0;
      let endIterTemp = series[0].data.length - 1;

      updateOption(series, startIterTemp, endIterTemp);
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  useEffect(() => {
    if (Data) {
      rebuildData();
    }
    if (DashboardData) {
      rebuildData();
    }
  }, [Data, buttonId, label, rate, DashboardData]);

  useEffect(() => {
    if (DashboardData) {
      const categoryArr = Object.keys(DashboardData);
      setArr(categoryArr);
    }
  }, [DashboardData]);

  return (
    <Component>
      <Chart
        type="line"
        series={tempSeries}
        options={optionsState}
        height={200}
        width="100%"
        ref={ChartRef}
      />
    </Component>
  );
};

export default MultiLineChart;
