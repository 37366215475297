import React, { useState } from 'react';
import styled from 'styled-components';

import DataError from '../../Atoms/DataError';
import { IconInform } from '../../Atoms/Icon';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H7 } from '../../Atoms/Typo';
import ButtonGroup from '../../Molecule/ButtonGroup';
import Spinner from '../../Molecule/Spinner';
import TextTooltip from '../../Molecule/TextTooltip';
import SegmentProgress from '../SegmentProgress';

const Conponent = styled(CardLayout)`
  padding: 24px 24px 24px 30px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  ${H7} {
    color: ${(props) => props.theme.colors.black};
  }
`;

interface DashboardUsageCardProps {
  isLoading?: boolean;
  activeTabID: number;
  usageData:
    | {
        name: string;
        id: number;
        rate: number;
      }[]
    | null;
  onClickButtonGroup: (id: number) => void;
}

const DashboardUsageCard = ({
  isLoading,
  activeTabID,
  usageData,
  onClickButtonGroup,
}: DashboardUsageCardProps) => {
  const [titleTooltip, setTitleTooltip] = useState(false);

  const onEnterTitle = () => {
    setTitleTooltip(true);
  };
  const onLeaveTitle = () => {
    setTitleTooltip(false);
  };

  return (
    <Conponent>
      <CardHeaderLayout>
        <Title>
          <H7>고객군 별 추천 이용률</H7>
          <IconInform onMouseEnter={onEnterTitle} onMouseLeave={onLeaveTitle} />
          <TextTooltip
            isShow={titleTooltip}
            title
            text="고객군 별 추천 이용률입니다"
          />
        </Title>
        <ButtonGroup
          buttonData={[
            {
              id: 0,
              title: '어제',
            },
            {
              id: 1,
              title: '지난주',
            },
            {
              id: 2,
              title: '지난달',
            },
          ]}
          activeID={activeTabID}
          onClick={onClickButtonGroup}
        />
      </CardHeaderLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          {usageData ? (
            usageData.map((element) => {
              return (
                <SegmentProgress
                  key={element.id}
                  segid={element.id}
                  rate={element.rate}
                  name={element.name}
                />
              );
            })
          ) : (
            <DataError />
          )}
        </CardBodyLayout>
      )}
    </Conponent>
  );
};

export default DashboardUsageCard;
