import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import {
  GetManageRecommendDetailParamType,
  GetManageRecommendGraphParamType,
  SetRecommendNameParamType,
  GetAlgorithmCheckParamType,
  DeleteRecommendParamType,
} from '../../api/management/type';
import {
  getRecommendDetailAsync,
  getRecommendGraphAsync,
  setRecommendNameAsync,
  getAlgorithmCheckAsync,
  deleteRecommendAsync,
} from '../../modules/management';
import { SetManageRecommendStateParamType } from '../../api/register/type';
import { setRecommendStateAsync } from '../../modules/register';

// components
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import SettingNameCard from '../../components/Organisms/Card/SettingNameCard';
import BasicInformCard from '../../components/Organisms/Card/BasicInformCard';
import GraphWrapperCard from '../../components/Organisms/Card/GraphWrapperCard';
import DetailHeader from '../../components/Organisms/DetailHeader';
import NoDataCard from '../../components/Organisms/Card/NoDataCard';
import Modal from '../../components/Molecule/Modal';
import Spinner from '../../components/Molecule/Spinner';

interface CurationDetailProps extends RouteComponentProps {}

const CurationDetail = ({ location }: CurationDetailProps) => {
  const history = useHistory();

  // state
  const [title, setTitle] = useState('');
  const [exposeTitle, setExposeTitle] = useState('');
  const [algorithmTitle, setAlgorithmTitle] = useState('');
  const [state, setChangeState] = useState<number>(-1);
  const [cardState, setCardState] = useState<number>();
  const [startDate, setStartDate] = useState('');
  const [ak, setAk] = useState('');
  const [date, setDate] = useState('');
  const [reload, setReload] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const [endModal, setEndModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);

  const dispatch = useDispatch();
  const {
    isDetailLoading,
    isGraphLoading,
    isDeleteLoading,
    isItemListLoading,

    recomDetailData,
    recomGraphData,
    setNameData,
    recommendItemList,
    deleteStatus, // delete 여부
    isDeleteSuccess, // delete suceess 여부
  } = useSelector((state: RootState) => state.management);
  const { isStateLoading, setState } = useSelector(
    (state: RootState) => state.register
  );

  const onGetRecommendDetail = (info: GetManageRecommendDetailParamType) => {
    dispatch(getRecommendDetailAsync.request(info));
  };
  const onGetRecommendGraph = (info: GetManageRecommendGraphParamType) => {
    dispatch(getRecommendGraphAsync.request(info));
  };
  const onSetRecommendName = (info: SetRecommendNameParamType) => {
    dispatch(setRecommendNameAsync.request(info));
  };
  const onGetAlgorithmCheck = (info: GetAlgorithmCheckParamType) => {
    dispatch(getAlgorithmCheckAsync.request(info));
  };
  const onSetRecommendState = (info: SetManageRecommendStateParamType) => {
    dispatch(setRecommendStateAsync.request(info));
  };
  const onDeleteRecommend = (info: DeleteRecommendParamType) => {
    dispatch(deleteRecommendAsync.request(info));
  };

  useEffect(() => {
    const params: { [key: string]: string } = {};
    location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      (str: string, key: string, value: string) => {
        params[key] = value;
        return value;
      }
    );
    onGetRecommendDetail({
      ak: params.ak,
      dt: params.datetime,
    });
    onGetRecommendGraph({
      ak: params.ak,
      dt: params.datetime,
    });
    onGetAlgorithmCheck({
      ak: params.ak,
      dt: params.datetime,
    });
    setTitle(decodeURI(params.title));
    setAk(params.ak);
    setDate(params.datetime);
  }, []);

  const onClickSave = (name: string, type: number) => {
    onSetRecommendName({
      name: name,
      ak: ak,
      type: type,
    });
    if (type === 1) {
      setTitle(name);
    } else {
      setExposeTitle(name);
    }
  };

  // State Modal
  const ModalClose = () => {
    setModalOpen(false);
    setEndModal(false);
    setOpenDelModal(false);
  };
  const ModalConfirm = (state: number) => {
    setCardState(state);
    onSetRecommendState({
      key: ak,
      state: state,
    });
    setModalOpen(false);
    setEndModal(false);
    setConfirm((prev) => {
      return !prev;
    });
  };

  const onChangeState = (val: number) => {
    setChangeState(val);
    switch (val) {
      case 1:
        setModalOpen(true);
        break;
      case 2:
        setEndModal(true);
        break;

      default:
        break;
    }
  };

  const onClickDelete = (val: boolean) => {
    setOpenDelModal(val);
  };

  const DeleteConfirm = () => {
    setOpenDelModal(false);
    // delete API
    onDeleteRecommend({
      ak: ak,
    });
    setReload((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    if (isDeleteSuccess && reload) {
      history.replace('/curation');
    }
  }, [isDeleteSuccess, reload, history]);

  useEffect(() => {
    if (isStateLoading && confirm) {
      onGetRecommendDetail({
        ak: ak,
        dt: date,
      });
    }
  }, [isStateLoading, confirm]);

  useEffect(() => {
    if (recomDetailData) {
      setExposeTitle(recomDetailData.recommend_name);
      setAlgorithmTitle(recomDetailData.algorithm);
      setChangeState(recomDetailData.state);
      setCardState(recomDetailData.state);
      setStartDate(recomDetailData.start_datetime);
    }
  }, [recomDetailData]);

  useEffect(() => {
    if (setNameData || setState) {
      history.replace(
        `/curation/detail?ak=${ak}&datetime=${date}&title=${title}`
      );
    }
  }, [setNameData, setState, date, ak, title, history]);

  return (
    <ContentsLayout>
      <Modal
        isOpen={modalOpen || endModal}
        isPublic={true}
        message={
          modalOpen
            ? '추천을 진행할까요?\n상태 변경시 데이터를 확인할 수 있습니다.'
            : '추천을 종료할까요?\n상태 변경 즉시 추천이 노출되지 않습니다.'
        }
        closeBtnName="취소"
        confirmBtnName="확인"
        handleClose={ModalClose}
        handleConfirm={() => ModalConfirm(state)}
      />
      <Modal
        isOpen={openDelModal}
        isPublic={true}
        message="추천을 삭제할까요?"
        isDelete
        closeBtnName="취소"
        confirmBtnName="삭제"
        handleClose={ModalClose}
        handleConfirm={DeleteConfirm}
      />
      <DetailHeader title={title} />
      {isDeleteLoading ? (
        <Spinner />
      ) : (
        <ContentBodyLayout detailPage>
          <ContentsItemLayout desktop={8}>
            <SettingNameCard
              title={title}
              state={state}
              exposeName={exposeTitle}
              onSave={onClickSave}
            />
          </ContentsItemLayout>
          <ContentsItemLayout desktop={4}>
            <BasicInformCard
              isloading={isDetailLoading}
              detailData={recomDetailData}
              changeState={onChangeState}
              onClickDel={onClickDelete}
            />
          </ContentsItemLayout>
          {cardState === 0 ? (
            <ContentsItemLayout desktop={8}>
              <NoDataCard />
            </ContentsItemLayout>
          ) : isGraphLoading ? (
            <Spinner />
          ) : (
            recomGraphData &&
            recomGraphData.map((element) => {
              const val = Object.values(element)[0];
              return (
                <ContentsItemLayout key={Object.keys(element)[0]} desktop={8}>
                  <GraphWrapperCard
                    isLoadingItemList={isItemListLoading}
                    graphTitle={Object.keys(element)[0]}
                    segmentId={val.segment_id}
                    algorithmTitle={algorithmTitle}
                    state={state}
                    startDate={startDate}
                    chartData={val.graph_data}
                    clickRate={val.click_per_expose}
                    conversionRate={val.purchase_conversion}
                    reuseRate={val.reuse_per_total}
                    itemList={recommendItemList}
                  />
                </ContentsItemLayout>
              );
            })
          )}
        </ContentBodyLayout>
      )}
    </ContentsLayout>
  );
};

export default withRouter(CurationDetail);
