import React, {
  ReactNodeArray,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import styled from 'styled-components';

import TableBody from '../../Atoms/TableBody';
import TableCell from '../../Atoms/TableCell';
import TableHead from '../../Atoms/TableHead';
import { TableRow, TableSubRow } from '../../Atoms/TableRow';
import { P3 } from '../../Atoms/Typo';

const Component = styled.table`
  width: 100%;
`;

export interface TableProps {
  columns: {
    title: string;
    id: string;
    rowspan?: number;
    colspan?: number;
    isAsc?: boolean;
    isSorting: boolean;
    subColumns?: {
      title: string;
      id: string;
      isSorting: boolean;
    }[];
  }[];

  data: {
    id: string;
    [key: string]: React.ReactNode | { [key: string]: React.ReactNode };
    onClick?: (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
    ) => void;
    isHighlignt?: boolean;
    ak: string;
    stateNum: number;
  }[];
  children?: React.ReactNode;
  isOpen: boolean;
}

const Table = ({ columns, data, children, isOpen }: TableProps) => {
  const [rebuildData, setRebuildData] = useState<TableProps['data']>([]);
  const [filterID, setFilterID] = useState<{
    id: string;
    sub_id: string | null;
    isAsc: boolean;
  }>({
    id: '',
    sub_id: null,
    isAsc: true,
  });

  const [rowWidth, setRowWidth] = useState(0);

  const rowEl = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const current = rowEl.current;
    if (current) {
      setRowWidth(current.scrollWidth);
    }
  }, [rowEl.current]);

  const getInnerText = (obj: React.ReactNode) => {
    let buf = '';

    if (obj) {
      const type = typeof obj;

      if (type === 'string' || type === 'number') {
        buf += obj;
      } else if (type === 'object') {
        let childrens = null;
        if (Array.isArray(obj)) {
          childrens = obj;
        } else {
          const { props } = obj as React.ReactPortal;

          if (props) {
            childrens = props.children;
          }
        }

        if (childrens) {
          if (Array.isArray(childrens)) {
            childrens.forEach((element) => {
              buf += getInnerText(element);
            });
          } else {
            buf += getInnerText(childrens);
          }
        }
      }
    }
    return buf;
  };

  const sort = (filter_info: {
    id: string;
    sub_id: string | null;
    isAsc: boolean;
  }) => {
    const isNumber = (val: string) => {
      return /^-?\d+$/.test(val);
    };
    const numberSort = (a: number, b: number) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    };

    const stringSort = (a: string, b: string) => {
      const A = a.toUpperCase(); // ignore upper and lowercase
      const B = b.toUpperCase(); // ignore upper and lowercase
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }

      // 이름이 같을 경우
      return 0;
    };
    const temp_data = [...data];
    if (filter_info.sub_id) {
      temp_data.sort((a, b) => {
        const node_a = a[filter_info.id] as {
          [key: string]: React.ReactNode;
        };
        const node_b = b[filter_info.id] as {
          [key: string]: React.ReactNode;
        };

        let value_a = '';
        let value_b = '';
        if (filter_info.sub_id && node_a && filter_info.sub_id in node_a) {
          value_a = getInnerText(node_a[filter_info.sub_id]);
        }
        if (filter_info.sub_id && node_b && filter_info.sub_id in node_b) {
          value_b = getInnerText(node_b[filter_info.sub_id]);
        }

        if (isNumber(value_a) && isNumber(value_b)) {
          return numberSort(Number(value_a), Number(value_b));
        }
        return stringSort(value_a, value_b);
      });
    } else {
      temp_data.sort((a, b) => {
        const node_a = a[filter_info.id] as React.ReactNode;
        const node_b = b[filter_info.id] as React.ReactNode;

        let value_a = '';
        let value_b = '';
        value_a = getInnerText(node_a);
        value_b = getInnerText(node_b);

        if (isNumber(value_a) && isNumber(value_b)) {
          return numberSort(Number(value_a), Number(value_b));
        }
        return stringSort(value_a, value_b);
      });
    }

    if (filter_info.isAsc) {
      setRebuildData(temp_data);
    } else {
      setRebuildData(temp_data.reverse());
    }
  };

  const onClickSort = (id: string) => {
    const result_filter_arr = columns.filter((element) => element.id === id);
    let sub_filter_id: string | null = null;
    if (result_filter_arr[0].subColumns) {
      const sub_filter_ob = result_filter_arr[0].subColumns.find(
        (element) => element.isSorting === true
      );

      sub_filter_id = sub_filter_ob?.id || null;
    }

    setFilterID((prev) => {
      sort({
        id,
        sub_id: sub_filter_id,
        isAsc: !prev.isAsc,
      });
      return {
        id,
        sub_id: sub_filter_id,
        isAsc: !prev.isAsc,
      };
    });
  };

  const printRow = useCallback(
    (index: number) => {
      const result: ReactNodeArray = [];

      columns.forEach((element) => {
        if (element.subColumns) {
          const sub_result: ReactNodeArray = [];
          element.subColumns.forEach((sub_element) => {
            const ob = rebuildData[index][element.id] as {
              [key: string]: React.ReactNode;
            };

            const { id } = sub_element;
            if (
              typeof ob === 'object' &&
              !React.isValidElement(ob) &&
              ob !== null &&
              id in ob
            ) {
              sub_result.push(
                <TableSubRow key={sub_element.id}>
                  <TableCell isHeading={false}>{ob[id]}</TableCell>
                </TableSubRow>
              );
            }
          });
          result.push(
            <td key={element.id}>
              <Component>
                <tbody>{sub_result}</tbody>
              </Component>
            </td>
          );
        } else {
          result.push(
            <TableCell
              isHeading={false}
              rowspan={element.rowspan}
              colspan={element.colspan}
              key={element.id}
            >
              {rebuildData[index][element.id]}
            </TableCell>
          );
        }
      });
      return result;
    },
    [rebuildData]
  );

  const printBody = useMemo(() => {
    return rebuildData.map((element, index) => {
      return (
        <React.Fragment key={element.ak}>
          <TableRow ref={rowEl} onClick={element.onClick} key={element.id}>
            {printRow(index)}
          </TableRow>
        </React.Fragment>
      );
    });
  }, [rebuildData, isOpen, rowWidth, rowEl]);

  useEffect(() => {
    const result_filter_arr = columns.filter(
      (element) => element.isSorting === true
    );

    if (result_filter_arr[0]) {
      let sub_filter_id: string | null = null;
      if (result_filter_arr[0].subColumns) {
        const sub_filter_ob = result_filter_arr[0].subColumns.find(
          (element) => element.isSorting === true
        );

        sub_filter_id = sub_filter_ob?.id || null;
      }

      const { isAsc } = result_filter_arr[0];

      setFilterID({
        id: result_filter_arr[0].id,
        sub_id: sub_filter_id,
        isAsc: isAsc !== undefined ? isAsc : true,
      });

      sort({
        id: result_filter_arr[0].id,
        sub_id: sub_filter_id,
        isAsc: isAsc !== undefined ? isAsc : true,
      });
    } else {
      setRebuildData(data);
    }
  }, [columns, data]);

  return (
    <Component>
      <TableHead>
        {columns.map((element) => {
          return (
            <TableCell
              key={element.id}
              isHeading
              isAsc={filterID.id === element.id ? filterID.isAsc : undefined}
              isSorting={element.isSorting}
              onClickTh={
                element.isSorting
                  ? () => {
                      onClickSort(element.id);
                    }
                  : undefined
              }
            >
              <P3>{element.title}</P3>
            </TableCell>
          );
        })}
      </TableHead>
      <TableBody>{printBody}</TableBody>
    </Component>
  );
};

export default Table;
