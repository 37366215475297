import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { IconLeftArrow, IconRightArrow } from '../../Atoms/Icon';
import { P2 } from '../../Atoms/Typo';

const Container = styled.div`
  width: 100%;
  .react-datepicker__input-container {
    input {
      padding: 0 10px;
      caret-color: transparent;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .not-present-day {
    color: ${(props) => props.theme.colors.grey500};
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
  }
  .react-datepicker__day--in-range {
    background-color: ${(props) => props.theme.colors.blue200};
    color: white;
  }
  .react-datepicker__day--range-start {
    color: white;
  }
  .react-datepicker__day--selecting-range-start {
    background-color: ${(props) => props.theme.colors.blue200};
    color: white;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: ${(props) => props.theme.colors.blue100};
  }
  .react-datepicker__day {
    border-radius: 50%;
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors.grey300};
  }
`;

const Calendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
  }
`;

const Component = styled(DatePicker)`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.colors.grey400};
  .react-datepicker__input-container {
    padding: 0 10px;
  }
`;

interface DefDatePickerProps {
  settingDate: (date: any) => void;
  children?: React.ReactNode;
}

const DefDatePicker = ({ settingDate, children }: DefDatePickerProps) => {
  const today = new Date();
  const [dateRange, setDateRange] = useState([]);
  //   const [dateRange, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRange;
  const chageDate = (date: any) => {
    setDateRange(date);
    settingDate(date);
  };

  const formatDate = (d: Date): string => {
    const date = new Date(d);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}년 ${`0${monthIndex}`.slice(-2)}월`;
  };

  const [month, setMonth] = useState(new Date().getMonth());

  const handleMonthChange = (date: Date) => {
    setMonth(date.getMonth());
  };

  return (
    <Container>
      <Component
        locale={ko}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        placeholderText="날짜 선택"
        onChange={chageDate}
        dateFormat="yyyy-MM-dd"
        maxDate={today}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <Calendar>
            <IconLeftArrow onClick={decreaseMonth} />
            <P2>{formatDate(date)}</P2>
            <IconRightArrow onClick={increaseMonth} />
          </Calendar>
        )}
        onMonthChange={handleMonthChange}
        dayClassName={(d) =>
          d.getMonth() === month ? 'present-day' : 'not-present-day'
        }
      >
        {children}
      </Component>
    </Container>
  );
};

export default DefDatePicker;
