import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';

import {
  GetDashboardAccumulateParamType,
  GetDashboardAccumulateResponseType,
  GetDashboardProgressParamType,
  GetDashboardProgressResponseType,
  GetDashboardEndedParamType,
  GetDashboardEndedResponseType,
  GetSegmentStatusParamType,
  GetSegmentStatusResponseType,
  GetUsageSegmentParamType,
  GetUsageSegmentResponseType,
  GetRecommendTotalGraphParamType,
  GetRecommendTotalGraphResponseType,
} from '../../api/dashboard/type';

// 누적 개인화 추천수
export const GET_DASHBOARD_ACCUMULATE =
  'dashboard/GET_DASHBOARD_ACCUMULATE' as const;
export const GET_DASHBOARD_ACCUMULATE_SUCCESS =
  'dashboard/GET_DASHBOARD_ACCUMULATE_SUCCESS' as const;
export const GET_DASHBOARD_ACCUMULATE_ERROR =
  'dashboard/GET_DASHBOARD_ACCUMULATE_ERROR' as const;

export const getAccumulateAsync = createAsyncAction(
  GET_DASHBOARD_ACCUMULATE,
  GET_DASHBOARD_ACCUMULATE_SUCCESS,
  GET_DASHBOARD_ACCUMULATE_ERROR
)<
  GetDashboardAccumulateParamType,
  GetDashboardAccumulateResponseType,
  AxiosErrorType
>();

// 진행 개인화 추천수
export const GET_DASHBOARD_PROGRESS =
  'dashboard/GET_DASHBOARD_PROGRESS' as const;
export const GET_DASHBOARD_PROGRESS_SUCCESS =
  'dashboard/GET_DASHBOARD_PROGRESS_SUCCESS' as const;
export const GET_DASHBOARD_PROGRESS_ERROR =
  'dashboard/GET_DASHBOARD_PROGRESS_ERROR' as const;

export const getProgressAsync = createAsyncAction(
  GET_DASHBOARD_PROGRESS,
  GET_DASHBOARD_PROGRESS_SUCCESS,
  GET_DASHBOARD_PROGRESS_ERROR
)<
  GetDashboardProgressParamType,
  GetDashboardProgressResponseType,
  AxiosErrorType
>();

// 종료 개인화 추천수
export const GET_DASHBOARD_ENDED = 'dashboard/GET_DASHBOARD_ENDED' as const;
export const GET_DASHBOARD_ENDED_SUCCESS =
  'dashboard/GET_DASHBOARD_ENDED_SUCCESS' as const;
export const GET_DASHBOARD_ENDED_ERROR =
  'dashboard/GET_DASHBOARD_ENDED_ERROR' as const;

export const getEndedAsync = createAsyncAction(
  GET_DASHBOARD_ENDED,
  GET_DASHBOARD_ENDED_SUCCESS,
  GET_DASHBOARD_ENDED_ERROR
)<GetDashboardEndedParamType, GetDashboardEndedResponseType, AxiosErrorType>();

// 고객군 현황
export const GET_SEGMENT_STATUS = 'dashboard/GET_SEGMENT_STATUS' as const;
export const GET_SEGMENT_STATUS_SUCCESS =
  'dashboard/GET_SEGMENT_STATUS_SUCCESS' as const;
export const GET_SEGMENT_STATUS_ERROR =
  'dashboard/GET_SEGMENT_STATUS_ERROR' as const;

export const getSegmentStatusAsync = createAsyncAction(
  GET_SEGMENT_STATUS,
  GET_SEGMENT_STATUS_SUCCESS,
  GET_SEGMENT_STATUS_ERROR
)<GetSegmentStatusParamType, GetSegmentStatusResponseType, AxiosErrorType>();

// 고객군 별 추천 이용률
export const GET_USAGE_SEGMENT = 'dashboard/GET_USAGE_SEGMENT' as const;
export const GET_USAGE_SEGMENT_SUCCESS =
  'dashboard/GET_USAGE_SEGMENT_SUCCESS' as const;
export const GET_USAGE_SEGMENT_ERROR =
  'dashboard/GET_USAGE_SEGMENT_ERROR' as const;

export const getUsageSegmentAsync = createAsyncAction(
  GET_USAGE_SEGMENT,
  GET_USAGE_SEGMENT_SUCCESS,
  GET_USAGE_SEGMENT_ERROR
)<GetUsageSegmentParamType, GetUsageSegmentResponseType, AxiosErrorType>();

// 추천 데이터 그래프
export const GET_RECOMMEND_TOTAL_GRAPH =
  'dashboard/GET_RECOMMEND_TOTAL_GRAPH' as const;
export const GET_RECOMMEND_TOTAL_GRAPH_SUCCESS =
  'dashboard/GET_RECOMMEND_TOTAL_GRAPH_SUCCESS' as const;
export const GET_RECOMMEND_TOTAL_GRAPH_ERROR =
  'dashboard/GET_RECOMMEND_TOTAL_GRAPH_ERROR' as const;

export const getRecommendTotalGraphAsync = createAsyncAction(
  GET_RECOMMEND_TOTAL_GRAPH,
  GET_RECOMMEND_TOTAL_GRAPH_SUCCESS,
  GET_RECOMMEND_TOTAL_GRAPH_ERROR
)<
  GetRecommendTotalGraphParamType,
  GetRecommendTotalGraphResponseType,
  AxiosErrorType
>();

export const SET_ERROR_NULL_DASHBOARD = 'dashboard/SET_ERROR_NULL_DASHBOARD';

export const setErrorNullDashboard = createAction(SET_ERROR_NULL_DASHBOARD)();
