import styled from 'styled-components';
// import { Grid } from '@material-ui/core';

export const PublicRouteGrid = styled.div`
  width: 100%;
  min-width: 1200px;
  overflow: hidden;
  display: flex;
  /* align-items: center; */
  /* max-height: 100vh; */
  height: 100vh;
  min-height: 900px;

  /* padding: 100px 0; */
  .preloaderWrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;
    z-index: 11111;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #198ded;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`;

export { PublicRouteGrid as default };
