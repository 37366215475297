import axios from 'axios';

import config from '../config-api.json';
import {
  GetStatisticsParamType,
  GetPVMeanStatisticsParamType,
  GetUVMeanStatisticsParamType,
  GetUsageMeanStatisticsParamType,
  GetStatisticsResponseType,
  GetPVMeanStatisticsResponseType,
  GetUVMeanStatisticsResponseType,
  GetUsageMeanStatisticsResponseType,
  GetBrowserStaticsResponseType,
  GetDeviceStaticsResponseType,
  GetOSStaticsResponseType,
  GetCurationStatusParamType,
  GetCurationStatusResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetCurationStatus(info: GetCurationStatusParamType) {
  const response = await axios.post<GetCurationStatusResponseType>(
    `${apiInfo.api_url}/analysis/one_q/`,
    // `http://10.100.101.210:10030/api/analysis/one_q`,
    {
      segment_id: info.segment_id,
      datetime: info.dateTime,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetTotalUserStatistics(info: GetStatisticsParamType) {
  const response = await axios.get<GetStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/total_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNewUserStatistics(info: GetStatisticsParamType) {
  const response = await axios.get<GetStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/new_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPageViewUserStatistics(
  info: GetStatisticsParamType
) {
  const response = await axios.get<GetStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/page_view_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSessionUserStatistics(
  info: GetStatisticsParamType
) {
  const response = await axios.get<GetStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/session_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPVMeanStatistics(
  info: GetPVMeanStatisticsParamType
) {
  const response = await axios.get<GetPVMeanStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/pv_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetUVMeanStatistics(
  info: GetUVMeanStatisticsParamType
) {
  const response = await axios.get<GetUVMeanStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/uv_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetUsageMeanStatistics(
  info: GetUsageMeanStatisticsParamType
) {
  const response = await axios.get<GetUsageMeanStatisticsResponseType>(
    `${apiInfo.api_url}/analysis/usage_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
export async function apiGetDeviceStatistics() {
  const response = await axios.get<GetDeviceStaticsResponseType>(
    `${apiInfo.api_url}/analysis/national_device_env_statics/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
export async function apiGetOSStatistics() {
  const response = await axios.get<GetOSStaticsResponseType>(
    `${apiInfo.api_url}/analysis/national_os_env_statics/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
export async function apiGetBrowserStatistics() {
  const response = await axios.get<GetBrowserStaticsResponseType>(
    `${apiInfo.api_url}/analysis/national_browser_env_statics/`,
    {
      params: {},
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
