import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as LogoSignatureSVG } from '../../assets/icons/logo-signature.svg';
import { ReactComponent as IconCheckCircleSVG } from '../../assets/icons/icon-check-circle.svg';
import { ReactComponent as IconErrorSVG } from '../../assets/icons/icon-error.svg';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import { P2, P3, P4 } from '../../components/Atoms/Typo';

export const IconCheckCircle = styled(IconCheckCircleSVG)`
  width: 16px;
  height: 16px;
  path {
    fill: ${(props) => props.theme.colors.success5};
  }
`;
export const IconError = styled(IconErrorSVG)`
  position: absolute;
  top: 2px;
  left: -24px;
  width: 16px;
  height: 16px;
  path {
    fill: ${(props) => props.theme.colors.danger5};
  }
`;
export const IconLogoSignature = styled(LogoSignatureSVG)``;

const sliderInFromLeft = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
`;
const sliderOutFromLeft = keyframes`
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
`;
const sliderInFromRight = keyframes`
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
`;
const sliderOutFromRight = keyframes`
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
`;

export const Component = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoginContainerGrid = styled(ContentBodyLayout)`
  height: 100%;
  padding: 0px;
  margin: 0px;
  width: 100%;
`;

export const LoginItemGrid = styled(ContentsItemLayout)`
  height: 100%;
  padding: 0px !important;
`;

export const LoginImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey0};
  display: flex;
  flex-direction: column;

  flex-wrap: nowrap;
`;

export const LoginVodaTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
`;

export const LoginVodaMainImage = styled.div`
  position: relative;
  flex: 1 0 auto;
  max-height: 623px;
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const LoginVodaMainImageAbsolute = styled.div<{
  $id: number;
  $isSelect: number | null;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  ${(props) => {
    if (props.$id === props.$isSelect) {
      if (props.$id === 0) {
        return css`
          animation-name: ${sliderInFromLeft};
        `;
      }
      if (props.$id === 1) {
        return css`
          animation-name: ${sliderInFromRight};
        `;
      }
    }
    if (props.$id !== props.$isSelect && props.$isSelect !== null) {
      if (props.$id === 0) {
        return css`
          animation-name: ${sliderOutFromLeft};
        `;
      }
      if (props.$id === 1) {
        return css`
          animation-name: ${sliderOutFromRight};
        `;
      }
    }
    if (props.$id === 0 && props.$isSelect === null) {
      return css`
        left: 0px;
        top: 0px;
      `;
    }
    return css`
      left: 100%;
      top: 0px;
    `;
  }}
`;

export const ImageSlideButtonWrapper = styled.div`
  /* position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0%); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8px;

  margin-top: 72px;
  margin-bottom: 40px;
`;

export const ImageSlideButton = styled.button<{ $isSelect: boolean }>`
  cursor: pointer;
  margin-right: 8px;
  border: none;
  transition: all 0.5s ease-out;
  height: 100%;
  ${(props) => {
    if (props.$isSelect) {
      return css`
        background-color: ${props.theme.colors.primary};
        border-radius: 16px;
        width: 32px;
      `;
    }
    return css`
      background-color: ${props.theme.colors.grey400};
      width: 8px;
      border-radius: 50%;
    `;
  }}
`;

export const LoginFormWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  svg {
    height: 100%;
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 50px;
`;

export const LoginFormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const LoginFormItemTitle = styled.div`
  height: 20px;
  margin-bottom: 8px;
`;

export const LoginInput = styled.input`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey700};
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.grey400};
  background-color: white;
  padding: 16px;
  &:focus {
    color: ${(props) => props.theme.colors.grey800};
    border-color: ${(props) => props.theme.colors.grey800};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

export const LoginButton = styled.button`
  ${P2} {
    color: white;
  }

  width: 100%;
  height: 55px;
  background-color: ${(props) => props.theme.colors.blue400};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  border: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

export const Split = styled.div`
  width: 400px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey300};
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const ContactBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
export const ContactTitle = styled(P4)`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.grey500};
`;
export const ContactEmail = styled(P4)`
  color: ${(props) => props.theme.colors.primary};
`;
export const ContactPhone = styled(P4)`
  color: ${(props) => props.theme.colors.grey500};
`;
export const CopyRight = styled(P4)`
  color: ${(props) => props.theme.colors.grey400};
`;

export const BackgroundBlur = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 500;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

export const ModalWrapper = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 182px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

export const ModalDesc = styled.div`
  width: 100%;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey800};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalSplit = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

export const ModalConfirmButton = styled.button`
  width: 100%;
  height: 56px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

export const LoginRightWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const VodaHealthWrapper = styled.div<{
  $isVodaAvailable: boolean | null;
}>`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  ${(props) => {
    if (props.$isVodaAvailable) {
      return css`
        width: fit-content;
      `;
    }
    return css`
      width: fit-content;
    `;
  }}
`;

export const VodaHealthStatus = styled.div`
  width: 100%;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const VodaContactWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  ${P4} {
    color: ${(props) => props.theme.colors.grey500};
  }

  height: 16px;
  div {
    &:first-child {
      margin-right: 8px;
    }
    &:nth-child(2) {
      ${P4} {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const VodaVersionWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;

  ${P4} {
    color: ${(props) => props.theme.colors.grey400};
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 24px;
  width: 100%;
`;

export const VodaHealthProgressWrapper = styled.div<{
  $isShow: boolean | null;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  border-radius: 8px;

  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  background-color: white;

  ${(props) => {
    if (props.$isShow) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

export const VodaHealthText = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VodaProgressBarBackgorund = styled.div`
  position: relative;
  transition: 500ms;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.grey100};
`;

export const VodaProgressBar = styled.div<{ $width: number }>`
  position: absolute;

  width: ${(props) => props.$width}%;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const VodaProgressRate = styled(P4)`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.primary};
`;

export const VodaContactModal = styled.div<{ $isOpenModal: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  width: 320px;
  z-index: 999;

  ${(props) => {
    if (props.$isOpenModal) {
      return css`
        display: flex;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

export const VodaContactModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  justify-content: center;
`;

export const VodaContactBodySplit = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

export const VodaContactButtonWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
`;

export const VodaContactButtonSplit = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

export const VodaRetryButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;

  width: 100%;

  ${P3} {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

export const VodaContactButton = styled.a`
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    ${P3} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  ${P3} {
    color: ${(props) => props.theme.colors.primary};
  }
`;
