import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { H4, H5 } from '../../Atoms/Typo';
import CardImage from '../Card/CardImage';
import SelectBox from '../../Atoms/SelectBox';

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  padding-bottom: 8px;
  ${H4} {
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
  ${H5} {
    color: ${(props) => props.theme.colors.grey800};
    font-weight: bold;
  }
`;

const Script = styled.div`
  font-size: 14px;
  padding-bottom: 20px;
  color: #424242;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: row;
`;

const PC = styled.div`
  flex-direction: column;
  padding-right: 20px;
`;

const Screen = styled.div<{ $mobile?: boolean }>`
  width: 500px;
  height: 394px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.grey200};
  ${(props) => {
    if (props.$mobile) {
      return css`
        width: 200px;
      `;
    }
    return css``;
  }}
`;

interface SelectPageProps {
  pageData: {
    option: {
      value: string;
      label: string;
    }[];
    img_url: {
      [key: string]: {
        pc: string;
        mobile: string;
      };
    };
  };
  onClickPageID: (id: string, name: string) => void;
}

const SelectPage = ({ pageData, onClickPageID }: SelectPageProps) => {
  const [stateValue, setStateValue] = useState('a00');

  // select change
  const stateChange = useCallback((inputValue) => {
    setStateValue(inputValue.value);
    onClickPageID(inputValue.value, inputValue.label);
  }, []);

  return (
    <Component>
      <Title>
        <H4>페이지 선택</H4>
      </Title>
      <SelectBox
        type="page"
        option={pageData.option}
        defaultValue={pageData.option[0]}
        onChange={stateChange}
      />

      <Title>
        <H4>페이지 미리보기</H4>
      </Title>
      <Script>
        다음 이미지는 예시이며 실제 적용과 차이가 있을 수 있습니다.
      </Script>
      <Frame>
        <PC>
          <Title>
            <H5>PC 버전</H5>
          </Title>
          <Screen>
            <CardImage
              imgSrc={`https://voryq-media.nerdfactory.ai/${localStorage.getItem(
                'vq_tenant'
              )}/page/pc/${stateValue}`}
            />
          </Screen>
        </PC>
        <PC>
          <Title>
            <H5>Mobile 버전</H5>
          </Title>
          <Screen $mobile>
            <CardImage
              imgSrc={`https://voryq-media.nerdfactory.ai/${localStorage.getItem(
                'vq_tenant'
              )}/page/mobile/${stateValue}`}
            />
          </Screen>
        </PC>
      </Frame>
    </Component>
  );
};

export default SelectPage;
