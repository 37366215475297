import axios from 'axios';

import config from '../config-api.json';
import {
  SetCheckPasswordParamType,
  SetCheckPasswordResponseType,
  SetEditUserInfoParamType,
  SetEditUserInfoResponseType,
  SetEditPasswordParamType,
  SetEditPasswordResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiSetCheckPasswordInfo(info: SetCheckPasswordParamType) {
  const response = await axios.post<SetCheckPasswordResponseType>(
    `${apiInfo.api_url}/account/check_password/`,
    {
      pw: info.pw,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiSetEditUserInfo(info: SetEditUserInfoParamType) {
  const response = await axios.put<SetEditUserInfoResponseType>(
    `${apiInfo.api_url}/account/edit_user_info/`,
    {
      user_name: info.user_name,
      phone: info.phone,
      email: info.email,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiSetEditPasswordInfo(info: SetEditPasswordParamType) {
  const response = await axios.put<SetEditPasswordResponseType>(
    `${apiInfo.api_url}/account/edit_password/`,
    {
      pw: info.pw,
      pw_confirm: info.pw_confirm,
    },
    {
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
