import React, { useEffect, useState } from 'react';

// components
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../components/Organisms/Header';
import StatisticsSearchCard from '../../components/Organisms/Card/StatisticsSearchCard';
import StatisticsDataCard from '../../components/Organisms/Card/StatisticsDataCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import {
  GetItemAnalysisParamType,
  GetAnalysisAlgorithmListParamType,
} from '../../api/itemAnalysis/type';
import {
  getItemAnalysisAsync,
  getAnalysisAlgorithmAsync,
} from '../../modules/itemAnalysis';

const Statistics = () => {
  // state
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [algorithmVal, setAlgorithmVal] = useState<number>();
  const [sortField, setSortField] = useState('exposure');
  const [sortOrder, setSortOrder] = useState('desc');

  const dispatch = useDispatch();
  const {
    isItemListLoading,
    isAlgorithmLoading,

    itemAnalysisData,
    algorithmListData,
  } = useSelector((state: RootState) => state.itemAnalysis);

  const onGetItemAnalysis = (info: GetItemAnalysisParamType) => {
    dispatch(getItemAnalysisAsync.request(info));
  };

  const onGetAlgorithmAnalysis = (info: GetAnalysisAlgorithmListParamType) => {
    dispatch(getAnalysisAlgorithmAsync.request(info));
  };

  // Get Data
  const onGetDateRange = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
  };
  const onGetAlgorithm = (algorithm: number) => {
    setAlgorithmVal(algorithm);
  };
  const onGetSortField = (field: string) => {
    setSortField(field);
  };
  const onGetSortOrder = (order: string) => {
    setSortOrder(order);
  };

  const onClickButton = () => {
    // api 호출 부분
    if (startDate) {
      if (algorithmVal) {
        onGetItemAnalysis({
          start_date: startDate,
          end_date: endDate,
          algorithm: algorithmVal,
          sort_field: sortField,
          order: sortOrder,
        });
      } else {
        onGetItemAnalysis({
          start_date: startDate,
          end_date: endDate,
          sort_field: sortField,
          order: sortOrder,
        });
      }
    } else {
      onGetItemAnalysis({
        sort_field: sortField,
        order: sortOrder,
      });
    }
  };

  useEffect(() => {
    // 가장 처음 빈 param 의 API 호출
    onGetItemAnalysis({});
    onGetAlgorithmAnalysis({});
  }, []);

  return (
    <ContentsLayout>
      <Header title="추천 상품 통계" isDatePicker={false}></Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <StatisticsSearchCard
            isloading={isAlgorithmLoading}
            algorithmOptions={algorithmListData}
            dateRange={onGetDateRange}
            getAlgorithm={onGetAlgorithm}
            getSortField={onGetSortField}
            getSortOrder={onGetSortOrder}
            onClick={onClickButton}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <StatisticsDataCard
            isloading={isItemListLoading}
            data={itemAnalysisData}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Statistics;
