import { css } from 'styled-components';

const dot = css`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
`;

export const tooltip = css`
  .apexcharts-tooltip {
    z-index: 999;
  }
  .tooltip {
    padding: 10px 15px 10px 15px;
    .rateWrap {
      border-bottom: solid 1px ${(props) => props.theme.colors.grey200};
      padding-bottom: 5px;
      .title {
        font-size: 12px;
        color: ${(props) => props.theme.colors.black};
      }
      .rate {
        font-size: 16px;
        color: ${(props) => props.theme.colors.black};
      }
    }
    .valWrap {
      padding-top: 5px;
      .value {
        display: flex;
        align-items: center;
        .dot-1 {
          ${dot};
          background-color: ${(props) => props.theme.colors.blue500};
        }
        .dot-2 {
          ${dot};
          background-color: ${(props) => props.theme.colors.green100};
        }
        .dot-3 {
          ${dot};
          background-color: #fed34b;
        }
      }
    }
  }
`;
