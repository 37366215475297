import axios from 'axios';
import config from '../config-api.json';

import {
  GetDashboardAccumulateParamType,
  GetDashboardAccumulateResponseType,
  GetDashboardProgressParamType,
  GetDashboardProgressResponseType,
  GetDashboardEndedParamType,
  GetDashboardEndedResponseType,
  GetSegmentStatusParamType,
  GetSegmentStatusResponseType,
  GetUsageSegmentParamType,
  GetUsageSegmentResponseType,
  GetRecommendTotalGraphParamType,
  GetRecommendTotalGraphResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}
// 누적 개인화 추천수
export async function apiGetAccumulateInfo(
  info: GetDashboardAccumulateParamType
) {
  const response = await axios.get<GetDashboardAccumulateResponseType>(
    `${apiInfo.api_url}/dashboard/accumulate_q_info/`,
    {
      params: {
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 진행 개인화 추천수
export async function apiGetProgressInfo(info: GetDashboardProgressParamType) {
  const response = await axios.get<GetDashboardProgressResponseType>(
    `${apiInfo.api_url}/dashboard/progress_q_info/`,
    {
      params: {
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 종료 개인화 추천수
export async function apiGetEndedInfo(info: GetDashboardEndedParamType) {
  const response = await axios.get<GetDashboardEndedResponseType>(
    `${apiInfo.api_url}/dashboard/ended_q_info/`,
    {
      params: {
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 고객군 현황
export async function apiGetSegmentStatus(info: GetSegmentStatusParamType) {
  const response = await axios.get<GetSegmentStatusResponseType>(
    `${apiInfo.api_url}/dashboard/segment_status/`,
    {
      params: {
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 고객군 별 추천 이용률
export async function apiGetUsageSegment(info: GetUsageSegmentParamType) {
  const response = await axios.get<GetUsageSegmentResponseType>(
    `${apiInfo.api_url}/dashboard/usage_by_segment/`,
    {
      params: {
        unit: info.unit,
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
// 추천 데이터 그래프
export async function apiGetRecommendTotalGraph(
  info: GetRecommendTotalGraphParamType
) {
  const response = await axios.get<GetRecommendTotalGraphResponseType>(
    `${apiInfo.api_url}/dashboard/recommend_total_graph/`,
    {
      params: {
        unit: info.unit,
        datetime: info.datetime,
      },
      headers: {
        t: localStorage.getItem('vq_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
