import React from 'react';
import styled, { css } from 'styled-components';
import { P2 } from '../../Atoms/Typo';

const ModalBackBlur = styled.div<{ $isModalOpen: boolean; $isPublic: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  ${(props) => {
    if (props.$isPublic) {
      return css``;
    }
    return css`
      /* left: 240px; */
    `;
  }}

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 400;
`;

const ModalBackBlurNav = styled.div<{
  $isModalOpen: boolean;
}>`
  position: fixed;
  background-color: white;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 399;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const ModalWrapper = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 322px;
  height: 157px;
  background-color: white;
  border-radius: 8px;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 500;
  overflow: hidden;
`;

const ModalBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey800};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 125px;
`;

const ModalSplit = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const Modalbutton = styled.button`
  width: 100%;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const ModalbuttonWrapper = styled.div<{ $isDelete?: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;

  button {
    width: 50%;
    height: 100%;
    ${P2} {
      color: white;
    }

    :nth-child(2) {
      color: ${(props) => props.theme.colors.blue400};
    }

    ${(props) => {
      if (props.$isDelete) {
        return css`
          :nth-child(2) {
            color: #ed0000;
          }
        `;
      }
    }}
  }
`;

interface DefaultModalProps {
  isOpen: boolean;
  isPublic: boolean;
  isDefault?: boolean;
  isDelete?: boolean;
  message: string;
  closeBtnName?: string;
  confirmBtnName?: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const Modal = ({
  message,
  isOpen,
  isPublic,
  isDefault,
  isDelete,
  confirmBtnName,
  closeBtnName,
  handleClose,
  handleConfirm,
}: DefaultModalProps) => {
  return (
    <>
      <ModalBackBlurNav $isModalOpen={isOpen} />
      <ModalBackBlur $isModalOpen={isOpen} $isPublic={isPublic} />

      <ModalWrapper $isModalOpen={isOpen}>
        <ModalBody>
          {message.split(`\n`).map((line) => {
            return <div key={line}>{line}</div>;
          })}
        </ModalBody>
        <ModalSplit />
        {isDefault ? (
          <Modalbutton
            onClick={() => {
              handleConfirm();
            }}
          >
            확인
          </Modalbutton>
        ) : (
          <ModalbuttonWrapper $isDelete={isDelete}>
            <Modalbutton
              onClick={() => {
                handleClose();
              }}
            >
              {closeBtnName}
            </Modalbutton>
            <Modalbutton
              onClick={() => {
                handleConfirm();
              }}
            >
              {confirmBtnName}
            </Modalbutton>
          </ModalbuttonWrapper>
        )}
      </ModalWrapper>
    </>
  );
};

export default Modal;
